import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    Box,
    Drawer,
    Toolbar,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";

import LogoutIcon from '@material-ui/icons/ExitToApp';

import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

const NavigationDrawer = () => {
    const { t } = useTranslation();
    const { drawerIsOpen, setDrawerIsOpen, setIsLogin, isLogin, setUser } =
        React.useContext(GlobalContext);
    const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const logout = () => {
        sessionStorage.removeItem("token");
        setIsLogin(false);
        setDrawerIsOpen(false);
        setUser(null);
    };

    return (
        <Drawer
            anchor={smView ? "top" : "right"}
            sx={{
                flexShrink: 0,
                width: smView ? "100%" : 240,
                [`& .MuiDrawer-paper`]: {
                    width: smView ? "100%" : 240,
                    boxSizing: "border-box",
                },
            }}
            open={drawerIsOpen}
            onClose={() => setDrawerIsOpen(!drawerIsOpen)}
        >
            <Toolbar />
            <Box sx={{ overflow: "auto" }}>
                <List>
                    {isLogin && (
                        <ListItem onClick={logout} button>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("로그아웃")} />
                        </ListItem>
                    )}
                    {/* {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))} */}
                </List>
            </Box>
        </Drawer>
    );
};

export default NavigationDrawer;
