import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";

import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const";
import * as common from "../../../common"
import { useAuth } from "../../../AuthContext";
import moment from "moment"

import Paper from "@material-ui/core/Paper";

//레비뉴 테이블
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import qs from "qs";
import { useLocation } from "react-router-dom";
import Card from "../../../components/basic/Bcard";
import ContentText from "../../../components/basic/ContentText";



import CalenderControl from "../../../components/basic/CalenderControl";
import RevenueDisplay from "../../../components/basic/RevenueDisplay";

export default function Revenue(props) {
    const auth = useAuth();
    const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
    });
    const location = useLocation();
    const [apiData, setData] = React.useState(null);
    const [earning, setEarning] = React.useState(0);
    const [calender, setCalender] = React.useState(null);
    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        (async () => {
            console.log()
            console.log(location.pathname); // result: '/secondpage'
            console.log(location.search); // result: '?query=abc'
            console.log("ㄷㅔ이터터", location.state.data); // result: 'some_value'
            setUser(location.state.data)
        })()
    }, [location]);

    console.log("에이피아이데이터", user && user && apiData)

    const fetchingApi = async (date) => {


        if (user.data.role === constant.role.delivery) {

            const result = await api.getRevenuRent(user.data.role, user.id, date)
            console.log("result", result)
            setData(result.data)
            let sum = 0

            result && result.data.map((data) => {
                console.log("도니", data.data.pricing.delivery)
                sum += data.data.pricing.delivery
            })
            console.log(sum)
            setEarning(sum)

        } else if (user.data.role === constant.role.washmate) {
            console.log(date)
            const result = await api.getRevenuRequest(user.id, date)
            console.log("result washmate", result)
            setData(result.data)
            let sum = 0

            result.data && result.data.map((data) => {
                console.log("도니 washmate", data.data.pricing.washmate)
                sum += data.data.pricing.washmate * Number(data.data.response.amount.typeA)
            })
            console.log(sum)
            setEarning(sum)



        }
    }

    React.useEffect(() => {
        if (user === null) {
            return
        }
        setCalender(common.getTodayDateForm());

        (async () => {
            console.log("유저데이터", user.data && user)
            if (user.data.role === constant.role.delivery) {

                const result = await api.getRevenuRent(user.data.role, user.id, common.getTodayDateForm())
                console.log("result", result)
                setData(result.data)
                let sum = 0

                result.data && result.data.map((data) => {
                    console.log("도니", data.data.pricing.delivery)
                    sum += data.data.pricing.delivery
                })
                console.log(sum)
                setEarning(sum)

            } else if (user.data.role === constant.role.washmate) {
                const result = await api.getRevenuRequest(user.id, common.getTodayDateForm())
                console.log("result washmate", result)
                setData(result.data)
                let sum = 0

                result.data && result.data.map((data) => {
                    console.log("도니 washmate", data.data.pricing.washmate)
                    sum += data.data.pricing.washmate * Number(data.data.response.amount.typeA)
                })
                console.log(sum)
                setEarning(sum)



            }
        })()

    }, [user])











    // const washMaterevenueTable = (<>
    //     <TableContainer component={Paper}>
    //         <Table aria-label="simple table">
    //             <TableBody>
    //                 {user && apiData && !apiData.length && (
    //                     <div
    //                         style={{
    //                             width: "100%",
    //                             height: "100px",
    //                             textAlign: "center",
    //                             alignSelf: "center",
    //                             display: "flex"
    //                         }}
    //                     >
    //                         <span
    //                             style={{
    //                                 width: "100%",
    //                                 textAlign: "center",
    //                                 alignSelf: "center"
    //                             }}
    //                         >
    //                             데이터가 없습니다
    //                         </span>
    //                     </div>
    //                 )}
    //                 {

    //                     user.data.role === constant.role.washmate && apiData && apiData.length !== 0 && apiData.map((i, index) => {
    //                         console.log(i)
    //                         return (
    //                             <TableRow key={index} style={{ height: "90px" }}>
    //                                 <TableCell
    //                                     style={{
    //                                         fontSize: "14px",
    //                                         fontWeight: "400",
    //                                         verticalAlign: "middle"
    //                                     }}
    //                                     component="th"
    //                                     scope="row"
    //                                 >
    //                                     <p>{moment(i.data.washmateInfo.updatedBy).format('MM-DD HH:mm')}</p>
    //                                 </TableCell>

    //                                 <TableCell
    //                                     style={{ height: "60px", verticalAlign: "middle" }}
    //                                     align="left"
    //                                 >
    //                                     <p
    //                                         style={{
    //                                             fontWeight: "400",
    //                                             fontSize: "16px"
    //                                         }}
    //                                     >
    //                                         {i.data.request.mainAddress}
    //                                     </p>

    //                                 </TableCell>
    //                                 <TableCell
    //                                     style={{ height: "60px", verticalAlign: "middle" }}
    //                                     align="left"
    //                                 >
    //                                     <p
    //                                         style={{
    //                                             fontWeight: "400",
    //                                             fontSize: "16px"
    //                                             // color: "#00838F"
    //                                         }}
    //                                     >
    //                                         {i.data.response.amount.typeA}개
    //                                     </p>


    //                                 </TableCell>
    //                                 <TableCell
    //                                     style={{ height: "60px", verticalAlign: "middle" }}
    //                                     align="right"
    //                                 >

    //                                     <p
    //                                         style={{
    //                                             fontStyle: "normal",
    //                                             fontWeight: "800",
    //                                             fontSize: "18px",
    //                                             // marginTop: "8px"
    //                                         }}
    //                                     >
    //                                         {(i.data.pricing.delivery * Number(i.data.response.amount.typeA))
    //                                             + "원"}
    //                                     </p>
    //                                 </TableCell>
    //                             </TableRow>
    //                         );
    //                     })}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // </>)

    const deliveryRevenueTable = (user && <>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {console.log("유저데이터", user)}

                    {user && apiData && !apiData.length && (
                        <div
                            style={{
                                width: "100%",
                                height: "100px",
                                textAlign: "center",
                                alignSelf: "center",
                                display: "flex"
                            }}
                        >
                            <span
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    alignSelf: "center"
                                }}
                            >
                                데이터가 없습니다
                            </span>
                        </div>
                    )}
                    {

                        user.data.role === constant.role.delivery && apiData && apiData.length !== 0 && apiData.map((i, index) => {
                            console.log(i)
                            return (
                                <TableRow key={index} style={{ height: "90px" }}>
                                    <TableCell
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            verticalAlign: "middle"
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        <p>{moment(i.data.updatedBy).format('MM-DD HH:mm')}</p>
                                    </TableCell>

                                    <TableCell
                                        style={{ height: "60px", verticalAlign: "middle" }}
                                        align="left"
                                    >
                                        <p
                                            style={{
                                                fontWeight: "400",
                                                fontSize: "16px"
                                                // color: "#00838F"
                                            }}
                                        >
                                            {i.data.returnPoint.data.storeName}
                                        </p>


                                    </TableCell>
                                    <TableCell
                                        style={{ height: "60px", verticalAlign: "middle" }}
                                        align="right"
                                    >

                                        <p
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "800",
                                                fontSize: "18px",
                                                // marginTop: "8px"
                                            }}
                                        >
                                            {(i.data.pricing.delivery)
                                                + "원"}
                                        </p>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    </>)
    if (apiData === null) {
        return ("데이터가 없습니다")
    }

    return (
        <Page>
            <AppBar backLink="/login" />
            <EmptySpace />

            <DesText>배달 파트너 정보</DesText>
            <EmptySpace />

            <Card>
                <UpText>ID </UpText>
                <ContentText>{user && user.id}</ContentText>
                <UpText>성함 </UpText>
                <ContentText>{user && user.data.accountHolder}</ContentText>
                <UpText>계좌정보 </UpText>
                <ContentText>{user && user.data.accountNumber}</ContentText>
                <UpText>은행 </UpText>
                <ContentText>{user && user.data.bank}</ContentText>


            </Card>
            <EmptySpace />


            < CalenderControl calender={calender} setCalender={setCalender} fetchData={fetchingApi} />
            <RevenueDisplay calender={calender} totalRevenue={earning} />
            {/* {user && user.data.role === constant.role.washmate && washMaterevenueTable} */}
            {user && user.data.role === constant.role.delivery && deliveryRevenueTable}


            <EmptySpace />
        </Page>
    );
}
