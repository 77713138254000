import React from "react";
import TextField from "@material-ui/core/TextField";
import { css } from "../../Const";
import UpText from "./UpText";
import Link from "./Link";
import { Box } from '@material-ui/core';

export default function OutLinedTextFeild(props) {

  const marginSide = css.marginSide;
  const bothMarginside = marginSide * 2;
  const marginTop = css.marginTop;
  return (
    <>
      <UpText>{props.title} </UpText>
      <Box style={{ width: "100%" }}>
        <TextField
          variant="outlined"
          disabled={!props.fixable}
          value={props.value}
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
          fullWidth
          {...props}
          style={{
           
          }}
        ></TextField>
        <Box display="flex" justifyContent="flex-end" style={{marginTop:"4px"}}>
        <Link
        
          onClick={() => {
            props.fixButtonTapped();
          }}
          justifycontent="flex-end"
        >
          {props.fixable ? "완료" : "수정"}
        </Link>
        </Box>
      </Box>
    </>
  );
}

{
  //   const [restitleFixable, setRestitleFixable] = React.useState(true);
  /* <FixableTextField
title="음식점 이름"
fixable={restitleFixable}
onChange={() => {}}
value={"용문각"}
fixButtonTapped={() => {
  setRestitleFixable(!restitleFixable);
}}
/> */
}
