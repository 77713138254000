import React, { forwardRef } from "react";
import { Helmet } from "react-helmet-async";

import PropTypes from "prop-types";
import Header from "components/Header";
import Drawer from "components/Drawer";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  page: {
    // backgroundColor: theme.palette.backGround,
  },
}));

const Page = forwardRef(
  ({ children, title = "", header = false, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        {...rest}
        className={classes.page}
        style={{
          width: "100vw",
          height: `calc(100vh)`,
          overflow: "auto",
        }}
      >
        <Helmet>
          <title>
            {title} | {"리플하다"}
          </title>
        </Helmet>
        {header && <Header />}
        <Drawer />
        {children}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
