import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import Link from "../../../components/basic/Link";

import * as constant from "../../../Const";
import * as common from "../../../common";
import * as api from "../../../replhadaAPI";
import { useAuth } from "../../../AuthContext";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
});
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function SignUp(props) {
  const auth = useAuth();

  const classes = useStyles();
  const [value, setValue] = React.useState("restaurant");
  const [rents, setRents] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      // console.log("auth.userExtraInfo.role", auth.userExtraInfo.role)

      //delivered 된거, 워시메이트에 내 이메일,

      //TODO 여기서 렌트 가저오기
      //현재 렌트 스테터스 웨이팅 딜리버리인 애들 가져오기
      const received = await api.adminRestaurantList();

      console.log("received", received);
      if (received.code !== 200) {
        alert(received.msg);
        return;
      }

      setRents(received.data);
    })();
  }, []);

  const refreshData = async () => {
    console.log("auth.userExtraInfo.role", auth.userExtraInfo.role);

    //delivered 된거, 워시메이트에 내 이메일,

    //TODO 여기서 렌트 가저오기
    //현재 렌트 스테터스 웨이팅 딜리버리인 애들 가져오기
    const received = await api.adminRestaurantList();

    if (received.code !== 200) {
      alert(received.msg);
      return;
    }

    setRents(received.data);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false,
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  // console.log("rents", row.data.containerId)

  return (
    <Page>
      <AppBar backLink="/main" />
      <EmptySpace />

      <SubTitle>음식점 리스트</SubTitle>
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>음식점</TableCell>
              <TableCell align="center">주소</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rents &&
              rents.map((row, i) => (
                <TableRow
                  key={row.storeName + i}
                  onClick={() => {
                    console.log(row);
                    props.history.push({
                      pathname: `/adminRestaurantdetail`,
                      state: { data: row },
                    });
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.storeName}
                  </TableCell>
                  {/* <TableCell align="center">{row.data.updatedBy}</TableCell> */}
                  <TableCell align="center">
                    {row.mainAddress + " " + row.restAddress}
                  </TableCell>
                  {/* moment(row.data.updatedBy).format('MMMM Do YYYY, h:mm:ss a'); // May 05th 2018, 7:20:58 pm */}

                  <TableCell align="center">
                    {/* <Button variant="contained" color="secondary" onClick={async () => {
                                    if (window.confirm("미입고된 용기입니까")) {
                                        //TODO Drift
                                        const rentInfo = { status: constant.rentStatus.drifted, updatedBy: (new Date()) }
                                        const result1 = await api.updateRent(row.id, rentInfo)
                                        if (result1.code !== 200) {
                                            alert(result1.msg)
                                            return
                                        }
                                        const result = await api.updateContainer(row.data.containerId, { status: constant.containerStatus.drifted, updatedBy: (new Date()) })
                                        if (result.code !== 200) {
                                            alert(result.msg)
                                            return
                                        }
                                        alert("처리 되었습니다")
                                        refreshData()


                                    }
                                }}
                                > 입고사실 없음</Button> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptySpace />
      <EmptySpace />

      <EmptySpace />
    </Page>
  );
}
