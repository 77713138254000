import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";
import { useAuth } from "../../../AuthContext";

import UpText from "../../../components/basic/UpText";
import ContentText from "../../../components/basic/ContentText";

import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import qs from "qs";
import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const"

import QrReader from 'react-qr-reader'
export default function Retrive(props) {
    const auth = useAuth();

    const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
    });
    const rentId = query.rentId;
    const [rent, setRent] = React.useState(null);
    const [returnPoints, setReturnPoints] = React.useState(null);
    const [washmate, setWashmate] = React.useState(null);

    React.useState(() => {
        (async () => {

            const washResult = await api.getWashmate(constant.washmateId)
            if (washResult.code !== 200) {
                alert(washResult.msg)
                return
            }
            setWashmate(washResult)
            const returnPoints = washResult.data.returnPointDocs
            console.log("리턴포인트", returnPoints)

            const pa = await Promise.all(returnPoints.map((element) => {
                return api.getReturnPoint(element)
            }))
            setReturnPoints(pa)

            const result = await api.getRent(rentId)
            console.log("리저트", result)
            if (result.code !== 200) {
                alert(result.msg)
                return
            }

            setRent(result)




        })()

    }, [])
    const [value, setValue] = React.useState("restaurant");

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,
        checkedG: false
    });

    const handleError = (err) => {
        console.error(err);
    };

    const rentObj = { status: constant.rentStatus.delivered, updatedBy: (new Date) }

    const update = async (info, obj) => {
        const result = await api.updateRent(rent.id, obj)
        if (result.code !== 200) {
            alert(result.msg)
            return
        }
        return result

    }


    const handleScan = async (data) => {
        if (data === null) {
            return
        }
        const dataLowerCase = data.toLowerCase()
        const prefixLowerCase = constant.prefix.returnPoint.toLowerCase()
        if (dataLowerCase.includes(prefixLowerCase)) {

            let returnqr = dataLowerCase.replace(prefixLowerCase, "")
            returnqr = returnqr.replace("qrinfo=", "")

            if (returnqr === rent.data.returnPoint.data.qrinfo.toLowerCase()) {
                //TODO update status, updatedBy 배달부 객체에
                const result = await update(rentId, rentObj)
                alert("수거가 완료되었습니다 감사합니다")

                props.history.push(`/main`)
                return
            } else {
                alert("올바른 반납장소가 아닙니다")
                alert("반납장소가 다를시 가까운 반납장소에 반납해주세요 *수익은 입금되지 않습니다*")
                return
            }
        }
        return
        // if data.conta
        // setqrData(data)

    }

    return (
        <Page>
            <AppBar menu={true} />
            <EmptySpace />
            <DesText>수거장소에 부착된 QR을 찍어주세요</DesText>
            <EmptySpace />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%"
                }}
            // onClick={() => {
            // }}
            >
                <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    facingMode={"environment"}

                />


                <p
                    style={{
                        margin: "auto",
                        marginTop: "20px"
                    }}
                    onClick={async () => {
                        const result = await update(rentId, rentObj)


                        alert("수거가 완료되었습니다 감사합니다")
                        props.history.push(`/main`)

                    }}
                >
                    수거장소에 부착된 QR을 찍어 반납을 완료해주세요
                </p>
            </div>
            <EmptySpace />
            <EmptySpace />
            <EmptySpace />
            <EmptySpace />
        </Page>);
}
