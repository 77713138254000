import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";
import { useAuth } from "../../../AuthContext";

import UpText from "../../../components/basic/UpText";
import ContentText from "../../../components/basic/ContentText";

import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";

import {Radio,Box} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import qs from "qs";
import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const"
import QrScanner from 'qr-scanner'; 
import {Header,Title,LastLink,SectionTitle} from "components/basic/Components";

import QrReader from 'react-qr-reader'
export default function Retrive(props) {
    const auth = useAuth();

    const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
    });
    const rentId = query.rentId;
    const [rent, setRent] = React.useState(null);
    const [returnPoints, setReturnPoints] = React.useState(null);
    const [washmate, setWashmate] = React.useState(null);
    const [qrScanner, setQrScanner] = React.useState(null)

    const foundQR = (result)=>{
        if (!containerIds.includes(result)){
          handleScan(result)
        }
      }

    React.useState(() => {
        (async () => {
            const qrScanner = new QrScanner(videoElem, result => {
                if(result){
                  foundQR(result.data)
                }
                
                },{
                  highlightScanRegion: true,
                  highlightCodeOutline: true,
                });
                setQrScanner(qrScanner)
              qrScanner.setInversionMode("both")
              qrScanner.start()
            

            const washResult = await api.getWashmate(constant.washmateId)
            if (washResult.code !== 200) {
                alert(washResult.msg)
                return
            }
            setWashmate(washResult)
            const returnPoints = washResult.data.returnPointDocs
            console.log("리턴포인트", returnPoints)

            const pa = await Promise.all(returnPoints.map((element) => {
                return api.getReturnPoint(element)
            }))
            setReturnPoints(pa)

            const result = await api.getRent(rentId)
            //TODO예약중인 애들 가져오기
            //렌트의 그릇 번호확인
            console.log("리저트", result)
            if (result.code !== 200) {
                alert(result.msg)
                return
            }

            setRent(result)




        })()

    }, [])
    const [value, setValue] = React.useState("restaurant");

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,
        checkedG: false
    });

    const handleError = (err) => {
        console.error(err);
    };

    const rentObj = { status: constant.rentStatus.delivieryPicikup, updatedBy: (new Date) }

    const update = async (info) => {


        const result = await api.updateRent(rent.id, rentObj)
        if (result.code !== 200) {
            alert(result.msg)
            return
        }
        return result



    }

    const handleScan = async (data) => {
        if (data === null) {
          return
        }
        const dataLowerCase = data.toLowerCase()
        const prefixLowerCase = constant.prefix.container.toLowerCase()
        if (dataLowerCase.includes(prefixLowerCase)) {
          let newContainerId = dataLowerCase.replace(prefixLowerCase, "")
          newContainerId = newContainerId.replace("containerid=", "")
          // alert(newContainerId)
          if (containerIds.includes(newContainerId)) {
            // alert("중복")
            return
          }
          const add = containerIds
           add.push(newContainerId)
        //   enqueueSnackbar("다회용기 추가 성공", { variant: "success" });
    
        //   setContainerIds(add)
        //   setContainerLength(add.length)
    
          // if (containerId === rent.data.containerId.toLowerCase()) {
          //TODO update status, updatedBy 배달부 객체에
          // await update(rentObj)
          // alert("회수에 성공했습니다")
    
          // props.history.push(`/retriving?rentId=${rent.id}`)
          //   return
          // } else {
          //   alert("회수할 다회용기가 아닙니다")
          //   return
          // }
        }
        return
        // if data.conta
        // setqrData(data)
    
      }
    // const handleScan = async (data) => {
    //     if (data === null) {
    //         return
    //     }
    //     const dataLowerCase = data.toLowerCase()
    //     const prefixLowerCase = constant.prefix.container.toLowerCase()
    //     if (dataLowerCase.includes(prefixLowerCase)) {
    //         let containerId = dataLowerCase.replace(prefixLowerCase, "")
    //         containerId = containerId.replace("containerid=", "")
    //         const containerIds = rent.data.containerIds.map(v=>v.toLowerCase())
    //     //렌트의 그릇 번호확인
    //         if(containerIds.include(containerId)){
    //             //TODO update status, updatedBy 배달부 객체에
                

    //             await update(rentObj)
    //             alert("회수에 성공했습니다")

    //             props.history.push(`/retriving?rentId=${rent.id}`)
    //             return
    //         } else {
    //             alert("회수할 다회용기가 아닙니다")
    //             return
    //         }
    //     }
    //     return
    //     // if data.conta
    //     // setqrData(data)

    // }

    return (
        <Page>
            <AppBar menu={true} />
            <EmptySpace />
            <Title title="다회용기의 QR을 스캔해 주세요"/>
            <EmptySpace />
            <Box >
      <video id="videoElem"
      style={{width:"100%" }}/>
      </Box>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%"
                }}
          
            >
            

   
                <p
                    style={{
                        margin: "auto",
                        marginTop: "20px"
                    }}
                  
                >
                    수거하신 다회용기의 QR을 찍어주세요
                </p>
            </div>
            <EmptySpace />
            <EmptySpace />
            <EmptySpace />
            <EmptySpace />
        </Page>);
}
