import React from "react";
import { css } from "../../Const";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';


export default function DesText(props) {
    const marginSide = css.marginSide;
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    return (
        <div>

            <Dialog
                open={props.open}
                // TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}

            >
                <DialogTitle >수거지 정보</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        픽업수익 
                        <p>{props.title}</p>
                    </DialogContentText>
                    
                    <DialogContentText id="alert-dialog-slide-description">
                        픽업주소 
                        <p> {props.content}</p>
                    </DialogContentText>
                    
                    <DialogContentText id="alert-dialog-slide-description">
                       거리 
                       {props.content2 && <p>{props.content2}</p>}
                    </DialogContentText>
                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.closeButtonTapped} color="primary">
                        취소
                    </Button>
                    <Button onClick={props.actionButtonTapped} color="primary">
                        픽업하기
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
