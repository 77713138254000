import React from "react";
import { css } from "../../Const";
import {Box,Typography} from '@material-ui/core';

export default function UpText(props) {
  const marginSide = css.marginSide;

  return (
    <Box >
      <Typography
        style={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          color: "#323232",
          opacity: "0.8",
          marginLeft:"2px"
        }}
      >
        {props.children}
      </Typography>
    </Box>
  );
}
