import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../AuthContext";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import AdminAppBar from "../../../components/basic/AdminAppBar";
import Loader from "components/Loader";

import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import Link from "../../../components/basic/Link";
import Dialog from "../../../components/basic/Dialog";
import * as api from "../../../replhadaAPI";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Header,
  Title,
  LastLink,
  SectionTitle,
} from "components/basic/Components";

import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  Container,
  FormLabel,
  Box,
  Paper,
  Chip,
  Typography,
} from "@material-ui/core";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import QrReader from "react-qr-reader";
import GoogleMapReact from "google-map-react";
import PlaceIcon from "@material-ui/icons/Place";

import ListMenu from "../../../components/basic/ListMenu";
import * as constant from "../../../Const";
import * as common from "../../../common";

import useSupercluster from "use-supercluster";
import useSwr from "swr";
import "./App.css";
import { useHistory, withRouter } from "react-router-dom";

import {
  Map,
  Marker,
  MapMarker,
  MarkerClusterer,
  Polyline,
  useInjectKakaoMapApi,
} from "react-kakao-maps-sdk";
import { Delivery } from "Classes";

// const Marker = ({ children }) => children;

function App(props) {
  let history = useHistory();

  const auth = useAuth();
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [role, setRole] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [waitingDeliveryRents, setWaitingDeliveryRents] = React.useState(null);
  const [pickedDeliveryRents, setPickedDeliveryRents] = React.useState(null);
  const [activeDeliveryRents, setactiveDeliveryRents] = React.useState(null);
  const [reservedDeliveryRents, setReservedDeliveryRents] =
    React.useState(null);
  const [expoPushKey, setExpoPushKey] = React.useState("");

  const [coordinate, setCoordinate] = React.useState({
    lat: 37.6706743,
    lng: 126.7591576,
  });

  function checkMobile() {
    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    if (varUA.indexOf("android") > -1) {
      //안드로이드
      return "android";
    } else if (
      varUA.indexOf("iphone") > -1 ||
      varUA.indexOf("ipad") > -1 ||
      varUA.indexOf("ipod") > -1
    ) {
      //IOS
      return "ios";
    } else {
      //아이폰, 안드로이드 외
      return "other";
    }
  }
  const onMessageHandler = (e) => {
    try {
      const event = JSON.parse(e.data);
      window.ReactNativeWebView?.postMessage(JSON.stringify({ event: event }));

      // console.log("dodo" + JSON.stringify(event));
      if (event.key === "coordinate") {
        setCoordinate(event.coordinate);
      }
      if (event.key === "expoToken") {
        setExpoPushKey(event.expoToken);
      }
    } catch (e) {
      return;
    }
  };
  React.useEffect(() => {
    const isUIWebView = () => {
      return navigator.userAgent
        .toLowerCase()
        .match(/\(ip.*applewebkit(?!.*(version|crios))/);
    };

    const receiver = isUIWebView() ? window : document;

    if (!window.ReactNativeWebView) {
      return;
    }

    receiver.addEventListener("message", onMessageHandler);
    return () => {
      receiver.removeEventListener("message", onMessageHandler);
    };
  }, []);

  // function registerEvent(event) {
  //   console.log("레지스터 앱->웹", event);
  //   if (!event.data) {
  //     return;
  //   }
  //   if (event.data.includes("expoPushToken")) {
  //     try {
  //       const parsed = JSON.parse(event.data);
  //       const expoPushToken = parsed.expoPushToken;
  //       console.log(event.data);
  //       if (expoPushToken === "") {
  //         return;
  //       }
  //       setExpoPushKey(expoPushToken);
  //     } catch (e) {
  //       alert(e);
  //     }
  //   }
  //   if (event.data.includes("coordinate")) {
  //     try {
  //       const parsed = JSON.parse(event.data);
  //       const location = parsed.coordinate;

  //       setCoordinate({ lat: location.lat, lng: location.lng });
  //     } catch (e) {
  //       alert(e);
  //     }
  //   }
  // }

  // React.useEffect(() => {
  //   console.log("레지스터");
  //   if (checkMobile() === "android") {
  //     document.addEventListener("message", registerEvent);
  //   }
  //   if (checkMobile() === "ios") {
  //     window.addEventListener("message", registerEvent);
  //   }
  //   return () => {
  //     if (checkMobile() === "android") {
  //       document.removeEventListener("message");
  //     }
  //     if (checkMobile() === "ios") {
  //       window.removeEventListener("message");
  //     }
  //   };
  // }, []);
  // React.useEffect(() => {
  //   if (
  //     typeof window.ReactNativeWebView !== "undefined" &&
  //     window.ReactNativeWebView
  //   ) {
  //     // window.ReactNativeWebView &&
  //     //   window.ReactNativeWebView.postMessage(
  //     //     JSON.stringify([{ command: "getExpoToken" }])
  //     //   );
  //   } else {
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       let lat = position.coords.latitude;
  //       let lng = position.coords.longitude;
  //       console.log("내위치", lat, lng);
  //       setCoordinate({ lat, lng });
  //     });
  //   }
  // }, []);

  React.useEffect(() => {
    (async () => {
      if (expoPushKey) {
        try {
          const delivery = new Delivery(auth && auth.user.email);
          await delivery.setPushKey(expoPushKey);
        } catch (e) {
          console.log("error:1001", e);
        }
      }
    })();
  }, [expoPushKey]);
  React.useEffect(() => {
    (async () => {
      if (coordinate) {
        try {
          const delivery = new Delivery(auth && auth.user.email);
          await delivery.setCoordinate(coordinate);
        } catch (e) {
          console.log("error:1002", e);
        }
      }
    })();
  }, [coordinate]);
  React.useEffect(() => {
    (async () => {
      if (auth && auth.userExtraInfo === null) {
        return;
      }

      if (auth.userExtraInfo.role === constant.role.delivery) {
        //TODO 여기서 렌트 가저오기
        //현재 렌트 스테터스 웨이팅 딜리버리인 애들 가져오기
        const myPickups = await api.getUserActiveRents(auth && auth.user.email);

        if (myPickups.code !== 200) {
          alert(myPickups.msg);
          return;
        }
        const removeDrifted = myPickups.data.filter((v) => {
          return v.data.status !== constant.rentStatus.drifted && v;
        });

        setactiveDeliveryRents(removeDrifted);

        const getRents = await api.getRentByStatus(
          constant.rentStatus.waitingDelivery
        );
        if (getRents.code !== 200) {
          alert(getRents.msg);
          return;
        }
        // console.log("checking getRents in main : ", getRents);

        setWaitingDeliveryRents(getRents.data);
        const reservedRents = await api.getRentByUserIdAndStatus(
          auth.user.email,
          constant.rentStatus.deliveryReserved
        );
        if (reservedRents.code !== 200) {
          alert(getRents.msg);
          return;
        }
        console.log("checking reservedRents in main : ", reservedRents);

        setReservedDeliveryRents(reservedRents.data);
        const getPickedUpRents = await api.getRentByUserIdAndStatus(
          auth.user.email,
          constant.rentStatus.delivieryPicikup
        );
        if (getPickedUpRents.code !== 200) {
          alert(getRents.msg);
          return;
        }
        console.log("checking getRents in main : ", getPickedUpRents);

        setPickedDeliveryRents(getPickedUpRents.data);
      }
    })();
  }, [auth.userExtraInfo]);
  const defaultMapOptions = {
    fullscreenControl: false,
  };

  const handleClickOpen = (v) => {
    setPickedDeliveryRents(v);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleScan = (datat) => {
    if (datat === null) {
      return;
    }
    const dataLowerCase = datat.toLowerCase();
    const prefixLowerCase = constant.prefix.container.toLowerCase();
    if (dataLowerCase.includes(prefixLowerCase)) {
      const containerId = dataLowerCase.replace(prefixLowerCase, "");

      const passData = containerId;
      props.history.push({
        pathname: `/sendcontainer`,
        state: { passData },
      });

      return;
    }
    alert("다회용기의 QR을 스캔해주세요");

    return;
  };

  const handleError = (err) => {
    console.error(err);
  };

  React.useEffect(() => {
    if (!window.ReactNativeWebView) {
      return;
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      setCoordinate({ lat, lng });
    });
  }, []);
  // React.useEffect(() => {
  //   console.log(`${JSON.stringify(coordinate)}`);
  // }, [coordinate]);
  // React.useEffect(() => {
  //   console.log(`${JSON.stringify(coordinate)}`);
  // }, [coordinate]);

  const [value, setValue] = React.useState("restaurant");
  const [qrdata, setqrData] = React.useState("Not Found");

  const onClusterclick = (_target, cluster) => {
    const map = mapRef.current;
    // console.log("onClusterclick", _target, cluster);
    // 현재 지도 레벨에서 1레벨 확대한 레벨
    // const level = map.getLevel() - 1;
    const child = cluster.getMarkers().map((v) => JSON.parse(v.getTitle()));
    const childIds = child.map((obj) => obj.id);
    // console.log(child, childIds);

    history.push("/mapdetail", { coordinate, data: child, ids: childIds });
    // 지도를 클릭된 클러스터의 마커의 위치를 기준으로 확대합니다
    // map.setLevel(level, {anchor: cluster.getCenter()});
  };

  // React.useEffect(() => {
  //   setPositions(mapExampleData.positions);
  // },[])

  React.useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ key: "coordinate", data: "흠" })
    );
  }, []);
  React.useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ key: "expoToken", data: "흠" })
    );
  }, []);
  const { loading, error } = useInjectKakaoMapApi({
    appkey: "86d0fb152d4eb19ea7bb774cd3c7809c", // 발급 받은 APPKEY
    libraries: ["clusterer"],

    // 추가 옵션
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false,
  });
  const data = [
    { title: "수거완료(입고) 다회용기", path: "/received" },
    { title: "다회용기 렌탈 요청 확인", path: "/requests" },
    // { title: "음식점 출고 예약", path: "/reserved" }
  ];

  const adminData = [
    { title: "음식점 리스트", path: "/adminrestuarantlist" },
    { title: "세척파트너 리스트", path: "/adminwashmatelist" },
    { title: "수거파트너 리스트", path: "/admindeliverylist" },
    { title: "용기 리스트", path: "/admincontainerlist" },
    { title: "진행중인 렌트 리스트", path: "/adminrentinglist" },
    { title: "완료된 렌트 리스트", path: "/adminrentedlist" },
    { title: "고객후기 리스트", path: "/adminreviewlist" },
  ];
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const AdminView = () => (
    <>
      <Page>
        <AdminAppBar menu={true} />
        <ListMenu data={adminData} />
      </Page>
    </>
  );
  const WashmateView = () => (
    <>
      {" "}
      <Page>
        <AppBar menu={true} />
        <ListMenu data={data} />
      </Page>
    </>
  );
  const DeliveryView = (props) => (
    <Page>
      <AppBar refresh={true} menu={true} />

      <Box>
        {coordinate && (
          <Map // 지도를 표시할 Container
            // center={{
            //   // 지도의 중심좌표
            //   lat: 36.2683,
            //   lng: 127.6358,
            // }}

            center={props.coordinate}
            style={{
              // 지도의 크기
              width: "100%",
              height: screen.height,

              // "calc(100% - 66px)",
            }}
            level={8} // 지도의 확대 레벨
            // level={12}
            ref={mapRef}
          >
            <MarkerClusterer
              averageCenter={true} // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
              minLevel={1} // 클러스터 할 최소 지도 레벨
              minClusterSize={1}
              styles={[
                {
                  width: "50px",
                  borderRadius: "8px",
                  backgroundColor: "white",
                  textAlign: "center",
                  padding: "10px",
                },
              ]}
              disableClickZoom={true} // 클러스터 마커를 클릭했을 때 지도가 확대되지 않도록 설정한다
              // 마커 클러스터러에 클릭이벤트를 등록합니다
              // 마커 클러스터러를 생성할 때 disableClickZoom을 true로 설정하지 않은 경우
              // 이벤트 헨들러로 cluster 객체가 넘어오지 않을 수도 있습니다
              onClusterclick={onClusterclick}
              //   onCreate={(a)=>{console.log("에이",a)
              //   console.log("에이",a.getClusterMarker())
              //   // console.log("에이",b.getClusterMarker())

              // }}
              // onClustered={(a) => {
              //   console.log("에이", a);
              // var content = (
              //   <div
              //     style={{
              //       width: "50px",
              //       borderRadius: "8px",
              //       backgroundColor: "red",
              //       textAlign: "center",
              //       padding: "5px",
              //     }}
              //   ></div>
              // );
              // console.log("에이", a.getClusterMarker().setContent(content));
              // console.log("에이",b.getClusterMarker())
              // }}
            >
              {console.log("waitingDeliveryRents", waitingDeliveryRents)}
              {waitingDeliveryRents &&
                waitingDeliveryRents.map((data, i) => {
                  return (
                    <MapMarker
                      key={`${data.id}`}
                      title={JSON.stringify(data)}
                      position={{
                        lat: data.data.customerInfo.lat,
                        lng: data.data.customerInfo.lng,
                      }}
                    />
                  );
                })}
            </MarkerClusterer>
            <Box
              style={{
                marign: "auto",
                position: "fixed",
                bottom: "0px",
                zIndex: 99,
                width: "100%",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {[
                  {
                    title: "예약된 수거",
                    description: "주소지에서 용기를 수거하세요",
                    chipTitle: "확인",
                    clicked: () => {
                      if (!reservedDeliveryRents.length) {
                        alert("예약된 수거가 없습니다");
                        return;
                      }
                      return history.push("reserveddelivery", {
                        coordinate,
                        data: reservedDeliveryRents,
                      });
                    },
                  },
                  {
                    title: "픽업한 수거",
                    description: "수거 주소지에 용기를 입고하세요",
                    chipTitle: "확인",
                    clicked: () => {
                      // console.log(pickedDeliveryRents.length);

                      if (!pickedDeliveryRents.length) {
                        alert("수거한 용기가 없습니다");
                        return;
                      }

                      return history.push("pickedupdelivery", {
                        coordinate,
                        data: pickedDeliveryRents,
                      });
                    },
                  },
                ].map((v) => {
                  return (
                    <React.Fragment>
                      <Paper style={{ width: "100%", padding: "10px" }}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box>
                            <Container>
                              <Typography variant={"subtitle2"}>
                                {v.title}
                              </Typography>
                              <Typography variant={"caption"}>
                                {v.description}
                              </Typography>
                            </Container>
                          </Box>
                          <Box>
                            <Container>
                              <Chip
                                label={v.chipTitle}
                                clickable
                                onClick={v.clicked}
                              ></Chip>
                            </Container>
                          </Box>
                        </Box>
                      </Paper>
                    </React.Fragment>
                  );
                })}
              </Box>
            </Box>
          </Map>
        )}
      </Box>
    </Page>
  );

  const FranchiseView = () => (
    <Page>
      <AppBar menu={true} />
      <EmptySpace />
      <Container>
        <SectionTitle title="다회용기가 필요하신가요?" />
        <EmptySpace />
        <EmptySpace />
        <ContainedButton
          onClick={() => {
            history.push("/requestcontainer", { coordinate });
          }}
          justifycontent="flex-end"
        >
          다회용기 요청
        </ContainedButton>
      </Container>
    </Page>
  );
  if (auth.userExtraInfo === null) {
    console.log("null userinfo");
    return <Loader />;
  }
  if (!coordinate) {
    console.log("null coordinate");
    return <Loader />;
  }

  if (
    auth.userExtraInfo.role === constant.role.delivery &&
    waitingDeliveryRents === null
  ) {
    return <Loader />;
  }
  if (
    auth.userExtraInfo.role === constant.role.franchise &&
    coordinate === null
  ) {
    return <Loader />;
  }
  if (coordinate === null) {
    return <Loader />;
  }
  if (
    auth.userExtraInfo.role === constant.role.delivery &&
    pickedDeliveryRents === null
  ) {
    return <Loader />;
  }
  if (
    auth.userExtraInfo.role === constant.role.delivery &&
    reservedDeliveryRents === null
  ) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      {/* <Button
        onClick={() => {
          // window.ReactNativeWebView.postMessage(
          //   JSON.stringify({ key: "test", data: "흠" })
          // );
        }}
      >
        확인
      </Button>
      {<Button>{coordinate && coordinate.lat}</Button>}
      {<Button>{expoPushKey && expoPushKey}</Button>} */}
      {auth.userExtraInfo &&
        auth.userExtraInfo.role === constant.role.franchise && (
          <FranchiseView />
        )}
      {auth.userExtraInfo &&
        auth.userExtraInfo.role === constant.role.delivery &&
        coordinate && <DeliveryView coordinate={coordinate} />}
      {auth.userExtraInfo &&
        auth.userExtraInfo.role === constant.role.washmate && <WashmateView />}
      {auth.userExtraInfo &&
        auth.userExtraInfo.role === constant.role.admin && <AdminView />}
    </React.Fragment>
  );
}

export default withRouter(App);
