import React from "react";
import { css } from "../../Const";
import {Box, Typography} from "@material-ui/core";

export default function DesText(props) {
  const marginSide = css.marginSide;
  const marginTop = css.marginTop;

  return (
    <Box style={{ width: "100%" }}>
      <Typography
        style={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "22px",
          color: "#323232",
          opacity: "0.8"
        }}
      >
        {props.children}
      </Typography>
    </Box>
  );
}
