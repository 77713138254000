import React from "react";
import { css } from "../../Const";
import logo from "assets/img/logo.png"
import Box from '@material-ui/core/Box';

export default function Logo(props) {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <img src={logo} style={{ width: "50%",  margin: "auto",marginTop: "96px" }} {...props} />

    </Box>
  );
}
