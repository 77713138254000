import React from "react";
import { css } from "../../Const";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import * as common from "../../common"
export default function CalenderControl(props) {

    const marginSide = css.marginSide;

    const handleCalender = async (arg) => {
        let date = props.calender.split("-");
        let year = Number(date[0]);
        let month = Number(date[1]);
        let lastTimeStamp = common.getTimeStamp();

        if (arg === "add") {
            if (props.calender === common.getTodayDateForm()) {
                return;
            }
            if (month === 12) {
                year = year + 1;
                month = 1;
                month = common.leadingZeros(month, 2);
                year = String(year);
                props.setCalender(year + "-" + month);
                props.fetchData(year + "-" + month)
                return;
            }
            month = month + 1;
            month = common.leadingZeros(month, 2);
            year = String(year);
            props.fetchData(year + "-" + month)

            props.setCalender(year + "-" + month);
        }
        if (arg === "dec") {
            //TODO increase callender by 1
            if (month === 1) {
                year = year - 1;
                month = 12;
                month = common.leadingZeros(month, 2);

                year = String(year);
                props.fetchData(year + "-" + month)

                props.setCalender(year + "-" + month);
                return;
            }
            month = month - 1;
            month = common.leadingZeros(month, 2);
            year = String(year);
        }

        props.fetchData(year + "-" + month)

        props.setCalender(year + "-" + month);
        return;
    };

    return (
        <><div style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "rows",
            justifyContent: "space-between",
            // top: "104px",
            zIndex: "55",
            position: "sticky",
            position: "-webkit-sticky",
            height: "76px",
        }}>  <NavigateBeforeIcon
                onClick={() => {
                    handleCalender("dec");
                }}
                style={{ width: "28px", height: "28px", marginLeft: "60px" }}

                alt="leftButton"
            />
            <span style={{ margin: "0 30px" }}>
                {props.calender && props.calender.replace("-", ".")}
            </span>
            <NavigateNextIcon
                style={{ width: "28px", height: "28px", marginRight: "60px" }}
                alt="rightButton"
                onClick={() => {
                    handleCalender("add");
                }}
            />
        </div></>
    );
}
