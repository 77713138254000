import React from "react";
import Link from "@material-ui/core/Link";
import { css } from "../../Const";
import {Button,Box} from "@material-ui/core";

export default function Blink(props) {
  const marginSide = css.marginSide;
  const bothMarginside = marginSide * 2;
  const marginTop = css.marginTop;
  return (
  
      <Link
        {...props}
      >
        {props.children}
      </Link>
    
  );
}
