import React from "react";

import Page from "../../../components/basic/Page";

import AppBar from "../../../components/basic/BappBar";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../../AuthContext";
import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const"
import ListMenu from "../../../components/basic/ListMenu";

const useStyles = makeStyles({
    table: {
        width: "100%"
    }
});


export default function SignUp(props) {
    const [value, setValue] = React.useState("restaurant");
    const classes = useStyles();
    const auth = useAuth();


    const [role, setRole] = React.useState(null)
    const [open, setOpen] = React.useState(false);
    const [deliveryRents, setDeliveryRents] = React.useState(null)
    const [pickedDeliveryRents, setPickedDeliveryRents] = React.useState(null)
    const [activeDeliveryRents, setactiveDeliveryRents] = React.useState(null)
    const [data, setData] = React.useState(null)
    React.useEffect(() => {

        (async () => {

            if (auth.userExtraInfo.role === constant.role.delivery) {
                console.log("잘들어오구만")
                //TODO 여기서 렌트 가저오기
                //현재 렌트 스테터스 웨이팅 딜리버리인 애들 가져오기
                const myPickups = await api.getUserActiveRents(auth.user.email)
                console.log("마이 픽업", myPickups)

                if (myPickups.code !== 200) {
                    alert(myPickups.msg)
                    return
                }
                setactiveDeliveryRents(myPickups.data)
                const dataPickups = myPickups.data.map((v) => {
                    return { title: v.data.customerInfo.mainAddress + " " + v.data.customerInfo.restAddress, path: `/retriving?rentId=${v.id}` }
                })
                setData(dataPickups)

            }
        })()

    }, [])

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,
        checkedG: false
    });
    const handleCheckBoxChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };


    // console.log("data", activeDeliveryRents)
    // const data = [
    //     { title: "입고된 용기", path: "/received" },
    //     { title: "음식점 분배 요청", path: "/requests" },
    //     { title: "용기 출고 예약 리스트", path: "/reserved" }
    // ];

    return (
        <Page>
            <AppBar menu={true} />
            {data && <ListMenu data={data} />}
        </Page>)
}
