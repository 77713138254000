import React from "react";
import { css } from "../../Const";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}
function ListMenu(props) {
  const classes = useStyles();
  const history = useHistory();

  const marginSide = css.marginSide;
  const data = props.data;
  return (
    <div className={classes.root}>
      {/* <List component="nav" aria-label="main mailbox folders">
      <ListItem button>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Inbox" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon>
        <ListItemText primary="Drafts" />
      </ListItem>
    </List>
    <Divider /> */}
      <List component="nav" aria-label="secondary mailbox folders">
        {data.map((i) => {
          return (
            <ListItem
              button
              divider={true}
              onClick={() => {
                history.push(i.path);
              }}
            >
              <ListItemText primary={i.title} />
              <ChevronRightIcon />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
export default withRouter(ListMenu);
