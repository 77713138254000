import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";
import Logo from "../../../components/basic/Logo";
import Link from "../../../components/basic/Link";
import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";
import TextArea from "../../../components/basic/TextArea";
import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import CheckBox from "../../../components/basic/BcheckBox";
import PolicyBox from "../../../components/basic/PolicyBox";
import AddressForm from "../../../components/basic/AddressForm";
import BankSelect from "../../../components/basic/BankSelect";
import FormGroup from "@material-ui/core/FormGroup";

import theme from "styles/theme"
import {Radio, Paper,Container,withStyles} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import * as constant from "../../../Const"
import * as api from "../../../replhadaAPI"
import * as common from "../../../common"
import {Header,Title,LastLink,SectionTitle} from "components/basic/Components";

import { useAuth } from "../../../AuthContext";


export default function SignUp(props) {
  const auth = useAuth();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [value, setValue] = React.useState(constant.role.franchise);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("")
  const [contact, setContact] = React.useState("")

  const [bank, setBank] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("")
  const [accountHolder, setAccountHolder] = React.useState("")


  const [franchiseName, setFranchiseName] = React.useState("")
  const [mainAddress, setMainAddress] = React.useState("");
  const [restAddress, setRestAddress] = React.useState("");

  const [washmateName, setWashmateName] = React.useState("")
  const [mainWashmateAddress, setMainWashmateAddress] = React.useState("");
  const [restWashmateAddress, setRestWashmateAddress] = React.useState("");

  const YelloRadio = withStyles({
    root: {
      
      '&$checked': {
        color: theme.palette.primary.main,
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const franchiseView = (<><EmptySpace />
    <Container>
    <DesText>가맹점 정보</DesText>
          <EmptySpace />
    <UpText>상호명</UpText>
    <OutLinedTextField value={franchiseName} setValue={setFranchiseName} placeholder="상호명" />
    <EmptySpace />
    <AddressForm
      mainAddress={mainAddress}
      setMainAddress={setMainAddress}
      restAddress={restAddress}
      setRestAddress={setRestAddress}
      fixable={true}
    />
    </Container>
    <EmptySpace />
    <EmptySpace />



  </>)

  const deliveryView = (<>
    <Container>
    <DesText>입금 계좌 정보</DesText>
    <EmptySpace />
    <UpText>수익금 입금 통장 번호</UpText>
    <OutLinedTextField inputProps={{ inputMode: 'numeric' }} value={accountNumber} setValue={setAccountNumber} placeholder="00000000000" />
    <EmptySpace />
    <UpText>계좌주</UpText>
    <OutLinedTextField value={accountHolder} setValue={setAccountHolder} placeholder="홍길동" />
    <EmptySpace />
    <UpText>은행</UpText>
    <BankSelect setValue={setBank} value={bank} />
    </Container>
    <EmptySpace /></>)
  const washmateView = (<><EmptySpace />
        <Container>
        <DesText>가맹점 정보</DesText>

    <UpText>상호명</UpText>
    <OutLinedTextField value={washmateName} setValue={setWashmateName} placeholder="상호명" />
    <EmptySpace />
    <AddressForm
      mainAddress={mainWashmateAddress}
      setMainAddress={setMainWashmateAddress}
      restAddress={restWashmateAddress}
      setRestAddress={setRestWashmateAddress}
      fixable={true}

    />
    <EmptySpace />
    <EmptySpace />
    </Container></>)

  return (
    <Page>
      {/* <AppBar backLink="/login" /> */}
      <Header title="파트너 회원가입"/>

      <EmptySpace />
      <Container>
      <DesText>파트너 가입</DesText>
      <EmptySpace />
        <FormGroup column>
          <RadioGroup
            aria-label="gender"
            name={constant.role.franchise}
            value={value}
            onChange={handleChange}
          >
            <Paper elevation={0} style={{margin:"4px 0 4px 0", borderColor: value === constant.role.franchise ? theme.palette.primary.main:""}} variant="outlined">
            <FormControlLabel
            style={{margin:"4px"}}
              value={constant.role.franchise}
              control={<YelloRadio color="#fcba03" />}
              label="가맹점"
            />
            </Paper>
            <Paper elevation={0} style={{margin:"4px 0 4px 0", borderColor: value === constant.role.delivery ? theme.palette.primary.main:""}} variant="outlined">
            <FormControlLabel
            style={{margin:"4px"}}
              value={constant.role.delivery}
              control={<YelloRadio color="#fcba03" />}
              label="수거 파트너"
            />
            </Paper>
            <Paper elevation={0} style={{margin:"4px 0 4px 0", borderColor: value === constant.role.washmate ? theme.palette.primary.main:""}} variant="outlined">
            <FormControlLabel
            style={{margin:"4px"}}
              value={constant.role.washmate}
              control={<YelloRadio color="#fcba03" />}
              label="세척 파트너"
            />
            </Paper>
          </RadioGroup>
        </FormGroup>
        </Container>
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      
      <Container>
      <DesText>회원정보</DesText>
      <EmptySpace />
      <UpText>이메일 </UpText>
      <OutLinedTextField type="email" value={email} setValue={setEmail} placeholder="abc@gmail.com" />
      <EmptySpace />
      <UpText>비밀번호 입력</UpText>
      <OutLinedTextField type="password" value={password} setValue={setPassword} placeholder="비밀번호" />
      <EmptySpace />
      <UpText>비밀번호 확인</UpText>
      <OutLinedTextField type="password" value={passwordConfirm} setValue={setPasswordConfirm} placeholder="비밀번호 확인" />
      <EmptySpace />
      
      <UpText>연락처</UpText>
      <OutLinedTextField value={contact} inputProps={{ inputMode: 'numeric' }} setValue={setContact} placeholder="01098761234" />
      </Container>
      <EmptySpace />
      <EmptySpace />
      {/* <OptionalView role={value} /> */}
      {value === constant.role.franchise && franchiseView}
      {value === constant.role.delivery && deliveryView}
      {value === constant.role.washmate && washmateView}

      <PolicyBox
        label="개인정보 동의"
        checked={state.checkedA}
        onChange={handleCheckBoxChange}
        name="checkedA"
        url="https://partners.replhada.com/private_policy"
      />
      <EmptySpace />
      <PolicyBox
        label="위치 정책정보 동의"
        checked={state.checkedB}
        onChange={handleCheckBoxChange}
        name="checkedB"
        url="https://partners.replhada.com/service_policy"
      />
      <EmptySpace />
      <DesText>가맹점과 세척파트너는 당사의 매니저와 사전 협의가 필요합니다</DesText>
      <EmptySpace />
      <Container>
      <ContainedButton
        onClick={async () => {
          if (!!!email || !!!password || !!!passwordConfirm || !!!contact) {
            alert("필수 사항을 입력해 주세요")
            return
          }
          if (!common.isValidEmail(email)) {
            alert("옳바르지 않은 이메일 입니다")
            return
          }
          if (password !== passwordConfirm) {
            alert("비밀번호와 비밀번호확인이 같지 않습니다")
            return
          }
          if (!state.checkedA || !state.checkedB) {
            alert("정책 동의가 필요합니다")
            return
          }
          if (value === constant.role.franchise) {
            if (!!!franchiseName || !!!mainAddress || !!!restAddress) {
              alert("음식점 정보가 필요합니다")
              return
            }


          } else if (value === constant.role.delivery) {

            if (!!!accountNumber || !!!accountHolder || !!!bank) {
              console.log(accountNumber, accountHolder, bank)
              alert("수익 입금 정보가 필요합니다")
              return
            }
          } else if (value === constant.role.washmate) {
            if (!!!washmateName || !!!mainWashmateAddress || !!!restWashmateAddress) {
              alert("음식점 정보가 필요합니다")
              return
            }
          }

          const result = await api.getUser(email)

          if (result.code === 200) {
            alert("이미 존재하는 유저 입니다")
            return
          }



          let userData = { contact, "role": value, bank: "", accountNumber: "", accountHolder: "", franchiseDocs: [], washmateDocs: [] }
          let data = {}


          if (value === constant.role.franchise) {
            let franchiseData = {}
            franchiseData["storeName"] = franchiseName
            franchiseData["mainAddress"] = mainAddress
            franchiseData["restAddress"] = restAddress
            let result = await api.createFranchise(franchiseData)
            if (result.code !== 200) {
              alert(result.msg + "99")
              return
            }
            const franchiseId = result.id
            userData["franchiseDocs"] = [franchiseId]


            //여기에서 프렌차이즈 닥 만들기
          } else if (value === constant.role.delivery) {
            userData["accountHolder"] = accountHolder
            userData["accountNumber"] = accountNumber
            userData["bank"] = bank
            //여기에서 프렌차이즈 닥 만들기
          } else if (value === constant.role.washmate) {

            let washmateData = {}
            washmateData["storeName"] = washmateName
            washmateData["mainAddress"] = mainWashmateAddress
            washmateData["restAddress"] = restWashmateAddress
            washmateData["owner"] = email
            let returnPointData = {}
            returnPointData["storeName"] = washmateName
            returnPointData["mainAddress"] = mainWashmateAddress
            returnPointData["restAddress"] = restWashmateAddress
            returnPointData["qrinfo"] = common.shuffle(7)

            let result1 = await api.createReturnPoint(returnPointData)
            if (result1.code !== 200) {
              alert(result1.msg + "91")
              return
            }
            washmateData["returnPointDocs"] = [result1.id]

            let result2 = await api.createWashmate(washmateData)
            if (result2.code !== 200) {
              alert(result2.msg + "92")
              return
            }
            userData["washmateDocs"] = [result2.id]

          }
          console.log("유저 데이터", userData)

          const isInfoUpdated = await api.createUser(email, userData)

          if (isInfoUpdated.code !== 200) {
            alert(isInfoUpdated.msg + "93")
            return
          } else {

            try {

              const result = await auth.singUpWithEmail(
                email,
                password
              );

              if (result.code !== 200) {
                alert(result.msg + "90")
                return
              }
            } catch (e) {
              console.log(e)
            }






            alert("가입이 완료되었습니다")
            props.history.push("/main")
          }


          return
        }
        }

      >완료</ContainedButton>
      </Container>
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
    </Page >
  );
}
