import React from "react";
import { Box,Typography,Grid,Container,Link,Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export const Header = (props) => {
    const history = useHistory();
    return (
        <React.Fragment>                
            <Box display="flex" justifyContent="center" alignItems= "center"
             sx={{width:"100%", height:"66px"}}>
             <Grid container >
                 <Grid item xs={4}>
                <Box display="flex" justifyContent="center" alignItems= "center">
                 {props.leftButton === null ? "" : <ArrowBackIosIcon onClick={()=>history.goBack()} />}
                 </Box>
                 </Grid>
                 <Grid  item xs={4}>
                 <Box display="flex" justifyContent="center" alignItems= "center" sx={{height:"100%"}}>
                <Typography variant="subtitle1" align="center" > {props.title}</Typography>
                </Box>
                </Grid>
                <Grid item xs={4}>
                <Box display="flex" justifyContent="center" alignItems= "center">
                {props.rightButton && (props.rightButton)}
                </Box>
                </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export  const Title = (props) => {
    return ( 
            <React.Fragment>
        <Container>
            <Box my={2}>
            <Typography {...props} variant="h5">{props.title}</Typography>
            </Box>
        </Container>
            </React.Fragment>

        )
}

export const SectionTitle = (props) => {
    return ( 
            <React.Fragment>
        <Container>
            <Box my={2}>
            <Typography variant="h6">{props.title}</Typography>
            </Box>
        </Container>
            </React.Fragment>

        )
}

export const LastLink = (props) =>{
    return ( <React.Fragment>
        <Box  sx={{width: "100%"}} my={1} display="flex" justifyContent="flex-end" alignItems= "center">
        <Link variant="body2"color={"primary"} underline="always" {...props}  >   

        {props.title}
        </Link>
        </Box>
  </React.Fragment>)
  }

export  const FixedButton = (props) =>{
    return ( <React.Fragment>
        <Box justifyContent="center" alignItems="center" display="flex"
        sx={{position:"fixed",bottom:"0px", left:"0px", right:"0px", width:"100%", height:"60px"}}>
            <Container>
          <Button 
          fullWidth
          variant="contained"
          
          color={"primary"}
          {...props}
          >
              <Typography>완료</Typography>
          </Button>
          </Container>
          </Box>
    </React.Fragment>)
}
