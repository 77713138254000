import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";
import { useAuth } from "../../../AuthContext";

import UpText from "../../../components/basic/UpText";
import ContentText from "../../../components/basic/ContentText";

import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import * as common from "../../../common";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import qs from "qs";
import * as api from "../../../replhadaAPI";
import * as constant from "../../../Const";
import { useLocation } from "react-router-dom";

export default function Retrive(props) {
  const auth = useAuth();

  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true, // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
  });
  const rentId = query.rentId;
  const [rent, setRent] = React.useState(null);
  const [returnPoint, setReturnPoint] = React.useState(null);
  // const [washmate, setWashmate] = React.useState(null);
  const [data, setData] = React.useState(null);

  const [restaurant, setRestaurant] = React.useState(null);
  const [washmate, setWashmate] = React.useState(null);
  const [delivery, setDelivery] = React.useState(null);
  const location = useLocation();

  React.useEffect(() => {
    console.log(location.pathname); // result: '/secondpage'
    console.log(location.search); // result: '?query=abc'
    console.log("ㄷㅔ이터터", location.state.data); // result: 'some_value'
    setData(location.state.data);
  }, [location]);

  React.useEffect(() => {
    (async () => {
      if (data === null) {
        return;
      }
      if (typeof data.data.franchiseInfo !== "undefined") {
        const restaurantdata = await api.getFranchise(
          data.data.franchiseInfo.doc
        );
        setRestaurant(restaurantdata);
      }

      if (typeof data.data.franchiseInfo !== "undefined") {
        const washmatedata = await api.getWashmate(data.data.washmate.doc);
        setWashmate(washmatedata);
      }
      if (typeof data.data.franchiseInfo !== "undefined") {
        const deliverydata = await api.getDelivery(data.data.delivery.owner);
        if (deliverydata.code !== 200) {
          return;
        }
        setDelivery(deliverydata);
      }
    })();
  }, [data]);

  const [value, setValue] = React.useState("restaurant");
  const [refresh, setRefresh] = React.useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false,
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const info =
    rent &&
    (rent.data.status === constant.rentStatus.deliveryReserved
      ? "다회용기의를 회수하여 용기의 QR을 찍어주세요"
      : rent.data.status === constant.rentStatus.delivieryPicikup
      ? "수거장소로 다회용기를 배달하여 수거장소 QR을 스캔하여 완료해 주세요"
      : "");

  return (
    <Page style={{ backgroundColor: "grey" }}>
      <AppBar backLink="/adminrentedlist" />
      <EmptySpace />

      <SubTitle>{data && data.data.status}</SubTitle>
      <EmptySpace />
      <UpText>렌트아이디: {data && data.id}</UpText>
      <EmptySpace />

      <UpText>{info}</UpText>
      {restaurant && restaurant.data && (
        <>
          <EmptySpace />
          <EmptySpace />
          <DesText>음식점 정보</DesText>
          <EmptySpace />
          <Card>
            <UpText>상호 </UpText>
            <ContentText>
              {restaurant && restaurant.data?.storeName}
            </ContentText>
            <UpText>주소 </UpText>
            <ContentText>
              {restaurant && restaurant.data?.mainAddress}
            </ContentText>
            <ContentText>
              {restaurant && restaurant.data?.restAddress}
            </ContentText>
            <EmptySpace />
          </Card>
          <EmptySpace />
          <EmptySpace />
        </>
      )}

      {data && data.data.customerInfo && (
        <>
          <EmptySpace />
          <EmptySpace />
          <DesText>고객정보</DesText>
          <EmptySpace />
          <Card>
            <UpText>요청 시각 </UpText>
            <ContentText>
              {data &&
                data.data?.customerInfo &&
                common.getTimeStamp(data.data.customerInfo?.addedBy)}
            </ContentText>
            <UpText>주소 </UpText>
            <ContentText>
              {data &&
                data.data?.customerInfo &&
                data.data.customerInfo?.mainAddress}
            </ContentText>
            <ContentText>
              {data &&
                data.data?.customerInfo &&
                data.data.customerInfo?.restAddress}
            </ContentText>
            <EmptySpace />
          </Card>
          <EmptySpace />
          <EmptySpace />
        </>
      )}

      {delivery && delivery.data && (
        <>
          <EmptySpace />
          <EmptySpace />
          <DesText>회수 정보</DesText>
          <EmptySpace />
          <Card>
            <UpText>회수자 </UpText>
            <ContentText>{delivery && delivery?.id}</ContentText>
            <UpText>회수자 정보 </UpText>
            <ContentText>
              {delivery && delivery.data?.accountNumber}
            </ContentText>
            <ContentText>
              {delivery && delivery.data?.accountHolder}
            </ContentText>
            <EmptySpace />
          </Card>
          <EmptySpace />
          <EmptySpace />
        </>
      )}
      {washmate && washmate.data && (
        <>
          <EmptySpace />
          <EmptySpace />
          <DesText>세척업체</DesText>
          <EmptySpace />
          <Card>
            <UpText>세척업체 </UpText>
            <ContentText>{washmate && washmate.data?.storeName}</ContentText>
            <UpText>주소 </UpText>
            <ContentText>{washmate && washmate.data?.mainAddress}</ContentText>
            <ContentText>{washmate && washmate.data?.restAddress}</ContentText>
            <EmptySpace />
          </Card>
          <EmptySpace />
          <EmptySpace />
        </>
      )}
      {data && data.data.returnPoint && (
        <>
          <EmptySpace />
          <EmptySpace />
          <DesText>회수장소</DesText>
          <EmptySpace />
          <Card>
            <UpText>회수장소 </UpText>
            <ContentText>
              {data &&
                data.data.returnPoint &&
                data.data.returnPoint.data?.storeName}
            </ContentText>
            <UpText>주소 </UpText>
            <ContentText>
              {data &&
                data.data.returnPoint &&
                data.data.returnPoint.data?.mainAddress}
            </ContentText>
            <ContentText>
              {data &&
                data.data.returnPoint &&
                data.data.returnPoint.data?.restAddress}
            </ContentText>
            <EmptySpace />
          </Card>
          <EmptySpace />
          <EmptySpace />
        </>
      )}
    </Page>
  );
}
