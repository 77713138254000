import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";

import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const";
import * as common from "../../../common"
import { useAuth } from "../../../AuthContext";
import moment from "moment"

import Paper from "@material-ui/core/Paper";

//레비뉴 테이블
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import {Container,TableHead} from "@material-ui/core";
import {Washmate} from "Classes"


import CalenderControl from "../../../components/basic/CalenderControl";
import RevenueDisplay from "../../../components/basic/RevenueDisplay";

export default function Revenue(props) {
  const auth = useAuth();


  const fetchingApi = async (date) => {
    console.log("데이트", date)
    console.log("데이트", common.getTodayDateForm())
    
    if (auth.userExtraInfo.role === constant.role.delivery) {

      const result = await api.getRevenuRent(auth.userExtraInfo.role, auth.user.email, date)
      console.log("페칭",result.data)
      setData(result.data)
      let sum = 0

      result && result.data.map((data) => {
         sum += data.data.containerIds.length
      })
      console.log(sum)
      setEarning(sum)
      setTotalRents(result.data.length)

    } else if (auth.userExtraInfo.role === constant.role.washmate) {
    
      const washmate = new Washmate(auth.user.email)
      const apiResult = await washmate.getRevenue(date)
      console.log(apiResult)
      setData(apiResult.data)
      let sum = 0

      apiResult.data && apiResult.data.map((data) => {
        sum += data.data.containerIds.length
      })
      console.log(sum)
      setEarning(sum)
      setTotalRents(apiResult.data.length)


    }
  }

  React.useEffect(() => {
    setCalender(common.getTodayDateForm());

    (async () => {
      if (auth.userExtraInfo.role === constant.role.delivery) {

        const result = await api.getRevenuRent(auth.userExtraInfo.role, auth.user.email, common.getTodayDateForm())
        console.log("처음",result.data)

        setData(result.data)
        let sum = 0

        result.data && result.data.map((data) => {
          sum += data.data.containerIds.length
        })
        console.log(sum)
        setEarning(sum)
        setTotalRents(result.data.length)

      } else if (auth.userExtraInfo.role === constant.role.washmate) {
        const washmate = new Washmate(auth.user.email)
        const apiResult = await washmate.getRevenue(common.getTodayDateForm())
        console.log(apiResult)
        setData(apiResult.data)
        let sum = 0

        apiResult.data && apiResult.data.map((data) => {
          sum += data.data.containerIds.length
        })
        console.log(sum)
        setEarning(sum)
        setTotalRents(apiResult.data.length)


      }
    })()

  }, [])
  const [apiData, setData] = React.useState(null);
  const [earning, setEarning] = React.useState(0);
  const [totalRents, setTotalRents] = React.useState(0);

  const [calender, setCalender] = React.useState(null);






  const washMaterevenueTable = (<>
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
      <TableHead>
          <TableRow>
            <TableCell>날짜</TableCell>
            <TableCell>반납 장소</TableCell>
            <TableCell align="right">수량</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          
          {apiData && !apiData.length && (
            <div
              style={{
                width: "100%",
                height: "100px",
                textAlign: "center",
                alignSelf: "center",
                display: "flex"
              }}
            >
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  alignSelf: "center"
                }}
              >
                데이터가 없습니다
              </span>
            </div>
          )}
          {

            auth.userExtraInfo.role === constant.role.washmate && apiData && apiData.length !== 0 && apiData.map((i, index) => {
              return (
                <TableRow key={index} style={{ height: "90px" }}>
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      verticalAlign: "middle"
                    }}
                    component="th"
                    scope="row"
                  >
                    {/* <p>{(i.data.washmate.updatedBy )}</p> */}
                    <p>{moment(i.data.updatedBy ).format('MM-DD HH:mm')}</p>
                  </TableCell>

                  <TableCell
                    style={{ height: "60px", verticalAlign: "middle" }}
                    align="left"
                  >
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "16px"
                        // color: "#00838F"
                      }}
                    >
                      {i.data.returnPoint.storeName}
                    </p>
                    {/* <p
                      style={{
                        fontWeight: "400",
                        fontSize: "16px"
                        // color: "#00838F"
                      }}
                    >
                      {i.data.customerInfo.restAddress}
                    </p> */}

                  </TableCell>
                  <TableCell
                    style={{ height: "60px", verticalAlign: "middle" }}
                    align="left"
                  >
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "16px"
                        // color: "#00838F"
                      }}
                    >
                     {i.data.containerIds.length}개
                    </p>
                    {/* <p
                      style={{
                        fontWeight: "400",
                        fontSize: "16px"
                        // color: "#00838F"
                      }}
                    >
                      {i.data.customerInfo.restAddress}
                    </p> */}

                  </TableCell>
                  <TableCell
                    style={{ height: "60px", verticalAlign: "middle" }}
                    align="right"
                  >
                    {/* <p
                      style={{
                        fontSize: "13px",
                        color: "#263238"
                      }}
                    >
                     
                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        color: "#263238"
                      }}
                    >
                      {i.status}
                    </p> */}
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "800",
                        fontSize: "18px",
                        // marginTop: "8px"
                      }}
                    >
                      {/* {(i.data.pricing.delivery * Number(i.data.response.amount.typeA)) */}
                        {/* + "원"} */}
                    </p>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  </>)

  const deliveryRevenueTable = (<>
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
      <TableHead>
          <TableRow>
            <TableCell>날짜</TableCell>
            <TableCell>반납 장소</TableCell>
            <TableCell align="right">수량</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {apiData && !apiData.length && (
            <div
              style={{
                width: "100%",
                height: "100px",
                textAlign: "center",
                alignSelf: "center",
                display: "flex"
              }}
            >
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  alignSelf: "center"
                }}
              >
                데이터가 없습니다
              </span>
            </div>
          )}
          {

            auth.userExtraInfo.role === constant.role.delivery && apiData && apiData.length !== 0 && apiData.map((i, index) => {
              return (
                <TableRow key={index} style={{ height: "90px" }}>
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      verticalAlign: "middle"
                    }}
                    component="th"
                    scope="row"
                  >
                    <p>{moment(i.data.updatedBy).format('MM-DD HH:mm')}</p>
                  </TableCell>

                  <TableCell
                    style={{ height: "60px", verticalAlign: "middle" }}
                    align="left"
                  >
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "16px"
                        // color: "#00838F"
                      }}
                    >
                      {i.data.returnPoint.storeName ?? ""}
                    </p>
                    {/* <p
                    style={{
                      fontWeight: "400",
                      fontSize: "16px"
                      // color: "#00838F"
                    }}
                  >
                    {i.data.customerInfo.restAddress}
                  </p> */}

                  </TableCell>
                  <TableCell
                    style={{ height: "60px", verticalAlign: "middle" }}
                    align="right"
                  >
                    {/* <p
                    style={{
                      fontSize: "13px",
                      color: "#263238"
                    }}
                  >
                   
                  </p>
                  <p
                    style={{
                      fontSize: "13px",
                      color: "#263238"
                    }}
                  >
                    {i.status}
                  </p> */}
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "800",
                        fontSize: "18px",
                        // marginTop: "8px"
                      }}
                    >
                      {(i.data.containerIds.length)
                        + "개"}
                    </p>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  </>)

  return (
    <Page>
      <AppBar backLink="/login" />
      <EmptySpace />
    <Container>
      <DesText>다회용기
        {auth.userExtraInfo && auth.userExtraInfo.role === constant.role.washmate && " 세척"}
        {auth.userExtraInfo && auth.userExtraInfo.role === constant.role.delivery && " 수거"}


        수량 확인</DesText>
      <EmptySpace />
      <UpText>{auth.userExtraInfo && auth.userExtraInfo.role === constant.role.washmate && " 세척"}
        {auth.userExtraInfo && auth.userExtraInfo.role === constant.role.delivery && " 수거"}

        수량에 따라 자동 정산 됩니다
      </UpText>
      <UpText>정산된 수익은 익월 10일 입력하신 계좌로 입금 됩니다
      </UpText>
      </Container>
      <EmptySpace />
      < CalenderControl calender={calender} setCalender={setCalender} fetchData={fetchingApi} />
      <RevenueDisplay calender={calender} totalRent={totalRents} totalRevenue={earning} />
      {auth.userExtraInfo && auth.userExtraInfo.role === constant.role.washmate && washMaterevenueTable}
      {auth.userExtraInfo && auth.userExtraInfo.role === constant.role.delivery && deliveryRevenueTable}


      <EmptySpace />
    </Page>
  );
}
