import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useGlobal } from "../../../globalContext";
import { useAuth } from "../../../AuthContext";
import EmptySpace from "../../../components/basic/EmptySpace";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";
import Logo from "../../../components/basic/Logo";
import Link from "../../../components/basic/Link";
import UpText from "../../../components/basic/UpText";
import ContainedButton from "../../../components/basic/ContainedButton";

import { useHistory } from "react-router-dom";
import { Box, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  emptySpace: { width: "100%", height: "44px" },
  headerSpace: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "60px",
  },
  headerTitle: { fontSize: "18px", fontWeight: "bold", margin: "auto" },
  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

function LoginPage(props) {
  let history = useHistory();

  const classes = useStyles(props);
  const context = useGlobal();
  const auth = useAuth();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setLoding] = React.useState(false);

  React.useEffect(() => {
    !auth.user || props.history.push("/main");
  }, []);
  return (
    <>
      <Page>
        <header>
          <Logo />
        </header>

        <main>
          <Box sx={{ mt: 6 }}>
            <Container>
              <UpText>이메일</UpText>
              <OutLinedTextField
                placeholder="Email"
                type="email"
                value={email}
                setValue={setEmail}
              />
              <EmptySpace />
              <UpText>비밀번호</UpText>
              <OutLinedTextField
                // label="Phone Number"
                placeholder="Password"
                type="password"
                // helperText="투자하신 기기 수량만큼 수익이 창출됩니다"
                value={password}
                setValue={setPassword}
              />
            </Container>
          </Box>
          <Box>
            <Container>
              <ContainedButton
                style={{
                  height: "46px",
                  marginTop: "24px",
                  color: "white",
                  backgroundColor: "black",
                }}
                onClick={async () => {
                  history.push("/main");
                  setLoding(true);
                  try {
                    const result = await auth.singInWithEmail(email, password);
                    if (result.code !== 200) {
                      setLoding(false);

                      history.push("/login");

                      return;
                    }
                    setLoding(false);
                    history.push("/main");
                  } catch (error) {
                    window.alert("login:on error", error);
                    setLoding(false);
                    history.push("/login");

                    return;
                  }
                }}
              >
                로그인
              </ContainedButton>

              <Container>
                <Box
                  mt={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Link style={{ color: "black" }} href="/signup">
                    파트너 가입
                  </Link>
                  <Link style={{ color: "black" }} href="/forgotpassword">
                    비밀번호 재설정
                  </Link>

                  {auth.isLogin ?? (
                    <Link
                      color="black"
                      onClick={() => {
                        auth.signOut();
                        console.log("싸인아웃");
                      }}
                    >
                      로그아웃
                    </Link>
                  )}
                </Box>
              </Container>
            </Container>
          </Box>
        </main>
        <footer></footer>
        {isLoading && (
          <CircularProgress
            style={{
              position: "fixed",
              transitionDelay: { isLoading } ? "800ms" : "0ms",
              left: "calc(50% - 15px)",
              top: "49%",
            }}
          />
        )}
      </Page>
    </>
  );
}

export default LoginPage;
