


import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Link from "../../../components/basic/Link";

export default function SignUp(props) {
    const [value, setValue] = React.useState("restaurant");

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,
        checkedG: false
    });
    const handleCheckBoxChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (


<>
  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:굴림;\n\tpanose-1:2 11 6 0 0 1 1 1 1 1;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:"맑은 고딕";\n\tpanose-1:2 11 5 3 2 0 0 2 0 4;}\n@font-face\n\t{font-family:"\\@굴림";\n\tpanose-1:2 11 6 0 0 1 1 1 1 1;}\n@font-face\n\t{font-family:"\\@맑은 고딕";}\n@font-face\n\t{font-family:Tahoma;\n\tpanose-1:2 11 6 4 3 5 4 4 2 4;}\n@font-face\n\t{font-family:"Segoe UI";\n\tpanose-1:2 11 5 2 4 2 4 2 2 3;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:8.0pt;\n\tmargin-left:0cm;\n\ttext-align:justify;\n\ttext-justify:inter-ideograph;\n\tline-height:107%;\n\ttext-autospace:none;\n\tword-break:break-hangul;\n\tfont-size:10.0pt;\n\tfont-family:"맑은 고딕",sans-serif;}\n.MsoChpDefault\n\t{font-family:"맑은 고딕",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:8.0pt;\n\ttext-align:justify;\n\ttext-justify:inter-ideograph;\n\tline-height:107%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:595.3pt 841.9pt;\n\tmargin:3.0cm 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
    }}
  />
  <div className="WordSection1">
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "22.5pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스
      </span>
      <span
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "22.5pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용약관
      </span>
    </p>
    <div
      className="MsoNormal"
      align="center"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "18.75pt",
        marginLeft: "0cm",
        textAlign: "center",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        <hr size={0} width="100%" align="center" />
      </span>
    </div>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        마지막
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        업데이트
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        날짜
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        : 2021
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        년
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        11
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        월
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          1
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          목적
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        약관은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        디비아이
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이하
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        "
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        "
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        가
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보서비스와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관련된
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기능이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        내장된
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        운영체제
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        웹브라우저
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기타
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        프로그램
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이하
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        “
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        프로그램
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ”
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        사용되고
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        귀하의
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        PC,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        타블렛
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        PC,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정보처리기기
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        통신기기
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이동전화
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        등의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        각종
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기기
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이하
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        “
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ”
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        해당
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        지리적
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정보만을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        의미하며
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        해당
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용자
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        등
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        특정
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        개인의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보와는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        무관합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수집하고
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보사업자의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        지위에서
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        보호
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        등에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        법률
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이하
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        “
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보법
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ”{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        규정에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        따라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치기반서비스사업자에게
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공함에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있어
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        필요한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        귀하
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        사이의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        권리
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        의무
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기타
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제반
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        사항을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정함을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        목적으로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          2
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          위치정보
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          수집에
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          관한
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          동의
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치기반서비스사업자가
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        귀하에게
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치기반서비스를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공할
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있도록
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수집하여
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치기반서비스사업자에게
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공하며
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        귀하는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        본
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        약관에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        동의함으로써
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수집
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치기반서비스사업자에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        대한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        동의합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          3
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          위치정보
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          수집에
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          관한
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          동의의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          철회
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          등
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          귀하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          다음
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          각호의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          방법을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          통하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수집에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          철회할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          (
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          다만
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          윈도우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          폰
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          기타
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          휴대
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기에서
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          귀하가
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          “Find My Phone”
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          과
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          같이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          탐지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          기능이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          웹
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          기반
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사용한다면
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스기능의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          활성화
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          여부와
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          무관하게
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수집될
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ).
        </span>
      </li>
      <ol style={{ marginTop: "0cm" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            회사
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            프로그램을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            사용하기로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            계약을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            해지하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            방법
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ;{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            혹은
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기에서
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치정보서비스의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            기능을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            비활성화하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            방법
          </span>
        </li>
      </ol>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "36.0pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        같은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보서비스
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기능의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        비활성화는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        프로그램
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        또는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        종류에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        따라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있고
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        동의를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        철회하더라도
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        귀하가
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        해당
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        프로그램의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보서비스
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기능을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        활성화한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        경우에는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사가
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다시
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수집합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
    </p>
    <ol start={2} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          귀하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          설치된
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          프로그램
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          (
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          웹페이지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          소프트웨어
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          포함함
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ){" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이외의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그러한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          못하도록
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          설정하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          방법으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관련
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스사업자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공되어
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용되는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          것에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          철회할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있고
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          귀하가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          활성화한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          다시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          해당
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          소프트웨어
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          4
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          위치정보
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          수집방법
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다음의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        방법으로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다음과
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        같은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수집합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수집대상
        </span>
      </li>
      <ul style={{ marginTop: "0cm" }} type="circle">
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기
          </span>
        </li>
      </ul>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수집되는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보
        </span>
      </li>
      <ul style={{ marginTop: "0cm" }} type="circle">
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            현재
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            (
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용자
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            식별할
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인정보
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            내지
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인위치정보
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등은
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            일체
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수집되지
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            않습니다
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            )
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            의하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            식별된
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            기지국과
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            Wi-Fi{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            액세스포인트의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
            (
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용자
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            식별할
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인정보
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            내지
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인위치정보
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등은
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            일체
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수집되지
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            않습니다
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            )
          </span>
        </li>
      </ul>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수집방법
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "36.0pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        귀하가
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보서비스
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기능을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        활성화하고
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        필요로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        하는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치기반서비스를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용하는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        경우
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다음의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        방법
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        중
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        하나
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이상의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        방법을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        선택하여
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수집합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다만
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        아래와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        같은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수집방법은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정확도
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        개선
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용자들의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        편익을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위하여
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수시로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        변경될
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있습니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <ol style={{ marginTop: "0cm" }} start={3} type={1}>
      <ul style={{ marginTop: "0cm" }} type="circle">
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치정보를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            요청하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <ul style={{ marginTop: "0cm" }} type="square">
          <li
            className="MsoNormal"
            style={{
              color: "black",
              marginBottom: "9.4pt",
              textAlign: "left",
              lineHeight: "normal",
              background: "white",
              textAutospace: "ideograph-numeric ideograph-other",
              wordBreak: "keep-all"
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              단말기가
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              인터넷
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              등의
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              통신망에
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              연결되어
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              있는
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              경우
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              회사
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              프로그램이
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              단말기의
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              위치정보를
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              회사에
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              전달하고
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              ,{" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              회사는
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              IP
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              주소
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              등을
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              분석하여
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              단말기의
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              정확한
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              위치를
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              파악합니다
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              .
            </span>
          </li>
          <li
            className="MsoNormal"
            style={{
              color: "black",
              marginBottom: "9.4pt",
              textAlign: "left",
              lineHeight: "normal",
              background: "white",
              textAutospace: "ideograph-numeric ideograph-other",
              wordBreak: "keep-all"
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              단말기의
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              Wi-Fi
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              기능이
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              활성화되어
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              있는
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              경우
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              ,{" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              회사
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              프로그램이
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              단말기와
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              인접한
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              Wi-Fi{" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              액세스포인트의
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              MAC
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              주소와
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              신호강도
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              등
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              데이터를
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              수집하여
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              회사에
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              전달하고
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              ,{" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              회사는
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              해당
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              Wi-Fi{" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              액세스포인트에
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              대한
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              데이터베이스를
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              활용하여
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              대략적인
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              위치를
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              파악합니다
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              .
            </span>
          </li>
          <li
            className="MsoNormal"
            style={{
              color: "black",
              marginBottom: "9.4pt",
              textAlign: "left",
              lineHeight: "normal",
              background: "white",
              textAutospace: "ideograph-numeric ideograph-other",
              wordBreak: "keep-all"
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              통신기능이
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              있는
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              단말기의
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              경우
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              ,{" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              회사
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              프로그램이
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              단말기와
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              인접한
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              기지국의
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              식별정보
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              등
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              데이터를
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              수집하여
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              회사에
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              전달하고
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              ,{" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              회사는
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              자체
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              데이터베이스를
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              활용하여
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              대략적인
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              위치를
            </span>
            <span
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              {" "}
            </span>
            <span
              style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
            >
              파악합니다
            </span>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Segoe UI",sans-serif'
              }}
            >
              .
            </span>
          </li>
        </ul>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기의
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            GPS{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            기능이
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            활성화되어
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
      </ul>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "72.0pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        해당
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기가
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        식별한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기지국과
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        Wi-Fi{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        액세스
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        포인트의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        대략적인
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        자동으로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기록합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        그러한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        데이터는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        익명으로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        획득되며
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        특정
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        사용자
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        또는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        연결되지
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        않습니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        데이터는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기내에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기록되며
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기술
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관련
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제품
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        성능과
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정확성
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        향상을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위하여
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        주기적으로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        전송됩니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          4
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조의
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          2 (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          위치정보
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          수집사실
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          확인
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          자료의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          보유근거
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          및
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          보유기간
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        타사업자
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        또는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        고객과의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        요금정산
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        민원처리를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위해
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ·
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공사실
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        확인자료를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        자동
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기록
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ·
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        보존하며
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        해당
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        자료는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        목적
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        달성을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위해
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        필요한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기간
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        동안
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        보관합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          5
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          회사가
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          위치기반서비스사업자에게
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제공하고자
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          하는
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          서비스의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          내용
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          및
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조건
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          다음
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          중
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          하나
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이상의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스사업자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <ol style={{ marginTop: "0cm" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            및
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            고도
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            확인할
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이동속도
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            방향을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            확인할
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기에서
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            사용되는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            언어
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            브라우져
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            확인할
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            기타
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치기반서비스사업자가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치정보법등
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            관련
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            법령의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            요건을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            충족하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            요청하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치정보를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제공하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            주는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
        </li>
      </ol>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "36.0pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치기반서비스제공자들이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        개발한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        응용프로그램을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정보로서
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공됩니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <ol start={2} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스사업자에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공함에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있어
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          원칙적으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          별도의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          비용을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          청구하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          않습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          6
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          위치정보의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          보호
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          내부
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관리지침에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          따라
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          접근제어
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          기술
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보안기술
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          안전하게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관리됩니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관련법령이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          바에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          따라서
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보호하기
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          조치
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          취합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          6
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조의
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          2 (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          개인위치정보주체의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          권리
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          www.replhada.com
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          기재된
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인정보처리방침에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정해진
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          바에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          따라
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수집
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          공유합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          설정
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          메뉴
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등에서
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          직접
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          설정을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          변경하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          방식
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          환경설정을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          변경하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          방식을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          통하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          언제든지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용제공에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전부
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          일부를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          철회할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          방법에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          따라
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자신의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인데이터를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          온라인으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보거나
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          편집할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있으며
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          당해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자료에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          오류가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정정을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          요구할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          6
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조의
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          3 (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          법정대리인의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          권리
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          14
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          미만의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회원에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대해서는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          3
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          당해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회원과
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          당해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회원의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법정대리인으로부터
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          받아야
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법정대리인은
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          9
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          조에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          의한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회원의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          권리를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          모두
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          가집니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          14
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          미만의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아동의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ·
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공사실
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          확인자료를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용약관에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          명시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          고지한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          범위를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          넘어
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용하거나
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          3
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공하고자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          14
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세미만의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아동과
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법정대리인의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          받아야
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아래의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제외합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <ol style={{ marginTop: "0cm" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치정보
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            및
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치기반서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제공에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            따른
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            요금정산을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치정보
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제공사실
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            확인자료가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            필요한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            통계작성
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            학술연구
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            또는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            시장조사를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            특정
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            알아볼
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            없는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            형태로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            가공하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제공하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
      </ol>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          7
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          약관
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          외
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          준칙
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        약관에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        명시되지
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        않은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        사항에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        대해서는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보법
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        전기통신사업법
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정보통신망
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용촉진
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정보보호
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        등에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        법률
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        등
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관계
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        법령과
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사가
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        별도로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        개인정보보호정책
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (Privacy Statement){" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        등의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        규정에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        따릅니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          8
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          회사의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          연락처
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        상호
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        주소
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        전화
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        번호
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        그
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        밖의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        연락처는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다음과
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        같습니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          상
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          호
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          :{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          디비아이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          주
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          소
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          :{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경기도
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          파주시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          금바위로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          <span lang="EN-US">100</span>
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전화번호
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          : 0507-1487-1179
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          부
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          칙
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        1
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        조
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        시행일
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ){" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        약관은
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        2021
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        년
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        11
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        월
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        01
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        일부터
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        시행합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        2
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        조
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보관리책임자는
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        2021
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        년
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        11
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        월
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        01
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        일을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기준으로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다음과
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        같이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        지정합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        [
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관리책임자
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ]
      </span>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          성
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          명
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          :{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          김기선
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          소
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          속
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          :{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          디비아이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보서비스
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          책임자
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          연락처
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          : 0507-1487-1179
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "9.4pt",
        marginLeft: "0cm",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "22.5pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보기반
      </span>
      <span
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "22.5pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스
      </span>
      <span
        style={{
          fontSize: "22.5pt",
          fontFamily: '"Tahoma",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "22.5pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용약관
      </span>
    </p>
    <div
      className="MsoNormal"
      align="center"
      style={{
        marginTop: "18.75pt",
        marginRight: "0cm",
        marginBottom: "18.75pt",
        marginLeft: "0cm",
        textAlign: "center",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        <hr size={0} width="100%" align="center" />
      </span>
    </div>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        마지막
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        업데이트
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        날짜
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        : 2021
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        년
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        11
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        월
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          1{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          목적
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        본
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        약관은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용자
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        본
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용약관에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        동의한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        자를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        말합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이하
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        “
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용자
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ”
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        라고
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        가
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        디비아이
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이하
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        “
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ”
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        라고
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치기반서비스와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관련된
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기능이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        내장된
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        운영체제
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        웹브라우저
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기타
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        프로그램
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이하
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        “
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        프로그램
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ”
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        사용되고
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있거나
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        앞으로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        설치될
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용자의
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        PC,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        타블렛
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        PC,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        정보처리기기
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        통신기기
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이동전화
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        등의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        각종
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기기
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이하
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        “
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ”
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관련하여
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공하는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치기반서비스
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이하
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        “
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ”
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        라고
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        )
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용함에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있어
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용자의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        권리
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ·
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        의무
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        책임사항을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        규정함을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        목적으로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          2{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          이용약관의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          효력
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          및
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          변경
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관은
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          신청한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보주체가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의하면
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          효력이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          발생합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의함으로써
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          내용을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          모두
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          읽고
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          충분히
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이해하였으며
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          적용에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          것으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          봅니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          셋업시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          해당
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          프로그램의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인정보처리방침에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의함으로써
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          일부를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이루고
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의하게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          됩니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보호
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법률
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          콘텐츠산업
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          진흥법
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전자상거래
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등에서의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          소비자보호에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법률
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          소비자기본법
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          규제에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법률
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관련법령을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위배하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          않는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          범위에서
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          (i)
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          현행약관과
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정약관
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          (ii){" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정약관의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          적용일자와
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          (iii){" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정사유를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          명시하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          현행약관과
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          함께
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          적용일자
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          10
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          일
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전부터
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          적용일
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이후
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          상당한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          기간
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동안
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전자적
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          형태
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          (
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전자우편
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          , SMS
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          )
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사실을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          발송함으로써
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          고지하거나
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관련
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제품
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인정보처리방침에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          웹페이지에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          게시합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          내용이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          불리한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          적용일자
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          30
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          일
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전부터
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          적용일
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이후
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          상당한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          기간
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동안
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이러한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사실을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전자적
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          형태
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          (
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전자우편
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          , SMS
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          )
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          발송함으로써
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          고지하거나
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관련
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제품
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인정보처리방침에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          웹페이지에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          게시합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전항의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정된
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          시행일
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          7
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          일
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          후까지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          거부의사를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          표시하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아니하면
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정된
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          승인한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          것으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          봅니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개정약관에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          않을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용계약을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          해지할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          3{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          관계법령의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          적용
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        본
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        약관은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        신의성실의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        원칙에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        따라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        공정하게
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        적용하며
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        본
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        약관에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        명시되지
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        아니한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        사항에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        대하여는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관계법령
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        또는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        상관례에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        따릅니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          4{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          서비스의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          내용
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사가
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공하는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스에는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        아래와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        같은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        사항이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        포함될
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있으며
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        종류에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        따라
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        그
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공되는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        종류
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        내용은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있습니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        특정
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        경우
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        모든
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        단말기에서
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제공되는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        것은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        아닐
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수도
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있습니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        또한
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ,{" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용자의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        편익
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        증진을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위하여
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        항목이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        추가
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ·
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        삭제되거나
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        구체적인
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        서비스
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        내용이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        변경될
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있습니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <table
      className="MsoNormalTable"
      border={1}
      cellSpacing={0}
      cellPadding={0}
      style={{ borderCollapse: "collapse" }}
    >
      <tbody>
        <tr>
          <td
            width={265}
            nowrap
            style={{ width: "198.75pt", padding: "0cm 0cm 0cm 0cm" }}
          >
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"굴림",sans-serif'
                  }}
                >
                  서비스
                </span>
              </b>
            </p>
          </td>
          <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"굴림",sans-serif'
                  }}
                >
                  서비스 내용
                </span>
              </b>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <span
                style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
              >
                구글 지도 및 지역정보 검색 서비스
              </span>
            </p>
          </td>
          <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <span
                style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
              >
                구글 지도 및 지역정보 검색 서비스 이용자가 단말기의 설정
                메뉴에서 지도 또는 지역정보 검색 기능을 위하여 단말기의 위치
                정보를 사용할 수 있도록 허용한 경우에
                <span lang="EN-US">, </span>이용자가 구글 메뉴를 활성화시킨 다음
                해당 검색창에서 검색 질의어를 입력하면
                <span lang="EN-US">, </span>검색 질의어와 함께 이용자의 단말기의
                대략적인 위치가 디바아이의 리플하다 서비스로 전송됩니다
                <span lang="EN-US">. </span>디비아이의 리플하다 서비스는 전송된
                검색 질의어 및 단말기의 위치를 바탕으로 데이터베이스 내에서 가장
                적합한 검색 및 지도결과를 산출한 다음
                <span lang="EN-US">, </span>이용자의 단말기로 지도 및 지역검색
                결과를 전송합니다<span lang="EN-US">.</span>
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <span
                style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
              >
                체크인
              </span>
            </p>
          </td>
          <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <span
                style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
              >
                단말기의 위치를 활용하여 이용자가 가까운 곳의 지형지물
                <span lang="EN-US">(</span>식당<span lang="EN-US">, </span>상점
                등<span lang="EN-US">)</span>을 선택하여 이용자가 이용하는
                <span lang="EN-US"> SNS </span>서비스에 지형지물의 위치 및
                명칭을 게시할 수 있도록 합니다<span lang="EN-US">. </span>
                이용자들은 별도의 선택 기능을 활성화함으로써 향후 검색 결과의
                향상을 위하여 체크인 내역이 리플하다 계정에 저장되도록 할 수도
                있습니다<span lang="EN-US">.</span>
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <span
                style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
              >
                광고서비스
              </span>
            </p>
          </td>
          <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <span
                style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
              >
                본 서비스를 활용하는 제<span lang="EN-US">3</span>자
                애플리케이션은 이용자의 동의를 획득한 다음
                <span lang="EN-US">, </span>이용자의 단말기 위치를 디비아이에
                전송하도록 함으로써<span lang="EN-US">, </span>디비아이가 이를
                바탕으로 애플리케이션 내에서 현재의 단말기 위치에 기반한 광고를
                제공할 수 있도록 합니다<span lang="EN-US">.</span>
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <span
                style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
              >
                지역기반 정보제공
              </span>
            </p>
          </td>
          <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
            <p
              className="MsoNormal"
              align="left"
              style={{
                marginBottom: "9.4pt",
                textAlign: "left",
                lineHeight: "normal",
                textAutospace: "ideograph-numeric ideograph-other",
                wordBreak: "keep-all"
              }}
            >
              <span
                style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}
              >
                이용자가 날씨<span lang="EN-US">, </span>뉴스
                <span lang="EN-US">, </span>금융정보 등을 검색한 경우
                <span lang="EN-US">, </span>단말기의 위치를 기반으로 해당 지역과
                관련된 정보를 제공하게 됩니다<span lang="EN-US">.</span>
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          5{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          서비스
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          이용요금
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          무료입니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정책에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          따라
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          유료
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전활
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          될
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          발생하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          각종
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          통신료는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          별도이며
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          가입한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          각
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          (
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이동
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          )
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          통신사의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정책에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          따릅니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          6{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          서비스이용의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제한
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          및
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          중지
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아래
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          각
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          호의
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          1
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          해당하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사유가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          발생한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제한하거나
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          중지시킬
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <ol style={{ marginTop: "0cm" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용자가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            회사
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            운영을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            고의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            또는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            중과실로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            방해하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스용
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            설비
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            점검
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            보수
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            또는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            공사로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            인하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            부득이한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            전기통신사업법에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            규정된
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            기간통신사업자가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            전기통신
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            중지했을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            국가비상사태
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            설비의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            장애
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            또는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            폭주
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등으로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            지장이
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            때
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            기타
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            중대한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            사유로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            인하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            회사가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제공을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            지속하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            것이
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            부적당하다고
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            인정하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
      </ol>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전항의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          규정에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          의하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제한하거나
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          중지한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          때에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관련제품
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인정보보호방침에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          웹페이지에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          게시하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          것을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          포함하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          여타의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          방법으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사유
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제한기간
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          알려야
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          7{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          개인위치정보의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          이용
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          또는
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제공
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공하고자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          미리
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용약관에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          명시한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          후
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보주체의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          얻어야
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법정대리인의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          권리와
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          행사방법과
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관련하여
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          인한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          분쟁의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          해결은
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          주소를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관할하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          지방법원의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전속관할로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          하며
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          주소가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          없는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          거소를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관할하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          지방법원을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관할법원으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          다만
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제소
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          당시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          주소
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          거소가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          분명하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          않거나
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          외국
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          거주자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관할은
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          민사소송법상에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          바에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          따릅니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          타사업자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          고객과의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          민원처리를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ·
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공사실
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          확인자료를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자동
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          기록
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ·
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보존하며
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          해당
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자료는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          6
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개월간
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보관합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          지정하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          3
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수집한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          당해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말기로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          매회
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공받는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공일시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공목적을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          즉시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          통보합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아래
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          각
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          호의
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          1
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          해당하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          휴대전화
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          미리
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          특정하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          지정한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          통신
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단말장치
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전자우편주소로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          통보합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <ol style={{ marginTop: "0cm" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인위치정보를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수집한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            당해
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            단말기가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            문자
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            음성
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            또는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            영상의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수신기능을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            갖추지
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            아니한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용자가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            온라인
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            게시
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            방법으로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            통보할
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            것을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            미리
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            요청한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
      </ol>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          다음과
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          같은
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          3
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <ol style={{ marginTop: "0cm" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용자가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            3
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            자
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제공에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            동의하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용자
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            지원하거나
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            시스템
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            및
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            보안을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            필요한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            업무를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수행하기
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            고용된
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            회사에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            대하여
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            법규를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            준수하거나
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            사법기관
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            또는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            기타
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            정부기관을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            포함한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            주무관청의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            적법한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            법적
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            절차에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            대응하기
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            필요한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            스팸이나
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용자의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            생명
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            신체
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            재산
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            대한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위해를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            방지하기
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            필요한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
      </ol>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          8{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          개인위치정보주체의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          권리
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          언제든지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          3
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전부
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          일부를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          철회할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수집한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ∙
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공사실
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          확인자료를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          파기합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          언제든지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수집
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          일시적인
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          중지를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          요구할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있으며
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          거절할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          없고
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          요청을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          기술적
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수단을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          갖추고
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아래
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          각
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          호의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자료에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          열람
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          고지를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          요구할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있고
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          당해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자료에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          오류가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정정을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          요구할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정당한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사유
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          없이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          요구를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          거절할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          없습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <ol style={{ marginTop: "0cm" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            본인에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            대한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인위치정보
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            수집
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ,{" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제공사실
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            확인자료
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            본인의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            개인위치정보가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위치정보의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            보호
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            및
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            등에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            관한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            법률
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            또는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            다른
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            법률
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            규정에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            의하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            3
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            자에게
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제공된
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이유
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            및
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            내용
          </span>
        </li>
      </ol>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          1
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          항
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          내지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          3
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          항의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          권리행사를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          소정의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          절차를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          통해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          요구할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          9{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          법정대리인의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          권리
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          14
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          미만의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대해서는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치기반서비스
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          3
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          당해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자와
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          당해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법정대리인으로부터
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          받아야
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법정대리인은
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          8
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          조에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          의한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          권리를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          모두
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          가집니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          14
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          미만의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ∙
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공사실
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          확인자료를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용약관에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          명시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          고지한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          범위를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          넘어
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용하거나
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          3
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공하고자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          14
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          미만의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자와
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          그
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법정대리인의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          받아야
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          단
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          통계작성
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          학술연구
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          시장조사를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          특정
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          알아볼
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          없는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          형태로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          가공하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제외합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          10{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (8
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          세
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          이하의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          아동
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          등의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          보호의무자의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          권리
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아래의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          해당하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          (
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이하
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          “8
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이하의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아동
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ”
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이라
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          )
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보호의무자가
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          8
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이하의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아동
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          생명
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          신체보호를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우에는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본인의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          것으로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          봅니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <ol style={{ marginTop: "0cm" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            8
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            세
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          ></span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이하의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            아동
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            피성년후견인
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            장애인복지법
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            2
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            조
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            2
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            항
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            2
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            호의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            규정에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            의한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            정신적
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            장애를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            가진
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            자로서
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            장애인고용촉진
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            및
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            직업재활법
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            2
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            조
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            2
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            호의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            규정에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            의한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            중증장애인에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            해당하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            자
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            (
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            장애인복지법
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            29
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            조의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            규정에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            의하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            장애인등록을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            자에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            한합니다
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            )
          </span>
        </li>
      </ol>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          8
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        ></span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이하의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아동
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          생명
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          신체의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보호를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          하고자
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보호의무자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서면동의서에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보호의무자임을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          증명하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서면을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          첨부하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제출하여야
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보호의무자는
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          8
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          세
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이하의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아동
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          또는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          동의하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보주체
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          권리의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전부를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          행사할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          11{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          위치정보관리책임자의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          지정
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          적절히
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관리
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ∙
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보호하고
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          개인위치정보주체의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          불만을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          원활히
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          처리할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있도록
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          실질적인
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          책임을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          질
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          지위에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보관리책임자로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          지정해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          운영합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보관리책임자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          구체적인
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사항은
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          부칙에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          따릅니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          12{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          손해배상
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보호
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          법률
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          중
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관련
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          규정을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위반한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          행위로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          손해가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          발생한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          손해배상
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          청구를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          고의
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          과실이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          없음을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          입증하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          못하는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          책임을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          면할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          없습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          규정을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위반하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          손해가
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          발생한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          손해배상을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          청구할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          있습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          13{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          면책
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          다음
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          각
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          호의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스를
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          제공할
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          수
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          없는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경우
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          인하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자에게
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          발생한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          손해에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대해서는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          책임을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          부담하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          않습니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <ol style={{ marginTop: "0cm" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            천재지변
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            또는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            준하는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            불가항력의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            상태가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제공을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            위하여
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            회사와
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제휴계약을
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            체결한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            3
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            자의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            고의적인
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            방해가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용자의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            귀책사유로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            서비스
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            이용에
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            장애가
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            있는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "black",
            marginBottom: "9.4pt",
            textAlign: "left",
            lineHeight: "normal",
            background: "white",
            textAutospace: "ideograph-numeric ideograph-other",
            wordBreak: "keep-all"
          }}
        >
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            1
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            호
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            내지
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            3
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            호를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            제외한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            기타
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            회사의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            고의
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            ∙
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            과실이
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            없는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            사유로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            인한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            또는
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            회사의
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            합리적인
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            통제범위를
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            벗어난
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            사유로
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            인한
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
          >
            {" "}
          </span>
          <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
            경우
          </span>
        </li>
      </ol>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          회사는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          서비스에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          게재된
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정보
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          자료
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사실의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          신뢰도
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          ,{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          정확성
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          등에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대해서는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          보증을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          않으며
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          인해
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          발생한
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이용자의
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          손해에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대하여는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          책임을
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          부담하지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          아니합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          14{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          규정의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          준용
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관은
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대한민국법령에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          의하여
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          규정되고
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          이행됩니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          본
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          약관에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          규정되지
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          않은
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          사항에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          대해서는
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          관련법령
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          및
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          상관습에
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          의합니다
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          .
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          15{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          분쟁의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조정
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          및
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          기타
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        또는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이용자는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보와
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관련된
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        분쟁에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        대해
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        당사자간
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        협의가
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이루어지지
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        아니하거나
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        협의를
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        할
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        없는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        경우에는
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        개인정보보호법
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        43
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        조의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        규정에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        의한
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        개인정보분쟁조정위원회에
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        조정을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        신청할
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        수
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        있습니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          제
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          16{" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          조
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
          (
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          회사의
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          연락처
        </span>
      </b>
      <b>
        <span
          lang="EN-US"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          )
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        회사의
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        상호
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        및
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        주소
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        등은
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다음과
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        같습니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          상
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          호
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          :{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          디비아이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          주
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          소
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          :{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          경기도
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          파주시
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          금바위로
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          <span lang="EN-US">100</span>
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          전화번호
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          : 0507-1487-1179
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          부
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Segoe UI",sans-serif',
            color: "black"
          }}
        >
          {" "}
        </span>
      </b>
      <b>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"굴림",sans-serif',
            color: "black"
          }}
        >
          칙
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        1
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        조
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        (
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        시행일
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ){" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        약관은
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        2021
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        년
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        11
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        월
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        01
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        일부터
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        시행합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        제
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        2
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        조
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보관리책임자는
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        2021
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        년
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        11
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        월
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
        01
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        일을
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        기준으로
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        다음과
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        같이
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        지정합니다
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      align="left"
      style={{
        marginBottom: "9.4pt",
        textAlign: "left",
        lineHeight: "normal",
        background: "white",
        textAutospace: "ideograph-numeric ideograph-other",
        wordBreak: "keep-all"
      }}
    >
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        [
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        위치정보
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        {" "}
      </span>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"굴림",sans-serif',
          color: "black"
        }}
      >
        관리책임자
      </span>
      <span
        lang="EN-US"
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Segoe UI",sans-serif',
          color: "black"
        }}
      >
        ]
      </span>
    </p>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          성
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          명
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          :{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          김기선
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          소
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          속
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          :{" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          디비아이
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          위치정보서비스
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
        </span>
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          책임자
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{
          color: "black",
          marginBottom: "9.4pt",
          textAlign: "left",
          lineHeight: "normal",
          background: "white",
          textAutospace: "ideograph-numeric ideograph-other",
          wordBreak: "keep-all"
        }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"굴림",sans-serif' }}>
          연락처
        </span>
        <span
          lang="EN-US"
          style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
        >
          {" "}
          : 0507-1487-1179
        </span>
      </li>
    </ol>
    <p className="MsoNormal">
      <span lang="EN-US">&nbsp;</span>
    </p>
  </div>
</>
    )
    }