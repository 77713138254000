import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import CheckBox from "../../../components/basic/BcheckBox";
import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { Typography, Container, Box, Link } from "@material-ui/core";
import {
  Header,
  Title,
  LastLink,
  SectionTitle,
} from "components/basic/Components";

import * as api from "../../../replhadaAPI";
import theme from "styles/theme";
export default function SignUp(props) {
  const [value, setValue] = React.useState("restaurant");
  const [rentInfo, setRentInfo] = React.useState(null);

  // const containerIds = props.location.state.containerIds
  // const phoneNumber = props.location.state.phoneNumber
  const location = useLocation();
  console.log("프롭스 로케이션", props.location.search);

  const StyledRating = withStyles({
    iconFilled: {
      color: "#ff6d75",
    },
    iconHover: {
      color: "#ff3d47",
    },
  })(Rating);

  React.useEffect(() => {
    console.log("checking", location);

    console.log("checking", props.location);
    setRentInfo({
      id: props.location.state.id,
      data: props.location.state.data,
    });
  }, []);

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon style={{ fontSize: "30px" }} />,
      label: "Very Dissatisfied",
    },
    2: {
      icon: <SentimentDissatisfiedIcon style={{ fontSize: "30px" }} />,
      label: "Dissatisfied",
    },
    3: {
      icon: <SentimentSatisfiedIcon style={{ fontSize: "30px" }} />,
      label: "Neutral",
    },
    4: {
      icon: <SentimentSatisfiedAltIcon style={{ fontSize: "30px" }} />,
      label: "Satisfied",
    },
    5: {
      icon: <SentimentVerySatisfiedIcon style={{ fontSize: "30px" }} />,
      label: "Very Satisfied",
    },
  };
  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [rate, setrate] = React.useState(3);
  const [comment, setcomment] = React.useState("");

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false,
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Page>
      <Box
        style={{
          position: "absolute",
          top: "-100px",
          left: "-100px",
          backgroundColor: theme.palette.primary.main,
          width: "200px",
          height: "200px",
          borderRadius: "100px",
          zIndex: 0,
        }}
      >
        <Title
          style={{
            position: "absolute",
            top: "116px",
            left: "116px",
            width: "100%",
            fontWeight: "700",
          }}
          title="수거요청 완료"
        />
        <Typography
          style={{
            position: "absolute",
            top: "148px",
            width: "170%",
            left: "116px",
            color: "gray",
          }}
        >
          친환경 다회용기를 사용해 주셔서 감사합니다
        </Typography>
      </Box>
      <Box style={{ width: "100px", height: "100px", color: "gray" }}></Box>

      <Container>
        <EmptySpace />
        <EmptySpace />
        <DesText>{"후기를 남겨 주시면 서비스 개선에 큰 힘이 됩니다"}</DesText>

        <EmptySpace />
        <EmptySpace />
        <EmptySpace />
        <div style={{ width: "100%", margin: "auto", textAlign: "center" }}>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <Typography component="legend" style={{ marginBottom: "24px" }}>
              만족도를 평가해 주세요
            </Typography>
            <Rating
              style={{ fontSize: "99px" }}
              name="customized-icons"
              defaultValue={3}
              value={rate}
              onChange={(e) => {
                console.log(e.target.value);
                setrate(e.target.value);
              }}
              getLabelText={(value) => customIcons[value].label}
              IconContainerComponent={IconContainer}
            />
          </Box>
        </div>
        <UpText>
          *후기를 입력해 주세요{" "}
          <span style={{ fontSize: "14px" }}>(선택사항)</span>{" "}
        </UpText>
        <OutLinedTextField
          rows={4}
          multiline
          value={comment}
          setValue={setcomment}
        />

        <EmptySpace />
        <EmptySpace />

        <EmptySpace />

        <ContainedButton
          onClick={async () => {
            console.log(rentInfo);

            let updatedRentInfo = rentInfo.data;
            updatedRentInfo.customerInfo["rating"] = rate;
            updatedRentInfo.customerInfo["comment"] = comment;
            updatedRentInfo["updatedBy"] = new Date();
            const result = await api.updateRent(rentInfo.id, updatedRentInfo);
            if (result.code !== 200) {
              alert(result.msg);
              return false;
            }

            alert("감사합니다");
            window.location.replace("https://www.dbi-replhada.com/partners");
          }}
        >
          완료
        </ContainedButton>
        <EmptySpace />
        <Box style={{ width: "100%" }} display="flex" justifyContent="center">
          <Link
            href="#"
            onClick={() => window.open("http://pf.kakao.com/_Bcfws", "_blank")}
          >
            카카오톡으로 문의
          </Link>
        </Box>
      </Container>
      <EmptySpace />
    </Page>
  );
}
