import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import CheckBox from "../../../components/basic/BcheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import {Container, Box,Paper,Button} from "@material-ui/core";


import qs from "qs";
import { createRent } from "../../../replhadaAPI";
import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const"
import QrReader from 'react-qr-reader'
import { useEffectOnce } from 'react-use';
import { useSnackbar } from "notistack";
import {Header,Title,LastLink,SectionTitle} from "components/basic/Components";
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import QrScanner from 'qr-scanner'; 
import {Delivery} from "Classes"
// import { useAuth } from "AuthContext";


var geocoder = require('google-geocoder');
const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  wrapper: {
    width: 100 + theme.spacing(2),
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
//   const auth = useAuth();


  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
  });
  console.log("프롭스 로케이션", props.location.search.toLowerCase())
  const containerId = props.location.search.toLowerCase().replace("?containerid=", "")
  const [rentInfo, setRentInfo] = React.useState(null)
  const [mainAddress, setMainAddress] = React.useState(
    ""
  );
  const [delivery, setDelivery] = React.useState(null);

  const [lat, setlat] = React.useState(0)
  const [lng, setlng] = React.useState(0)
  const [restAddress, setRestAddress] = React.useState("");
  const [addressFixing, setAddressFixing] = React.useState(false);

  const [doorPassword, setdoorPassword] = React.useState("");
  const [pricing, setPricing] = React.useState(null)
  const [containerIds, setContainerIds] = React.useState([])
  
  const [qrScanner, setQrScanner] = React.useState(null)
  const [containerLength, setContainerLength] = React.useState(0)
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState(null);


    useEffectOnce(async()=>{
     

      
    //   const apiData =  initData()
      setData("apiData")
    })
    const foundQR = (result)=>{
      
      // if (!containerIds.includes(result)){
      //   handleScan(result)
      // }
    }
    const initData = async()=> {
      const delivery = new Delivery(auth.user.email)
      setDelivery(delivery)
      const reserved = await delivery.getReserved()
      console.log("reserved",reserved)


      return {reserved}
    }
  React.useEffect(() => {
    (async () => {
      const qrScanner = new QrScanner(videoElem, result => {
        if(result){
          foundQR(result.data)
        }
        },{
          highlightScanRegion: true,
          highlightCodeOutline: true,
        });
        setQrScanner(qrScanner)
      qrScanner.setInversionMode("both")
      qrScanner.start()
       
      
     

      // console.log("셋 프라이스", result.data)
      // setPricing(result.data)

      // if (rentResult.data.length === 0) {
      //   setAddressFixing(true)
      //   return
      // }
      // setRentInfo(rentResult.data)
      // setMainAddress(rentResult.data[0].data.customerInfo.mainAddress)
      // setRestAddress(rentResult.data[0].data.customerInfo.restAddress)



    })()
  }, [])

  const handleScan = async (data) => {
    if (data === null) {
      return
    }
    const dataLowerCase = data.toLowerCase()
    const prefixLowerCase = constant.prefix.container.toLowerCase()
    if (dataLowerCase.includes(prefixLowerCase)) {
      let newContainerId = dataLowerCase.replace(prefixLowerCase, "")
      newContainerId = newContainerId.replace("containerid=", "")
      // alert(newContainerId)
      if (containerIds.includes(newContainerId)) {
        // alert("중복")
        return
      }
      const add = containerIds
       add.push(newContainerId)
      enqueueSnackbar("다회용기 추가 성공", { variant: "success" });

      setContainerIds(add)
      setContainerLength(add.length)

      // if (containerId === rent.data.containerId.toLowerCase()) {
      //TODO update status, updatedBy 배달부 객체에
      // await update(rentObj)
      // alert("회수에 성공했습니다")

      // props.history.push(`/retriving?rentId=${rent.id}`)
      //   return
      // } else {
      //   alert("회수할 다회용기가 아닙니다")
      //   return
      // }
    }
    return
    // if data.conta
    // setqrData(data)

  }

  const handleError = (err) => {
    console.error(err);
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  

  //TODO
  // 용기 계속 찍기 
  return (
    <Page>
      {/* <Header  leftButton={null}/> */}
        <Title title="모든 다회용기의 QR을 스캔"/>
        
        <SectionTitle title={`스캔된 다회용기: ${containerLength}개`}></SectionTitle>
        <Box sx={{width:"100%"}}>
        <Container>
        <Box display="flex" justifyContent="flex-end" alignItems="center">

        <Button 
        color={"primary"}
        
        variant="contained"
        onClick={() => {
          qrScanner.stop()
          props.history.push(
            {
              pathname: `/customerinfo`,
              state: { containerIds }
            })
        }}>완료</Button>
        </Box>
        <EmptySpace/>
        </Container>
        <Box style={{width:"100%",backgroundColor:"black"}} >
        <Box display="flex" flexDirection="row" justifyContent="center"  alignItems="center" style={{width:"65%", margin:"auto",}}>
      <video id="videoElem"
      style={{width:"100%" }}>
      </video>
      </Box>
      </Box>
      
      
      </Box>

      {/* TODO 용기 갯수 */}
      {/* 용기 갯수가 추가되었습니다 햅틱*/}
      

      <EmptySpace />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%"
        }}
      // onClick={() => {
      // }}
      >
        {/* <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          facingMode={"environment"}

        /> */}



      </div>
      <Box style={{width:"100%", position:"fixed", bottom:"10px",  }}>
        <Container>
      <ContainedButton>완료</ContainedButton>
      </Container>
      </Box>
    </Page >
  );
}
