import React from "react";

import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));
const Loader = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <CircularProgress />
        </Box>
    );
};

export default Loader;
