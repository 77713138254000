import React from "react";
import { useAuth } from "../../../AuthContext";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";
import FixableTextField from "../../../components/basic/FixableTextField";
import AddressForm from "../../../components/basic/AddressForm";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import PolicyBox from "../../../components/basic/PolicyBox";

import TextField from "@material-ui/core/TextField";

import FormGroup from "@material-ui/core/FormGroup";
import Card from "../../../components/basic/Bcard";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import * as constant from "../../../Const";
import { getFranchise } from "../../../replhadaAPI";
import * as api from "../../../replhadaAPI"
import { Box,Paper,Typography,Container,Button,Grid,Link,Chip } from '@material-ui/core';
import {Header,Title,LastLink, SectionTitle} from "components/basic/Components";

export default function SignUp(props) {
  const auth = useAuth();

  const [value, setValue] = React.useState("restaurant");
  const [businessTitle, setBusinessTitle] = React.useState("")
  const [mainAddress, setMainAddress] = React.useState(
    ""
  );
  const [restAddress, setRestAddress] = React.useState("");
  const [addressFixing, setAddressFixing] = React.useState(false);
  const [restitleFixable, setRestitleFixable] = React.useState(false);

  const [accountNumber, setAccountNumber] = React.useState(auth.userExtraInfo.accountNumber && auth.userExtraInfo.accountNumber);
  const [acNumberFixable, setacNumberFixable] = React.useState(false);

  const [accountHolder, setAccountHolder] = React.useState(auth.userExtraInfo.accountHolder && auth.userExtraInfo.accountHolder);
  const [acHolderFixable, setacHolderFixable] = React.useState(false);

  const [bank, setBank] = React.useState(auth.userExtraInfo.bank && auth.userExtraInfo.bank);
  const [bankFixable, setbankFixable] = React.useState(false);

  const [franchises, setFranchises] = React.useState(null);
  const [washReturns, setWashReturns] = React.useState(null);

  const [washPointFixable, setWashPointFixable] = React.useState([])
  const [washPointAddressFixable, setWashPointAddressFixable] = React.useState([])



  React.useEffect(() => {
    (async () => {
      //TODO 프렌차이즈 일때
      if (auth.userExtraInfo.role === constant.role.franchise) {
        //필요정보
        //음식점 정보들
        const restaurants = await Promise.all(auth.userExtraInfo.franchiseDocs.map((v) => {
          return api.getFranchise(v)
        }))
        console.log("restaurants", restaurants)
        setFranchises(restaurants)
      } else if (auth.userExtraInfo.role === constant.role.delivery) {
        //TODO 딜리버리 일때
        //필요정보 통장 정보


        //TODO 워시메이트 일때
        //필요정보 본사정보
        //회수 장소 정보
      } else if (auth.userExtraInfo.role === constant.role.washmate) {


        const washmates = await Promise.all(auth.userExtraInfo.washmateDocs.map((v) => {
          return api.getWashmate(v)
        }))
        console.log("washmates", washmates)
        setFranchises(washmates)
        // const returnPoints = await Promise.all(washmates.map((v) => {

        //   for (const returnPoint of v.returnPointDocs) {
        //     console.log(returnPoint)
        //     return api.getReturnPoint(returnPoint)
        //   }
        // }))
        // console.log("returnPoints", returnPoints)
        let returnPointArray = []
        for (const washmate of washmates) {
          console.log("washmate", washmate)
          const returnPoints = await Promise.all(washmate.data.returnPointDocs.map((v) => api.getReturnPoint(v)))
          returnPointArray.push(returnPoints)
        }
        console.log("returnPointArray", returnPointArray)

        setWashReturns(returnPointArray[0])
        setWashPointFixable(returnPointArray.map(() => false))
        setWashPointAddressFixable(returnPointArray.map(() => false))


        //TODO 어드민 일때
      } else if (auth.userExtraInfo.role === constant.role.admin) {
      }

    })()

  }, [])

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const role = (auth.userExtraInfo.role === constant.role.franchise ? "음식점" : (auth.userExtraInfo.role === constant.role.delivery ? "배달" : (auth.userExtraInfo.role === constant.role.washmate ? "세척" : (auth.userExtraInfo.role === constant.role.admin ? "운영자" : ""))))
  const franchiseView = (<><DesText>매장 정보</DesText>
    <EmptySpace />
    {franchises && franchises.map((v, i) => {
      return (<>
        <Card>

          <FixableTextField
            title="가맹점 이름"
            fixable={restitleFixable}
            onChange={(e) => {
              let mArray = [...franchises]
              mArray[i].data.storeName = e.target.value


              setFranchises(mArray)
            }}
            value={franchises[i].data.storeName}
            fixButtonTapped={async () => {
              if (restitleFixable) {
                const result = await api.updateFranchise(franchises[i].id, { storeName: franchises[i].data.storeName })
                if (result.code !== 200) {
                  alert(result.msg)
                  return
                }
                alert("업데이트 완료")
              }

              setRestitleFixable(!restitleFixable);

            }}
          />

          <AddressForm
            fixable={addressFixing}
            bfixButton={true}
            mainAddress={franchises[i].data.mainAddress}
            // setMainAddress={setMainAddress}
            setMainAddress={(v) => {
              let mArray = [...franchises]
              mArray[i].data.mainAddress = v
              setFranchises(mArray)
            }}
            restAddress={franchises[i].data.restAddress}
            setRestAddress={(v) => {
              let mArray = [...franchises]
              mArray[i].data.restAddress = v
              setFranchises(mArray)
            }}
            fixButtonTapped={async (a) => {
              if (addressFixing) {
                const result = await api.updateFranchise(franchises[i].id, { mainAddress: franchises[i].data.mainAddress, restAddress: franchises[i].data.mainAddress })
                if (result.code !== 200) {
                  alert(result.msg)
                  return
                }
                alert("업데이트 완료")
              }
              setAddressFixing(!addressFixing);

            }}
          />
        </Card>
      </>)
    })
    }
  </>)



  let washmateView = (<><DesText>매장 정보</DesText>
    <EmptySpace />
    {franchises && franchises.map((v, i) => {
      return (<>
        <Card>

          <FixableTextField
            title="본사 이름"
            fixable={restitleFixable}
            onChange={(e) => {
              let mArray = [...franchises]
              mArray[i].data.storeName = e.target.value


              setFranchises(mArray)
            }}
            value={franchises[i].data.storeName}
            fixButtonTapped={async () => {
              if (restitleFixable) {
                const result = await api.updateWashmate(franchises[i].id, { storeName: franchises[i].data.storeName })
                if (result.code !== 200) {
                  alert(result.msg)
                  return
                }
                alert("업데이트 완료")
              }

              setRestitleFixable(!restitleFixable);

            }}
          />

          <AddressForm
            fixable={addressFixing}
            bfixButton={true}
            mainAddress={franchises[i].data.mainAddress}
            // setMainAddress={setMainAddress}
            setMainAddress={(v) => {
              let mArray = [...franchises]
              mArray[i].data.mainAddress = v
              setFranchises(mArray)
            }}
            restAddress={franchises[i].data.restAddress}
            setRestAddress={(v) => {
              let mArray = [...franchises]
              mArray[i].data.restAddress = v
              setFranchises(mArray)
            }}
            fixButtonTapped={async (a) => {
              if (addressFixing) {
                const result = await api.updateWashmate(franchises[i].id, { mainAddress: franchises[i].data.mainAddress, restAddress: franchises[i].data.mainAddress })
                if (result.code !== 200) {
                  alert(result.msg)
                  return
                }
                alert("업데이트 완료")
              }
              setAddressFixing(!addressFixing);

            }}
          />
        </Card>
      </>)
    })
    }
    <EmptySpace />
    <EmptySpace />

    <><DesText>반납 장소</DesText>
      <EmptySpace />

      {washReturns && washReturns.map((v, i) => {

        return (<>
          <Card>

            <FixableTextField
              title="회수 장소"
              fixable={washPointFixable[i]}


              onChange={(e) => {
                let mArray = [...washReturns]
                mArray[i].data.storeName = e.target.value
                setWashReturns(mArray)
              }}
              value={v.data.storeName}
              fixButtonTapped={async () => {

                let mArray = [...washPointFixable]
                mArray[i] = !washPointFixable[i]
                console.log(washPointFixable)


                if (washPointFixable[i]) {
                  const result = await api.updateReturnPoints(washReturns[i].id, { storeName: washReturns[i].data.storeName })
                  if (result.code !== 200) {
                    alert(result.msg)
                    return
                  }
                  alert("업데이트 완료")
                }
                // setAddressFixing(!addressFixing);


                setWashPointFixable(mArray);
              }}
            />

            <AddressForm
              fixable={washPointAddressFixable[i]}
              bfixButton={true}
              mainAddress={washReturns[i].data.mainAddress}
              // setMainAddress={setMainAddress}
              setMainAddress={(v) => {
                let mArray = [...washReturns]
                mArray[i].data.mainAddress = v
                setWashReturns(mArray)
              }}
              restAddress={washReturns[i].data.restAddress}
              setRestAddress={(v) => {
                let mArray = [...washReturns]
                mArray[i].data.restAddress = v
                setWashReturns(mArray)
              }}
              fixButtonTapped={async (a) => {
                let mArray = [...washPointAddressFixable]
                mArray[i] = !washPointAddressFixable[i]

                if (washPointAddressFixable[i]) {
                  const result = await api.updateReturnPoints(washReturns[i].id, { mainAddress: washReturns[i].data.mainAddress, restAddress: washReturns[i].data.restAddress })
                  if (result.code !== 200) {
                    alert(result.msg)
                    return
                  }
                  alert("업데이트 완료")
                }


                setWashPointAddressFixable(mArray);
              }}
            />
          </Card>
          <EmptySpace />

        </>)
      })}
    </>

  </>)
  const deliveryView = (<><DesText>수익 정산정보</DesText>
    <EmptySpace />
    <FixableTextField
      title="입금 통장번호"
      fixable={acNumberFixable}
      setValue={setAccountNumber}
      value={accountNumber}
      fixButtonTapped={async () => {
        if (acNumberFixable) {
          const result = await api.updateUser(auth.user.email, { accountNumber })
          if (result.code !== 200) {
            alert(result.msg)
            return
          }
          alert("업데이트 완료")
        }
        setacNumberFixable(!acNumberFixable);
      }}
    />
    <FixableTextField
      title=" 계좌주"
      fixable={acHolderFixable}
      setValue={setAccountHolder}
      value={accountHolder}
      fixButtonTapped={async () => {

        if (acHolderFixable) {
          const result = await api.updateUser(auth.user.email, { accountHolder })
          if (result.code !== 200) {
            alert(result.msg)
            return
          }
          alert("업데이트 완료")
        }

        setacHolderFixable(!acHolderFixable);
      }}
    />
    <FixableTextField
      title="은행"
      fixable={bankFixable}
      setValue={setBank}
      value={bank}
      fixButtonTapped={async () => {
        if (bankFixable) {
          const result = await api.updateUser(auth.user.email, { bank })
          if (result.code !== 200) {
            alert(result.msg)
            return
          }
          alert("업데이트 완료")
        }

        setbankFixable(!bankFixable);
      }}
    /></>)
  const adminView = (<></>)
  return (
    <Page>
      <AppBar backLink="/main" menu={true} />
      <EmptySpace />
      <SubTitle>내정보</SubTitle>
      <EmptySpace />
      <Container>
      <UpText>이메일</UpText>
      <OutLinedTextField value={auth.user.email} disabled />
      <Box display="flex" justifyContent="flex-end" style={{marginTop:"4px",width:"100%"}}>
      <Link href="/forgotpassword" justifycontent="flex-end">
        비밀번호 변경하기
      </Link>
      </Box>
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      <UpText>파트너스 종류</UpText>
      <OutLinedTextField value={role} disabled />
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      {auth.userExtraInfo.role === constant.role.franchise && franchiseView}
      {auth.userExtraInfo.role === constant.role.washmate && washmateView}

      {/* washmate, franchise */}
      <EmptySpace />
      <EmptySpace />
      {/* deleiveryView */}
      {auth.userExtraInfo.role === constant.role.delivery && deliveryView}

      <EmptySpace />

      <ContainedButton onClick={
        async () => {
          if (window.confirm("회원 탈퇴 하시겠습니까")) {

            await api.deleteUser(auth.user.email)
            await auth.user.delete()
            // User deleted.

            alert("삭제가 완료되었습니다")


            window.location.href = "/login";
            return
          }

        }
      }
      >회원탈퇴</ContainedButton>
      <ContainedButton
      style={{marginTop:"8px"}}
        onClick={
          async () => {
            auth.signOut()
            window.location.href = "/login";

            return
          }
        }

      >로그아웃</ContainedButton>
      <EmptySpace />
      </Container>
    </Page>
  );
}
