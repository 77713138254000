import React from "react";
import { css } from "../../Const";
import { makeStyles } from "@material-ui/core/styles";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';


const marginSide = css.marginSide;
const bothMarginside = marginSide * 2;
const marginTop = css.marginTop;
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: `${marginTop}px ${marginSide}px`,
        width: `calc(100% - ${marginSide * 2}px)`
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function TextArea(props) {
    const classes = useStyles();
    const handleSelectChange = (event) => {

        props.setValue(event.target.value);
    };
    return (
        <div style={{ width: "100%" }}>
            <TextareaAutosize aria-label="minimum height" minRows={3}
                placeholder="Minimum 3 rows" style={{ margin: "0px 24px", width: "calc(100% - 48px)", height: "80px", boxSizing: 'border-box' }} />
        </div>
    );
}


// const [bank, setBank] = React.useState("");
{/* <BankSelect setValue={setBank} value={bank} /> */ }
