// message
export const messageCategory = Object.freeze({ info: "INFO" });

export const role = Object.freeze({
  franchise: "FRANCHISE",
  delivery: "DELIVERY",
  washmate: "WASHMATE",
  admin: "ADMIN",
});
export const prefix = Object.freeze({
  container: "https://partners.replhada.com/customermain?",
  returnPoint: "https://partners.replhada.com/returnpoint?",
});
export const rentStatus = Object.freeze({
  sendToUser: "SENDTOUSER",
  waitingDelivery: "WAITINGDELIVERY",
  deliveryReserved: "DELIVERYRESERVED",
  delivieryPicikup: "DELIIVERYPICKUP",
  drifted: "DRIFTED",
  delivered: "DELIVERED",
  completed: "COMPLETED",
});
export const containerStatus = Object.freeze({
  sendToUser: "SENDTOUSER",
  waitingDelivery: "WAITINGDELIVERY",
  deliveryReserved: "DELIVERYRESERVED",
  delivieryPicikup: "DELIIVERYPICKUP",
  delivered: "DELIVERED",
  completed: "COMPLETED",
  drifted: "DRIFTED",
  missing: "MISSING",
  broken: "BROKEN",
  disposed: "DISPOSED",
  normal: "NORMAL",
});
export const requestStatus = Object.freeze({
  request: "REQUESTED",
  reserved: "RESERVED",
  delivered: "DELIVERED",
});
export const pricingId = Object.freeze("TSKDyhF531KWGPXmticu");
export const washmateId = Object.freeze("YMS1dwHvSOjUVP5W1GO6");
export const washmateOwner = Object.freeze("i670@naver.com");
export const dbCollection = Object.freeze({
  station: "Stations",
  franchise: "Franchises",
  buyerApplication: "BuyerApplications",
  salesApplication: "SalesApplications",
  storeApplication: "StoreApplications",
  rent: "Rents",
  order: "Orders",
  request: "Requests",
  washmate: "Washmates",
  returnPoint: "ReturnPoints",
  user: "Users",
});

export const applicationStatus = Object.freeze({
  approve: "APPROVE",
  waiting: "WAITING",
  rejected: "REJECTED",
});

export const buyerStatus = Object.freeze({
  noBuyer: "noBuyer",
  otherBuyer: "otherBuyer",
  ownBuyer: "ownBuyer",
});
export const salesMethod = Object.freeze({
  banto: "banto",
  ownSales: "ownSales",
  yet: "yet",
});
export const urls = Object.freeze({
  domain: "https://partners.mulli.world",
  api: "https://api.replhada.com/",
  // api: "http://replhada.com:3999/"
  // api: "http://localhost:3999/",
});

export const exampleUserId = Object.freeze("a1111");

export const css = Object.freeze({
  marginSide: 24,
  marginTop: 10,
  appBarHeight: 66,
});
