import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";
import theme from "styles/theme";
import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import PolicyBox from "../../../components/basic/PolicyBox";
import CheckBox from "../../../components/basic/BcheckBox";
import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import AddressForm from "../../../components/basic/AddressForm";
// import Link from "../../../components/basic/Link";
import qs from "qs";
import { createRent } from "../../../replhadaAPI";
import * as api from "../../../replhadaAPI";
import * as constant from "../../../Const";
import { useHistory } from "react-router-dom";
import { CustomerController } from "Controllers";
var geocoder = require("google-geocoder");
import {
  Box,
  Paper,
  Typography,
  Container,
  Button,
  Grid,
  Link,
  Chip,
  Card,
} from "@material-ui/core";
import {
  Header,
  Title,
  LastLink,
  SectionTitle,
} from "components/basic/Components";
import { Container as Containers, Order } from "Classes";

export default function SignUp(props) {
  let history = useHistory();
  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true, // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
  });
  // containerIds
  const containerIds = props.location.state?.containerIds ?? [];
  const numOfContainers = props.location.state?.numOfContainers ?? 0;
  console.log("프롭스 로케이션2", numOfContainers);

  const [rentInfo, setRentInfo] = React.useState(null);
  const [mainAddress, setMainAddress] = React.useState("");

  const [lat, setlat] = React.useState(0);
  const [lng, setlng] = React.useState(0);
  const [restAddress, setRestAddress] = React.useState("");
  const [addressFixing, setAddressFixing] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const [doorPassword, setdoorPassword] = React.useState("");
  const [pricing, setPricing] = React.useState(null);
  const [address, setAddress] = React.useState("");
  const [order, setOrder] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      const result = await api.getPricing(constant.pricingId);
      if (result.code !== 200) {
        return;
      }
      setPricing(result.data);
    })();
  }, []);

  const [state, setState] = React.useState({
    checkedA: true,
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Page>
      {/*헤더  */}
      <Box
        style={{
          position: "absolute",
          top: "-100px",
          left: "-100px",
          backgroundColor: theme.palette.primary.main,
          width: "200px",
          height: "200px",
          borderRadius: "100px",
          zIndex: 0,
        }}
      >
        <Title
          style={{
            position: "absolute",
            top: "116px",
            left: "116px",
            width: "100%",
            fontWeight: "700",
          }}
          title="안녕하세요"
        />
        <Typography
          style={{
            position: "absolute",
            top: "148px",
            width: "120%",
            left: "116px",
            color: "gray",
          }}
        >
          환경을 생각하는 리플하다 입니다
        </Typography>
      </Box>
      <Container>
        <Box style={{ width: "100px", height: "100px", color: "gray" }}></Box>
        <EmptySpace />
        <EmptySpace />
        <Title title="수거정보 입력"> </Title>
        <Paper elevation={3}>
          <Container>
            <>
              <EmptySpace />
              <UpText>전화번호를 입력해 주세요 </UpText>
              <OutLinedTextField
                margin="dense"
                value={phoneNumber}
                setValue={setPhoneNumber}
              />
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: "4px", marginBottom: "8px" }}
                  fullWidth={false}
                  onClick={async () => {
                    const cc = new CustomerController();
                    await cc.init({ phoneNumber });
                    try {
                      const orders = await cc.getMyOrder();
                      console.log("오더스", orders.data.length);
                      if (orders.data.length === 0 || orders.data.length > 1) {
                        history.push({
                          pathname: "/choosemyorder",
                          state: { phoneNumber, containerIds, numOfContainers },
                        });
                        // TODO
                        // 오더 없을시
                        return;
                      }

                      const address = orders.data[0].data.orderer.addrNew;
                      console.log(orders.data[0].data.orderer.addrNew);
                      setOrder(orders.data[0]);
                      setAddress(address);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                >
                  확인
                </Button>
              </Box>
            </>
          </Container>
        </Paper>
        <EmptySpace />

        {/* 전번까지 */}
        {address && (
          <React.Fragment>
            <Title title="주소 확인"> </Title>

            <Paper elevation={3}>
              <Container>
                <EmptySpace />
                <UpText>아래 주소정보가 정확한가요? </UpText>
                <UpText>
                  주소 정보가 다르거나 안보인다면 다시 입력해 주세요{" "}
                </UpText>

                <OutLinedTextField
                  multiline
                  value={address && address}
                  setValue={setAddress}
                />

                <div style={{ width: "100%" }}>
                  <CheckBox
                    label="아파트 출입문 비밀번호 필요(선택사항)"
                    checked={state.checkedA}
                    onChange={handleCheckBoxChange}
                    name="checkedA"
                  />
                </div>

                {state.checkedA && (
                  <>
                    <EmptySpace />
                    <UpText>
                      아파트 출입문 비밀번호를 입력해주세요(예#1234){" "}
                    </UpText>

                    <OutLinedTextField
                      margin="dense"
                      value={doorPassword}
                      setValue={setdoorPassword}
                    />
                    <UpText>
                      비밀번호는 수거 후 자동 삭제되오니 안심하셔요{" "}
                    </UpText>
                  </>
                )}
                <EmptySpace />
              </Container>
            </Paper>
          </React.Fragment>
        )}
        <EmptySpace />
        <Typography variant="subtitle1">
          수거요청 후, 사용하신 용기를 문 앞에 놓아 주시면 수거가 됩니다.
        </Typography>
        <EmptySpace />
        <ContainedButton
          onClick={async () => {
            let latitude = 0;
            let longitude = 0;
            if (address === "") {
              alert("휴대폰 번호와 주소를 확인해주세요");
              return;
            }
            if (state.checkedA && doorPassword === "") {
              alert("출입문 비밀번호를 적어주세요");
              return;
            }

            var geo = geocoder({
              key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
            });

            function coor(main, rest) {
              return new Promise((resolve, reject) => {
                geo.find(main + " " + rest, (err, res) => {
                  if (err) {
                    console.log(err);
                    console.log(res);
                    reject(err);
                    return;
                  }
                  resolve(res);
                });
              });
            }
            async function getCoordinate() {
              return await coor(address);
            }
            const addrssCoor = await getCoordinate();
            longitude =
              addrssCoor[0]?.location.lng ??
              order?.data?.data?.orderer?.longitude ??
              "37.5383499";
            latitude =
              addrssCoor[0]?.location.lat ??
              order?.data?.data?.orderer?.latitude ??
              "126.6559696";

            console.log(order?.data?.data?.orderer?.latitude ?? "오더없음");

            if (rentInfo === null) {
              const rentInfo = {
                status: constant.rentStatus.waitingDelivery,
                createdBy: new Date(),
                numOfContainers,
                pricing,
                order,
                customerInfo: {
                  mainAddress: address,
                  restAddress: "",
                  lat: latitude,
                  lng: longitude,
                  bDoorLock: state.checkedA,
                  doorlockPassword: { value: doorPassword },
                  rating: 0,
                  comment: "",
                  addedBy: new Date(),
                },
                franchiseInfo: {
                  owner: "",
                  doc: "",
                },
              };
              const result = await api.createRent(rentInfo);
              if (result.code !== 200) {
                alert(result.msg);
                return false;
              }
              console.log("id", result);
              // for await (const conId of containerIds) {
              //   const container = new Containers(conId);
              //   const conObj = {
              //     status: constant.containerStatus.waitingDelivery,
              //     updatedBy: new Date(),
              //   };
              //   container.updateContainer(conObj);
              // }

              const orderController = new Order(order.id);
              await orderController.delete();
              console.log("id", result.id);
              props.history.push({
                pathname: "/rating",
                state: {
                  id: result.id,
                  data: result.data,
                  containerIds,
                  numOfContainers,
                },
              });
            }

            return false;
          }}
        >
          수거요청
        </ContainedButton>
        <EmptySpace />
        <Box style={{ width: "100%" }} display="flex" justifyContent="center">
          <Link
            href="#"
            onClick={() => window.open("http://pf.kakao.com/_Bcfws", "_blank")}
          >
            카카오톡으로 문의
          </Link>
        </Box>
      </Container>
      <EmptySpace />
    </Page>
  );
}
