import React from "react";
import Slide from "@material-ui/core/Slide";
import { css } from "../../Const";

export default function Page(props) {
  return (
    <Slide
      direction="left"
      in={true}
      timeout={{ enter: "0.2s", exit: "0.2s" }}
      mountOnEnter
      unmountOnExit
    >
      <div style={{ backgroundColor: "white", height: "100vh" }}>
        {props.children}
      </div>
    </Slide>
  );
}
