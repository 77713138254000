import React from "react";
import {TextField, Box,Container,withStyles} from "@material-ui/core";
import { css } from "../../Const";
import  { useState, useRef, useCallback } from "react";

export default function OutLinedTextFeild(props) {
  const marginSide = css.marginSide;
  const bothMarginside = marginSide * 2;
  const marginTop = css.marginTop;
  

  const TextInput = useCallback((props) => {
    return <TextField {...props}/>;
  }, []);

  const CustomTextField = withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: `8px`,
        },
      },
    },
  })(TextInput);
  return (
    <Box >
      <TextInput
      
        variant="outlined"
        fullWidth
        onChange={(e) => {
          props.setValue(e.target.value)
        }}
        {...props}
      ></TextInput>
      
    </Box>
  );
}
