import React from "react";
import { makeStyles } from "@material-ui/styles";

import EmptySpace from "./EmptySpace";
import Link from "./Link";
import OutLinedTextField from "./OutLinedTextField";
import UpText from "./UpText";
import DaumPostcode from "react-daum-postcode";
import Button from "@material-ui/core/Button";
import { Box, TextField, Container } from "@material-ui/core";

import { css } from "../../Const";
const useStyles = makeStyles((theme) => ({
  addressContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "55px 0 0 25px",
  },
  contactPersonTitle: {
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "8px",
  },
  firstAddressWithButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  contactPersonTextField: { marginTop: "10px", width: "calc(100% - 25px)" },

  addressTextField: {
    width: "calc(100% - 48px)",
    marginTop: "10px",
    marginBottom: "8px",
  },
}));
const SecondTextField = (props) => {
  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        <Container>
          <TextField variant="outlined" fullWidth {...props}></TextField>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default function AddressForm(props) {
  const classes = useStyles(props);

  const marginSide = css.marginSide;
  const bothMarginside = marginSide * 2;
  const marginTop = css.marginTop;
  const fixable = props.fixable;
  const bfixButton = props.bfixButton;

  const handleOpen = () => {
    console.log("터치");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);
  const [mainAddress, setMainAddress] = React.useState("");
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    props.setMainAddress(fullAddress);
    setOpen(false);
    // console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
  };
  const body = (
    <Box
      style={{
        width: "100%",
        backgroundColor: "grey",
        height: "100%",
        marginTop: "-22%",
        paddingTop: "40px",
        paddingBottom: "85px",
      }}
    >
      <DaumPostcode
        style={{
          margin: "auto",
          width: "90%",
        }}
        onComplete={handleComplete}
        {...props}
        // style={{ width: "100%", height: "100%" }}
      />
      <Button
        style={{ position: "absolute", marginTop: "15px", right: "5%" }}
        variant="contained"
        onClick={handleClose}
      >
        취소
      </Button>
    </Box>
  );

  return (
    <>
      {open && body}
      <UpText>주소</UpText>
      <OutLinedTextField value={props.mainAddress} onClick={handleOpen} />
      <EmptySpace />
      <UpText>상세주소</UpText>
      <OutLinedTextField
        value={props.restAddress}
        // setValue={props.setRestAddress}
        onChange={(e) => {
          console.log(e.target.value);
          props.setRestAddress(e.target.value);
        }}
      />
    </>
  );
}

// const [mainAddress, setMainAddress] = React.useState(
//   "서울 강서구 마곡서로 33 (마곡동, 서울공진초등학교)"
// );
// const [restAddress, setRestAddress] = React.useState("공진 급식소 지하1층");
// const [addressFixing, setAddressFixing] = React.useState(true);

{
  /* <AddressForm
fixable={addressFixing}
bfixButton={true}
mainAddress={mainAddress}
setMainAddress={setMainAddress}
restAddress={restAddress}
setRestAddress={setRestAddress}
fixButtonTapped={(a) => {
  if (addressFixing) {
    setAddressFixing(false);
  } else {
    setAddressFixing(true);
  }
  window.alert(mainAddress, restAddress);
}}
/> */
}
