import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import CheckBox from "../../../components/basic/BcheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import {Container, Box,Paper,Button} from "@material-ui/core";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import AddressForm from "../../../components/basic/AddressForm";
import Link from "../../../components/basic/Link";
import qs from "qs";
import { createRent } from "../../../replhadaAPI";
import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const"
import QrReader from 'react-qr-reader'
import { useEffectOnce } from 'react-use';
import { useSnackbar } from "notistack";
import {Header,Title,LastLink,SectionTitle} from "components/basic/Components";
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import QrScanner from 'qr-scanner'; 
import {Delivery,Container as Containers} from "Classes"
import { useAuth } from "AuthContext";


var geocoder = require('google-geocoder');
const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  wrapper: {
    width: 100 + theme.spacing(2),
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const auth = useAuth();


  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
  });
  const containerId = props.location.search.toLowerCase().replace("?containerid=", "")
  const [rentInfo, setRentInfo] = React.useState(null)
  const [mainAddress, setMainAddress] = React.useState(
    ""
  );
  const [delivery, setDelivery] = React.useState(null);

  const [lat, setlat] = React.useState(0)
  const [lng, setlng] = React.useState(0)
  const [restAddress, setRestAddress] = React.useState("");
  const [addressFixing, setAddressFixing] = React.useState(false);

  const [doorPassword, setdoorPassword] = React.useState("");
  const [pricing, setPricing] = React.useState(null)
  const [containerIds, setContainerIds] = React.useState([])
  
  const [qrScanner, setQrScanner] = React.useState(null)
  const [containerLength, setContainerLength] = React.useState(0)
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState(null);
    let isProcessing = false

  useEffectOnce(async()=>{
    const apiData = await initData()
    setData(apiData)   
    isProcessing = false
    
  })
  const initData = async()=> {
    const delivery = new Delivery(auth.user.email)
    setDelivery(delivery)
    let reserved = await delivery.getReserved()
    reserved = reserved.data
    const coordinate = props.location.state.coordinate
    const returnPoints = await delivery.sortReturnPoints(coordinate)

    return {reserved,coordinate,returnPoints}
  }
  React.useEffect(() => {
    (async () => {
      if (data !== null){
        const qrScanner = new QrScanner(videoElem, async(result) => {
          if(result && !isProcessing){
            await foundQR(result.data)
          }
          },{
            highlightScanRegion: true,
            highlightCodeOutline: true,
          });
          setQrScanner(qrScanner)
        qrScanner.setInversionMode("both")
        qrScanner.start()
        }
    })()
  },[data])
  const foundQR = async (result)=>{
    isProcessing = true
    const qrResult = delivery.getRentByQRScan(result,data.reserved)
    console.log("qrResult",qrResult)
    if (!qrResult){
      alert("예약된 수거에서 다회용기를 찾지 못했습니다")
      return
    }

    const now = new Date()
    let copyRent = qrResult.data
    console.log(copyRent)
    copyRent.delivery.pickedupAt = now
    copyRent.status = constant.rentStatus.delivieryPicikup
    copyRent.updatedBy = now
    copyRent.washmate = {addedBy:now, washmate:data.returnPoints[0].data.washmateDoc,owner:data.returnPoints[0].data.owner}
    copyRent.returnPoint = {addedBy:now, ...data.returnPoints[0].data}
    const pickupResult = await delivery.pickUp(qrResult.id,copyRent)
    if (!pickupResult){
      alert("픽업 실패: 서버오류 403")
      return
    }
    await api.washmatePush(data.returnPoints[0].data.owner)
    const containerUpdate=copyRent.containerIds.map(v=>{
      console.log(v)
      const container = new Containers(v)
      const conStatus = {updatedBy:now,status:constant.containerStatus.delivieryPicikup}
      return container.updateContainer(conStatus)

    })
    Promise.all(containerUpdate)
    alert("픽업 성공 : 지도의 '픽업한 수거'에서 확인해주세요")
    isProcessing = false
    return
  }



  const handleError = (err) => {
    console.error(err);
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  

  //TODO
  // 용기 계속 찍기 
  return (
    <Page>
        <Header title="회수 스캔"/>
        <Title title="다회용기의 QR을 스캔해주세요"/>
        
        <Box sx={{width:"100%"}}>
        <Container>
      
        <EmptySpace/>
        </Container>
        <Box style={{width:"100%",backgroundColor:"black"}} >
        <Box display="flex" flexDirection="row" justifyContent="center"  alignItems="center" style={{width:"65%", margin:"auto",}}>
      <video id="videoElem"
      style={{width:"100%" }}>
      </video>
      </Box>
      </Box>
       
      </Box>

      {/* TODO 용기 갯수 */}
      {/* 용기 갯수가 추가되었습니다 햅틱*/}
      

      <EmptySpace />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%"
        }}
      // onClick={() => {
      // }}
      >
        {/* <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          facingMode={"environment"}

        /> */}



      </div>
    
    </Page >
  );
}
