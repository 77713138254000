import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../AuthContext";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";
import AddressForm from "../../../components/basic/AddressForm";
import {
  Container,
  FormLabel,
  Box,
  Paper,
  Chip,
  Typography,
} from "@material-ui/core";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import Link from "../../../components/basic/Link";
import TimePicker from "../../../components/basic/TimePicker";
import DatePicker from "../../../components/basic/DatePicker";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import useInput from "hooks/useInput";
import firebase from "firebase/app";

import TextField from "@material-ui/core/TextField";
import * as api from "../../../replhadaAPI";
import * as constant from "../../../Const";
import { Restaurant } from "Classes";
import MenuItem from "@material-ui/core/MenuItem";

let db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function RequestContainer(props) {
  const auth = useAuth();
  const [stores, setStores] = React.useState(null);
  const seoguA = useInput(0);
  const seoguB = useInput(0);
  const banchan = useInput(0);

  const 원형소 = useInput(0);
  const 직사각소 = useInput(0);
  const 직사각중 = useInput(0);
  const 직사각대 = useInput(0);
  const 납작사각대 = useInput(0);
  const 원형특대 = useInput(0);

  const [returnPoints, setReturnPoints] = React.useState(null);
  const [selectReturnPoint, setSelectReturnPoint] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      const restaurant = new Restaurant(auth.user.email);

      const returnPointsData = await restaurant.sortReturnPoints(
        props.location.state.coordinate
      );
      console.log("returnPointsData", returnPointsData);

      setReturnPoints(returnPointsData);
      setSelectReturnPoint(returnPointsData[0]);
      const result = await api.getStores(
        auth.user.email,
        auth.userExtraInfo.franchiseDocs,
        "franchiseDocs"
      );
      if (result.code !== 200) {
        alert(result.msg);
        return;
      }
      setStores(result.data);
      setRestaurant(result.data[0].data.storeName);
      setMainAddress(result.data[0].data.mainAddress);
      setRestAddress(result.data[0].data.restAddress);
    })();
  }, []);
  const classes = useStyles();
  // const rstaurant = auth.userExtraInfo.businessInfo.storeName
  // const maddress = auth.userExtraInfo.businessInfo.mainAddress
  // const raddress = auth.userExtraInfo.businessInfo.restAddress

  const [restaurant, setRestaurant] = React.useState(null);
  const [mainAddress, setMainAddress] = React.useState(null);
  const [restAddress, setRestAddress] = React.useState(null);
  const [addressFixing, setAddressFixing] = React.useState(false);
  const [quantity, setQuantity] = React.useState(0);

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  // const [state, setState] = React.useState({
  //   checkedA: false,
  //   checkedB: false,
  //   checkedC: false,
  //   checkedG: false
  // });
  // const handleCheckBoxChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const [selectedDate, setSelectedDate] = React.useState("");
  const [selectedTime, setSelectedTime] = React.useState("");

  const handleDateChange = (date) => {
    console.log(date.target.value);
    setSelectedDate(date.target.value);
  };
  const handleTimeChange = (date) => {
    console.log(date.target.value);
    setSelectedTime(date.target.value);
  };

  const ContainerType = (props) => {
    return <React.Fragment></React.Fragment>;
  };

  const handleSelectChange = async (e) => {
    console.log(e.target.value);
    setSelectReturnPoint(e.target.value);
    return;
  };

  return (
    <Page>
      <AppBar backLink="/login" />
      <Container>
        <EmptySpace />
        <SubTitle>다회용기 요청하기</SubTitle>
        <EmptySpace />
        <EmptySpace />
        <UpText>음식점 명</UpText>
        <OutLinedTextField
          value={restaurant}
          setValue={setRestaurant}
          disabled={addressFixing}
        />
        <EmptySpace />
        <EmptySpace />
        <AddressForm
          fixable={addressFixing}
          bfixButton={true}
          mainAddress={mainAddress}
          setMainAddress={setMainAddress}
          restAddress={restAddress}
          setRestAddress={setRestAddress}
          fixButtonTapped={(a) => {
            if (addressFixing) {
              setAddressFixing(false);
            } else {
              setAddressFixing(true);
            }
          }}
        />
        <EmptySpace />
        <EmptySpace />
        <DesText>요청 수량</DesText>
        <EmptySpace />
        <UpText>필요한 수량을 입력해주세요 </UpText>
        <Box style={{ width: "100%" }}>
          <Box
            style={{ width: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {[
              {
                title: "원형 소",
                description: "420ml 밥 용기  (1box / 20개)",
                value: 원형소.value,
                onChange: 원형소.onChange,
              },
              {
                title: "직사각 소",
                description: "300ml 반찬 용기  (1box / 20개)",
                value: 직사각소.value,
                onChange: 직사각소.onChange,
              },
              {
                title: "직사각 중(노랑)",
                description: "1.1L 국물 요리 용기  (1box / 30개)",
                value: 직사각중.value,
                onChange: 직사각중.onChange,
              },
              {
                title: "직사각 대(노랑)",
                description: "2.0L 국물 요리 용기 용기  (1box / 16개)",
                value: 직사각대.value,
                onChange: 직사각대.onChange,
              },
              {
                title: "납작사각 대",
                description: "1.8L 족발, 보쌈 용기  (1box / 14개)",
                value: 납작사각대.value,
                onChange: 납작사각대.onChange,
              },
              {
                title: "원형 특대",
                description: "3.3L 탕, 찌개 용기  (1box / 10개)",
                value: 원형특대.value,
                onChange: 원형특대.onChange,
              },
            ].map((v) => {
              return (
                <React.Fragment>
                  <Paper style={{ width: "100%", marginTop: "4px" }} key={"ab"}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Container>
                        <Box>
                          <Typography variant={"subtitle2"}>
                            {v.title}
                          </Typography>
                          <Typography variant={"caption"}>
                            {v.description}
                          </Typography>
                        </Box>
                      </Container>

                      <Box
                        display="flex"
                        flexWrap="nowrap"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Container>
                          <Box
                            display="flex"
                            flexWrap="nowrap"
                            flexDirection="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <TextField
                              variant="outlined"
                              margin="dense"
                              key={v.title}
                              style={{ width: 60, marginRight: "4px" }}
                              inputProps={{ inputMode: "numeric" }}
                              // value={v.value}
                              defaultValue={v.value}
                              onChange={v.onChange}
                            ></TextField>
                            <Typography>box</Typography>
                          </Box>
                        </Container>
                      </Box>
                    </Box>
                  </Paper>
                </React.Fragment>
              );
            })}
          </Box>
        </Box>

        {/* <OutLinedTextField inputProps={{ inputMode: 'numeric' }} value={quantity} setValue={setQuantity} /> */}
        <EmptySpace />
        <EmptySpace />
        <DesText>배송희망 날짜/시간</DesText>
        <EmptySpace />
        {/* //DatePicker */}
        <DatePicker
          label="날짜를 선택 하세요"
          setValue={setSelectedDate}
          value={selectedDate}
        />

        {/* //DatePicker */}
        <EmptySpace />
        {/* //TimePicker */}
        {/* <TimePicker label={"시간을 선택하세요"} value={selectedTime} setValue={setSelectedTime} /> */}
        {/* //TimePicker */}
        <EmptySpace />
        <DesText>요청할 세척센터</DesText>
        <TextField
          value={selectReturnPoint}
          variant="outlined"
          // label="선택하세요"
          fullWidth
          onChange={handleSelectChange}
          select
        >
          {returnPoints &&
            returnPoints.map((v) => {
              return <MenuItem value={v}>{v.data.storeName}</MenuItem>;
            })}
        </TextField>
        <Typography align="center" variant="body1">
          {selectReturnPoint?.data.mainAddress ??
            "" + " " + selectReturnPoint?.data.restAddress ??
            ""}
        </Typography>
        <EmptySpace />

        <ContainedButton
          onClick={async () => {
            if (
              !!!원형소.value &&
              !!!직사각소.value &&
              !!!직사각중.value &&
              !!!직사각대.value &&
              !!!납작사각대.value &&
              !!!원형특대.value
            ) {
              alert("요청하실 용기의 갯수를 입력해 주세요");
              return;
            }
            const pricing = await api.getPricing(constant.pricingId);
            console.log("프라이스", pricing);
            if (pricing.code !== 200) {
              return;
            }
            const requestObj = {
              pricing: pricing.data,
              status: constant.requestStatus.request,
              updatedBy: new Date(),
              request: {
                mainAddress,
                restAddress,
                storeName: restaurant,
                amount: {
                  원형소: 원형소.value,
                  직사각소: 직사각소.value,
                  직사각중: 직사각중.value,
                  직사각대: 직사각대.value,
                  납작사각대: 납작사각대.value,
                  원형특대: 원형특대.value,
                },
                createdBy: new Date(),
                // time: selectedTime,
                date: selectedDate,
                franchise: {
                  doc: auth.userExtraInfo.franchiseDocs[0],
                  owner: auth.user.email,
                },
              },
              washmateInfo: {
                owner: selectReturnPoint.data.owner,
                washmateId: selectReturnPoint.data.washmateDoc,
                createdAt: new Date(),
              },
            };
            const result = await api.createRequest(requestObj);
            if (result.code !== 200) {
              alert(result.msg);
              return;
            }
            alert("다회용기를 요청하였습니다");

            //Todo : 리퀘스트 만들기

            props.history.push("/main");
          }}
        >
          완료
        </ContainedButton>
        <EmptySpace />
      </Container>
    </Page>
  );
}
