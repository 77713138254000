import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Link from "../../../components/basic/Link";
import {Box,Container,Typography} from '@material-ui/core';
import {Header,Title,LastLink,SectionTitle} from "components/basic/Components";
import CustomerCenter from "assets/img/customercenter.png"
export default function SignUp(props) {
  const [value, setValue] = React.useState("restaurant");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Page>
      <Header title="고객센터"/>

      <EmptySpace />

      {/* <SubTitle>고객센터</SubTitle> */}

      <EmptySpace />
      <EmptySpace />
      <Box 
      display="flex" justifyContent="center"
      alignItems="center"
      style={{width:"100%"}}>
      <img src={CustomerCenter} style={{width:"25%", height:"auto"}} alt="Customer Center"/>
      </Box>
      <Box display="flex" justifyContent="center"
      alignItems="center">
        <Container>
        <EmptySpace />
      <EmptySpace />
        <Typography align="center">안녕하세요! 리플하다 고객센터 입니다</Typography>
        <Typography align="center">문의사항에 대해 순차적으로 답변을 드리고 있습니다</Typography>
        </Container>
      </Box>
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      <Box>
        <Container>
        <ContainedButton style={{marginBottom:"12px"}}
        onClick={()=>window.open('tel:050714871179')
        }
        >전화문의</ContainedButton>
        <ContainedButton 
        onClick={()=>window.open('mailto:replhada@gmail.com')
      }
        style={{marginBottom:"12px"}}>이메일 문의</ContainedButton>
        <ContainedButton 
        onClick={()=>window.open('http://pf.kakao.com/_Bcfws', '_blank')
      }
        style={{marginBottom:"12px"}}>카카오톡 문의</ContainedButton>
        </Container>
      </Box>
      
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      <EmptySpace />
      {/* <div style={{ marginLeft: "16px" }}>
        <h3
          style={{ fontSize: "18px", fontWeight: "bold" }}
        >서비스 이용문의</h3>
        <div style={{ marginTop: "20px", }}>
          <p style={{ marginTop: "8px", marginLeft: "8px" }}>- 전화문의</p>
          <p style={{ marginTop: "8px", marginLeft: "8px" }}>이용시간    평일9시-18시(주말,공휴일 제외)</p>
          <p style={{ marginTop: "8px", marginLeft: "8px" }}>전화번호    0507.1487.1179</p>
        </div>
        <div style={{ marginTop: "20px", }}>
          <p style={{ marginTop: "8px", marginLeft: "8px" }}>- 이메일 문의</p>
          <p style={{ marginTop: "8px", marginLeft: "8px" }}>replhada@gmail.com</p>

        </div>
        <div style={{ marginTop: "20px", }}>
          <p style={{ marginTop: "8px", marginLeft: "8px" }}>카카오톡 '리플하다' 검색</p>

          <a style={{ marginTop: "8px", marginLeft: "8px" }} target="_blank" href="http://pf.kakao.com/_Bcfws">리플하다 채널 바로가기</a>

        </div>

        <EmptySpace />
        <EmptySpace />
      </div> */}

      <EmptySpace />

      <EmptySpace />
    </Page>
  );
}
