import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";
import { useAuth } from "../../../AuthContext";

import UpText from "../../../components/basic/UpText";
import ContentText from "../../../components/basic/ContentText";

import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import qs from "qs";
import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const"

export default function Retrive(props) {
  const auth = useAuth();

  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
  });
  const rentId = query.rentId;
  const [rent, setRent] = React.useState(null);
  const [returnPoints, setReturnPoints] = React.useState(null);
  const [washmate, setWashmate] = React.useState(null);

  React.useState(() => {
    (async () => {

      const washResult = await api.getWashmate(constant.washmateId)
      if (washResult.code !== 200) {
        alert(washResult.msg)
        return
      }
      console.log("워시 리저트", washResult)
      setWashmate(washResult)
      const returnPoints = washResult.data.returnPointDocs
      console.log("리턴포인트", returnPoints)

      const pa = await Promise.all(returnPoints.map((element) => {
        return api.getReturnPoint(element)
      }))
      console.log("결과", pa)
      setReturnPoints(pa)

      const result = await api.getRent(rentId)
      console.log("리저트", result)
      if (result.code !== 200) {
        alert(result.msg)
        return
      }

      setRent(result)




    })()

  }, [])
  const [value, setValue] = React.useState("restaurant");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Page style={{ backgroundColor: "grey" }}>
      <AppBar backLink="/login" />
      <EmptySpace />

      <SubTitle>수거 정보</SubTitle>
      <EmptySpace />
      <EmptySpace />
      <DesText>수거 주소</DesText>
      <EmptySpace />

      <Card>
        <UpText>주소 </UpText>
        <ContentText>{rent && rent.data.customerInfo.mainAddress}</ContentText>
        <ContentText>{rent && rent.data.customerInfo.restAddress}</ContentText>
        <EmptySpace />
        {/* {rent && rent.data.customerInfo.bDoorLock && (<><UpText>출입문 비밀번호*</UpText>
          <ContentText>{rent && rent.data.customerInfo.doorlockPassword}</ContentText></>)} */}
      </Card>

      <EmptySpace />
      <EmptySpace />

      <DesText>수거 수익</DesText>
      <EmptySpace />
      <Card>
        <UpText>수익</UpText>
        <ContentText>{rent && rent.data.pricing.delivery}원</ContentText>
      </Card>


      <EmptySpace />
      <DesText>반납 장소를 선택해 주세요</DesText>
      <EmptySpace />
      <EmptySpace />

      {returnPoints && returnPoints.map((v) => {
        console.log("브이", v)
        return (<><Card>
          <UpText>세척 업체명</UpText>
          <ContentText>{v.data.storeName}</ContentText>
          <EmptySpace />
          <UpText>주소</UpText>
          <ContentText>{v.data.mainAddress}</ContentText>
          <ContentText>{v.data.restAddress}</ContentText>
          <ContainedButton
            onClick={async () => {
              //먼저 워시메이트, 리턴포인트 업데이트, 딜리버리, 스테터스 업데이트
              //그다음 /retriving?rentId 으로 이동
              const returnPointObj = Object.assign(v, { addedBy: (new Date()) })
              const rentInfo = {
                washmate:
                  { owner: constant.washmateOwner, doc: washmate.id, addedBy: (new Date()) },
                returnPoint: v,
                status: constant.rentStatus.deliveryReserved,
                delivery: {
                  owner: auth.user.email
                },
                updatedBy: (new Date())

              }
              const result = await api.updateRent(rent.id, rentInfo)
              if (result.code !== 200) {
                alert(result.msg)
                return
              }
              alert("수거 예약이 완료 되었습니다")
              props.history.push(`/retriving?rentId=${rent.id}`)
            }}
          >이 반납장소로 수거예약</ContainedButton>
          <EmptySpace />
        </Card>
          <EmptySpace />
          <EmptySpace /></>)
      })
      }

    </Page>
  );
}
