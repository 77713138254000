import React from "react";
import { useEffectOnce } from "react-use";
import Page from "components/Page";
// import GlobalContext from "context/GlobalContext";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import { useSnackbar } from "notistack";
import { useModal } from "react-hooks-use-modal";
import { makeStyles } from "@material-ui/core/styles";
import { Header, Title, LastLink } from "components/basic/Components";
import {
  Box,
  Paper,
  Typography,
  Container,
  Button,
  Grid,
} from "@material-ui/core";
import AddressForm from "components/basic/AddressForm";
import CloseIcon from "@material-ui/icons/Close";
import CheckBox from "components/basic/BcheckBox";
import UpText from "components/basic/UpText";
import EmptySpace from "components/basic/EmptySpace";
import ContainedButton from "components/basic/ContainedButton";
import { TextField } from "@material-ui/core";
import { createRent, getPricing } from "replhadaAPI";
import * as constant from "Const";
import useInput from "hooks/useInput";
var geocoder = require("google-geocoder");

const useStyles = makeStyles({
  table: {},
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const DoorTextField = (props) => {
  return (
    <React.Fragment>
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          value={props.doorPassword.value}
          onChange={props.doorPassword.onChange}
        />
      </Box>
    </React.Fragment>
  );
};

const DoorComponent = (props) => {
  return (
    <React.Fragment>
      <Box>
        <Container>
          <CheckBox
            label="출입문 비밀번호가 필요한가요?"
            checked={props.state.checkedA}
            onChange={props.handleCheckBoxChange}
            name="checkedA"
            sx={{ width: "100%" }}
          />
        </Container>
        {props.state.checkedA && (
          <React.Fragment>
            <Container>
              <UpText>아파트 출입문 비밀번호를 입력해주세요 (예#1234) </UpText>
              <EmptySpace />
              <UpText>비밀번호는 수거 후 자동 삭제되오니, 안심하세요^^ </UpText>
              <DoorTextField
                variant="outlined"
                fullWidth
                doorPassword={props.doorPassword}
              />
            </Container>
          </React.Fragment>
        )}

        <EmptySpace />
      </Box>
    </React.Fragment>
  );
};

const Home = (props) => {
  // const { user, setNetwork, setDialogMessage } =
  //     React.useContext(GlobalContext);

  const { enqueueSnackbar } = useSnackbar();
  const containerIds = props.location.state?.containerIds ?? [];
  const numOfContainers = props.location.state?.numOfContainers ?? 0;
  console.log("프롭스", props.location.state);
  const phoneNumber = props.location.state?.phoneNumber ?? "";
  console.log("checking phonenumber in inputorder", phoneNumber);
  const classes = useStyles();
  const [mainAddress, setMainAddress] = React.useState(null);
  const [restAddress, setRestAddress] = React.useState(null);
  const [addressFixing, setAddressFixing] = React.useState(false);
  const [state, setState] = React.useState({
    checkedA: false,
  });
  const doorPassword = useInput("");

  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });

  useEffectOnce(async () => {
    const dataResult = await initData();
    console.log("데이터", dataResult);
    setData(dataResult);
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const dataResult = await initData();
        setData(dataResult);
        setRefresh(false);
      })();
    }
  }, [refresh]);

  const initData = async () => {
    const priceResult = await getPricing(constant.pricingId);
    if (priceResult.code !== 200) {
      return;
    }
    return { price: priceResult.data };
  };
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const requestTapped = async () => {
    console.log("프롭 넘어온거", props.location.state);

    let latitude = 0;
    let longitude = 0;

    var geo = geocoder({
      key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
    });

    function coor(main, rest) {
      return new Promise((resolve, reject) => {
        geo.find(main + " " + rest, (err, res) => {
          if (err) {
            console.log("InputMyOrder:coor", err);
            reject(err);
            return;
          }
          resolve(res);
        });
      });
    }
    async function getCoordinate() {
      try {
        const coordi = await coor(mainAddress);
        return coordi;
      } catch (e) {
        console.log("InputMyOrder:getCoordinate", e);
      }

      return coordi;
    }
    try {
      const addrssCoor = await getCoordinate();
      longitude = addrssCoor[0]?.location.lng ?? "126.6559696";
      latitude = addrssCoor[0]?.location.lat ?? "37.5383499";
    } catch (e) {
      console.log("InputMyOrder:addrssCoor", e);
    }
    const orderer = {
      addrNew: mainAddress + " " + restAddress,

      addrOld: mainAddress + " " + restAddress,

      hangAdmCode: "",

      hangAdmName: "",

      latitude: latitude ?? "37.5383499",

      lawAdmCode: "",

      lawAdmName: "",

      longitude: longitude ?? "126.6559696",

      mobileNumber: "",

      plainMobileNumber: phoneNumber,
    };
    const order = {
      id: "",
      data: { orderer },
      createdAt: new Date(),
      recycledId: "",
      orderId: "",
    };

    const rentInfo = {
      status: constant.rentStatus.waitingDelivery,
      createdBy: new Date(),
      containerIds,
      numOfContainers,
      pricing: data.price,
      order: order,
      customerInfo: {
        mainAddress: mainAddress + " " + restAddress ?? "",
        restAddress: "",
        lat: latitude ?? "37.5383499",
        lng: longitude ?? "126.6559696",
        bDoorLock: state.checkedA ?? "",
        doorlockPassword: doorPassword ?? "",
        rating: 0,
        comment: "",
        addedBy: new Date(),
      },
      franchiseInfo: {
        owner: "",
        doc: "",
      },
    };
    console.log("인풋마이오더: 렌트인포 ", rentInfo);

    const result = await createRent(rentInfo);
    if (result.code !== 200) {
      console.log("checking result", result);

      alert(result.msg);
      return false;
    } else {
      console.log("checking result", result);
      console.log("checking what do you want to pass to rating", {
        id: result.id,
        data: result.data,
        phoneNumber,
        containerIds,
        numOfContainers,
      });
      props.history.push({
        pathname: "/rating",
        state: {
          id: result.id,
          data: result.data,
          containerIds,
          numOfContainers,
          phoneNumber,
        },
      });
    }
    return;

    return false;
  };
  const Body = (props) => {
    return <></>;
  };

  const ModalContent = (props) => {
    return (
      <React.Fragment>
        <Box></Box>
      </React.Fragment>
    );
  };

  const FixedButton = () => {
    return (
      <React.Fragment>
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          sx={{
            position: "fixed",
            bottom: "0px",
            left: "0px",
            right: "0px",
            width: "100%",
            height: "60px",
          }}
        >
          <Container>
            <Button
              fullWidth
              variant="contained"
              onClick={tableButtonTapped}
              color={"primary"}
            >
              <Typography>완료</Typography>
            </Button>
          </Container>
        </Box>
      </React.Fragment>
    );
  };
  const tableButtonTapped = () => {
    return history.push(`/rating`);
  };

  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          <React.Fragment>
            <Container>
              <Box>
                <Header title="주소지 입력" />
                <Title title="수거가 필요한 수거주소를 입력해주세요" />
                <AddressForm
                  fixable={!addressFixing}
                  mainAddress={mainAddress}
                  setMainAddress={setMainAddress}
                  restAddress={restAddress}
                  setRestAddress={setRestAddress}
                ></AddressForm>
              </Box>

              <DoorComponent
                state={state}
                doorPassword={doorPassword}
                handleCheckBoxChange={handleCheckBoxChange}
              ></DoorComponent>

              <ContainedButton
                style={{ marginTop: "24px" }}
                onClick={requestTapped}
              >
                수거요청
              </ContainedButton>
            </Container>
          </React.Fragment>
          <Modal>
            <ModalContent></ModalContent>
          </Modal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};
export default Home;
