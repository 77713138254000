import React from "react";
import { IconButton, AppBar, Toolbar, Box } from "@material-ui/core";
// import { useTheme } from "material-ui/material/styles";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

import GlobalContext from "context/GlobalContext";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const Header = () => {
    const { drawerIsOpen, setDrawerIsOpen } = React.useContext(GlobalContext);
    const classes = useStyles();
    const history = useHistory();

    const openDrawer = () => {
        setDrawerIsOpen(!drawerIsOpen);
    };

    return (
        <React.Fragment>
            <Toolbar />
            <AppBar
                enableColorOnDark
                position="fixed"
                sx={{
                    backgroundColor: "white",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Box display="flex">
                        <IconButton
                            onClick={() => history.push("/")}
                            sx={{ p: 0 }}
                            disableRipple={true}
                            disableFocusRipple={true}
                            aria-label="menu"
                        >
                            Panel Control
                        </IconButton>
                    </Box>
                    <Box className={classes.iconBox}>
                        <Box mr={1}>
                            <IconButton onClick={() => null}></IconButton>
                        </Box>
                        <Box>
                            <IconButton onClick={openDrawer}>
                                {drawerIsOpen ? (
                                    <CloseIcon sx={{ fontSize: 30 }} color="primary" />
                                ) : (
                                    <MenuIcon sx={{ fontSize: 30 }} color="primary" />
                                )}
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    iconBox: {
        display: "flex",
        alignItems: "center",
    },
}));

export default Header;
