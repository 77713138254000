import React from "react";
import { css } from "../../Const";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles({
  root: {
    margin: "auto",
    width: `calc(100% - 48px)`,
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

export default function Bcard(props) {
  const classes = useStyles();

  const marginSide = css.marginSide;
  return (
    <div style={{ width: "100%" }}>
      <Card className={classes.root} {...props}>
        <CardContent>{props.children}</CardContent>
      </Card>
    </div>
  );
}
