import React from "react";
import { useAuth } from "../../../AuthContext";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";

import {Radio,Container} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";


import {Header,Title,LastLink,SectionTitle} from "components/basic/Components";

export default function SignUp(props) {
  const auth = useAuth();

  const [email, setEmail] = React.useState("");
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Page>
            <Header title="비밀번호 재설정"/>

      <EmptySpace />
      <EmptySpace />
      <Container>
      <DesText>비밀번호를 재설정할 이메일을 <br/>입력하세요</DesText>
      <EmptySpace />
      <EmptySpace />
      
      <UpText>이메일 </UpText>
      <OutLinedTextField
        placeholder="Email"
        type="email"
        // helperText="투자하신 기기 수량만큼 수익이 창출됩니다"
        value={email}
        setValue={setEmail}
      />

      <EmptySpace />
      <ContainedButton
        onClick={() => {
          auth
            .sendPasswordResetEmail(email)
            .then(() => {
              window.alert("이메일로 링크가 전송됐습니다");
              // props.history.push("/login");
            })
            .catch((error) => {
              window.alert(error.message);
            });
        }}
      >완료</ContainedButton>
      <EmptySpace />
      </Container>
    </Page>
  );
}
