import * as api from "replhadaAPI";
import * as constant from "Const";
import firebase from "firebase/app";
import { calcCrow, getAMonthBerfore, getAMonthAfter } from "common";

var db = firebase.firestore();

class Order {
  constructor(orderId) {
    console.log(orderId);
    this.orderId = orderId;
  }
  async delete() {
    const result = await db
      .collection(constant.dbCollection.order)
      .doc(this.orderId)
      .delete();
    return true;
  }
}

class Delivery {
  constructor(userId) {
    this.userId = userId;
  }

  async setPushKey(pushKey) {
    try {
      const result = await db
        .collection(constant.dbCollection.user)
        .doc(this.userId)
        .update({ pushKey: pushKey });
      return { code: 200, result };
    } catch (e) {
      console.log(e);
      return { code: 400, msg: e.message };
    }
  }
  async setCoordinate(coordinate) {
    try {
      console.log(this.userId);
      const result = await db
        .collection(constant.dbCollection.user)
        .doc(this.userId)
        .update({ coordinate: coordinate });
      return { code: 200, result };
    } catch (e) {
      console.log(e);
      return { code: 400, msg: e.message };
    }
  }

  async reserve(rentId, rent) {
    const checkReserved = await api.getRent(rentId);
    if (checkReserved.data.status === constant.rentStatus.deliveryReserved) {
      return { code: 400, msg: "이미 예약된 수거요청입니다" };
    }
    const updateResult = await api.updateRent(rentId, rent);
    return updateResult;
  }
  async pickUp(rentId, rent) {
    const updateResult = await api.updateRent(rentId, rent);
    return updateResult;
  }
  async delivered(rentId, rent) {
    const updateResult = await api.updateRent(rentId, rent);
    return updateResult;
  }

  async getReserved() {
    try {
      const result = await db
        .collection(constant.dbCollection.rent)
        .where("delivery.owner", "==", this.userId)
        .where("status", "==", constant.rentStatus.deliveryReserved)
        .get();
      const data = result.docs.map((v) => ({ id: v.id, data: v.data() }));
      return { code: 200, data };
    } catch (e) {
      console.log(e);
      return { code: 400, msg: e.message };
    }
  }
  async getPickuped() {
    try {
      const result = await db
        .collection(constant.dbCollection.rent)
        .where("delivery.owner", "==", this.userId)
        .where("status", "==", constant.rentStatus.delivieryPicikup)
        .get();
      let data = [];
      console.log("docs ㅇ이곳", result?.docs);

      data = result.docs?.map((v) => ({ id: v.id, data: v.data() }));
      return { code: 200, data };
    } catch (e) {
      console.log(e);
      return { code: 400, msg: e.message };
    }
  }
  async cancelReservation(rentId, rent) {
    delete rent.delivery;
    rent.status = constant.rentStatus.waitingDelivery;
    const updateResult = await api.updateRent(rentId, rent);
    return updateResult;
  }
  async getTodayWorks(startDate, endDate) {
    console.log("시간", this.userId, startDate, endDate);
    try {
      const result = await db
        .collection(constant.dbCollection.rent)
        .where("delivery.owner", "==", this.userId)
        .where("status", "in", [
          constant.rentStatus.delivered,
          constant.rentStatus.completed,
        ])
        .where("updatedBy", "<=", endDate)
        .where("updatedBy", ">=", startDate)
        .get();

      let data = [];
      result.forEach((doc) => {
        data.push({ id: doc.id, data: doc.data() });
      });
      return { code: 200, data };
    } catch (e) {
      console.log(e);
      return { code: 400, msg: e.message };
    }
  }
  getWashmates = async () => {
    const washmatesData = await db
      .collection(constant.dbCollection.washmate)
      .get();
    const result = washmatesData.docs.map((v) => ({
      id: v.id,
      data: v.data(),
    }));
    return result;
  };
  getReturnPoints = async () => {
    const returnData = await db
      .collection(constant.dbCollection.returnPoint)
      .get();
    const result = returnData.docs.map((v) => ({ id: v.id, data: v.data() }));
    return result;
  };

  getRentByQRScan(qrData, rents) {
    let rent = null;

    if (qrData === null) {
      return null;
    }
    const dataLowerCase = qrData.toLowerCase();
    const prefixLowerCase = constant.prefix.container.toLowerCase();
    if (!dataLowerCase.includes(prefixLowerCase)) {
      return null;
    }
    let newContainerId = dataLowerCase.replace(prefixLowerCase, "");
    newContainerId = newContainerId.replace("containerid=", "");
    newContainerId = newContainerId.replace(/\s+/g, "");

    rents.forEach((v) => {
      v.data.containerIds.forEach((t) => {
        const containerLower = t.toLowerCase();
        if (containerLower === newContainerId) rent = v;
        return rent;
      });
    });
    return rent;
  }
  async returnQRScan(qrData) {
    let returnObj = null;

    if (qrData === null) {
      return null;
    }
    const dataLowerCase = qrData.toLowerCase();
    const prefixLowerCase = constant.prefix.returnPoint.toLowerCase();
    if (!dataLowerCase.includes(prefixLowerCase)) {
      return null;
    }
    let newQrId = dataLowerCase.replace(prefixLowerCase, "");
    newQrId = newQrId.replace("qrinfo=", "");
    newQrId = newQrId.replace(/\s+/g, "");
    const returnPoints = await this.getReturnPoints();
    returnPoints.forEach((v) => {
      if (v.data.qrinfo === newQrId) {
        returnObj = v;
        return returnObj;
      }
    });
    return returnObj;
  }

  calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    var lat1 = this.toRad(lat1);
    var lat2 = this.toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d.toFixed(1);
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  sortReturnPoints = async (coor) => {
    let returnPoints = await this.getReturnPoints();
    console.log(returnPoints);
    return returnPoints.sort(function (a, b) {
      console.log(a);
      const distanceA = calcCrow(
        coor?.lat ?? 0,
        coor?.lng ?? 0,
        a.data.coor?.lat ?? 0,
        a.data.coor?.lng ?? 0
      );
      const distanceB = calcCrow(
        coor?.lat ?? 0,
        coor?.lng ?? 0,
        b.data.coor?.lat ?? 0,
        b.data.coor?.lng ?? 0
      );
      if (distanceA < distanceB) {
        return -1;
      }
      if (distanceA > distanceB) {
        return 1;
      }
      return 0;
    });
  };
}

class Restaurant {
  constructor(userId) {
    this.userId = userId;
  }
  getWashmates = async () => {
    const washmatesData = await db
      .collection(constant.dbCollection.washmate)
      .get();
    const result = washmatesData.docs.map((v) => ({
      id: v.id,
      data: v.data(),
    }));
    return result;
  };
  async getReturnPoints() {
    const returnData = await db
      .collection(constant.dbCollection.returnPoint)
      .get();
    const result = returnData.docs.map((v) => ({ id: v.id, data: v.data() }));
    return result;
  }
  async sortReturnPoints(coor) {
    let returnPoints = await this.getReturnPoints();
    console.log(returnPoints);
    return returnPoints.sort(function (a, b) {
      console.log(a);
      const distanceA = calcCrow(
        coor.lat,
        coor.lng,
        a.data.coor.lat,
        a.data.coor.lng
      );
      const distanceB = calcCrow(
        coor.lat,
        coor.lng,
        b.data.coor.lat,
        b.data.coor.lng
      );
      if (distanceA < distanceB) {
        return -1;
      }
      if (distanceA > distanceB) {
        return 1;
      }
      return 0;
    });
  }

  calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d.toFixed(1);
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return (Value * Math.PI) / 180;
  }
}
class Container {
  constructor(containerId) {
    this.containerId = containerId;
  }
  async updateContainer(status) {
    await api.updateContainer(this.containerId, status);
  }
}

class Washmate {
  constructor(userId) {
    this.userId = userId;
  }
  async setPushKey(pushKey) {
    try {
      const result = await db
        .collection(constant.dbCollection.user)
        .doc(this.userId)
        .update({ pushKey: pushKey });
      return { code: 200, result };
    } catch (e) {
      console.log(e);
      return { code: 400, msg: e.message };
    }
  }
  async getRequest() {
    const reqeustData = await db
      .collection(constant.dbCollection.request)
      .where("washmateInfo.owner", "==", this.userId)
      .where("status", "==", constant.requestStatus.request)
      .get();
    const result = reqeustData.docs.map((v) => ({ id: v.id, data: v.data() }));
    return result;
  }
  async delivered(id, requestObj) {
    const reqeustData = await db
      .collection(constant.dbCollection.request)
      .doc(id)
      .update(requestObj);
  }
  async getRevenue(date) {
    const afterMonth = getAMonthAfter(date);
    let end = String(new Date(afterMonth).toISOString());
    let start = String(new Date(date).toISOString());
    try {
      console.log(
        start,
        end,
        constant.dbCollection.rent,
        constant.rentStatus.delivered,
        constant.rentStatus.completed,
        this.userId
      );
      const result = await db
        .collection(constant.dbCollection.rent)
        .where("status", "in", [
          constant.rentStatus.delivered,
          constant.rentStatus.completed,
        ])
        .where("washmate.owner", "==", this.userId)
        .where("updatedBy", ">", start)
        .where("updatedBy", "<", end)
        .orderBy("updatedBy", "desc")
        .get();

      const data = result.docs.map((doc) => {
        return { id: doc.id, data: doc.data() };
      });
      return { code: 200, data };
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}

export { Delivery, Restaurant, Container, Washmate, Order };
