import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import CheckBox from "../../../components/basic/BcheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import { Container, Box, Paper, Button, Typography } from "@material-ui/core";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import AddressForm from "../../../components/basic/AddressForm";
import Link from "../../../components/basic/Link";
import qs from "qs";
import { createRent } from "../../../replhadaAPI";
import * as api from "../../../replhadaAPI";
import * as constant from "../../../Const";
import QrReader from "react-qr-reader";
import { useEffectOnce } from "react-use";
import { useSnackbar } from "notistack";
import {
  Header,
  Title,
  LastLink,
  SectionTitle,
} from "components/basic/Components";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import QrScanner from "qr-scanner";
import theme from "styles/theme";

var geocoder = require("google-geocoder");
const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  wrapper: {
    width: 100 + theme.spacing(2),
  },
  paper: {
    zIndex: 1,
    position: "relative",
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true, // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
  });
  console.log("프롭스 로케이션", props.location.search.toLowerCase());
  const containerId = props.location.search
    .toLowerCase()
    .replace("?containerid=", "");
  const [rentInfo, setRentInfo] = React.useState(null);
  const [mainAddress, setMainAddress] = React.useState("");
  const [lat, setlat] = React.useState(0);
  const [lng, setlng] = React.useState(0);
  const [restAddress, setRestAddress] = React.useState("");
  const [addressFixing, setAddressFixing] = React.useState(false);

  const [doorPassword, setdoorPassword] = React.useState("");
  const [pricing, setPricing] = React.useState(null);
  const [containerIds, setContainerIds] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [qrScanner, setQrScanner] = React.useState(null);
  const [containerLength, setContainerLength] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleScan = async (data) => {
    const canVibrate = window.navigator.vibrate;

    if (data === null) {
      return;
    }
    const dataLowerCase = data.toLowerCase();
    const prefixLowerCase = constant.prefix.container.toLowerCase();
    if (dataLowerCase.includes(prefixLowerCase)) {
      let newContainerId = dataLowerCase.replace(prefixLowerCase, "");
      newContainerId = newContainerId.replace("containerid=", "");
      newContainerId = newContainerId.replace(" ", "");
      newContainerId = newContainerId.replace(/\s+/g, "");
      console.log(newContainerId);
      console.log(containerIds);

      if (newContainerId === "") {
        if (canVibrate) {
          window.navigator.vibrate([200]);
        }
        return;
      }
      if (containerIds.includes(newContainerId)) {
        // alert("중복")
        if (canVibrate) {
          window.navigator.vibrate([200]);
        }

        return;
      }
      if (canVibrate) {
        window.navigator.vibrate([200]);
      }

      let add = containerIds;
      add.push(newContainerId);
      add = add.filter((v, i) => {
        console.log("v", v);
        if (v === "") {
          return false;
        }
        return add.indexOf(v) === i;
      });

      enqueueSnackbar("다회용기 추가 성공", { variant: "success" });

      setContainerIds(add);
      setContainerLength(add.length);

      // if (containerId === rent.data.containerId.toLowerCase()) {
      //TODO update status, updatedBy 배달부 객체에
      // await update(rentObj)
      // alert("회수에 성공했습니다")

      // props.history.push(`/retriving?rentId=${rent.id}`)
      //   return
      // } else {
      //   alert("회수할 다회용기가 아닙니다")
      //   return
      // }
    }
    return;
    // if data.conta
    // setqrData(data)
  };

  const handleError = (err) => {
    console.error(err);
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
    checkedC: false,
    checkedG: false,
  });
  const [numOfContainers, setNumOfContainers] = React.useState("");

  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  //TODO
  // 용기 계속 찍기
  return (
    <Page>
      <Box
        style={{
          position: "fixed",
          top: "-100px",
          left: "-100px",
          backgroundColor: theme.palette.primary.main,
          width: "200px",
          height: "200px",
          borderRadius: "100px",
          zIndex: 0,
        }}
      >
        <Title
          style={{
            position: "fixed",
            top: "16px",
            left: "16px",
            fontWeight: "700",
          }}
          title="다회용기 수량을 입력해 주세요"
        />
        <Typography
          style={{
            position: "fixed",
            top: "48px",
            left: "16px",
            color: "gray",
          }}
        ></Typography>
      </Box>

      <EmptySpace />
      <EmptySpace />

      <Box sx={{ width: "100%" }}>
        <Container>
          <EmptySpace />
        </Container>
        <Box style={{ width: "100px", height: "100px", color: "gray" }}></Box>
        <EmptySpace />
        <EmptySpace />
        <Title title="용기 수량 입력"> </Title>
        <Container>
          <Paper elevation={3}>
            <>
              <Container>
                <EmptySpace />
                <UpText> </UpText>
                <OutLinedTextField
                  margin="dense"
                  value={numOfContainers}
                  setValue={setNumOfContainers}
                />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ width: "100%" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginTop: "4px", marginBottom: "8px" }}
                    fullWidth={false}
                    onClick={async () => {
                      try {
                        console.log("흠", numOfContainers);
                        if (!numOfContainers) {
                          alert("용기의 수를 입력해 주세요");
                          return;
                        }
                        props.history.push({
                          pathname: `/customerphone`,
                          state: { numOfContainers, containerIds },
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  >
                    입력
                  </Button>
                </Box>
              </Container>
            </>
          </Paper>
        </Container>
      </Box>

      {/* TODO 용기 갯수 */}
      {/* 용기 갯수가 추가되었습니다 햅틱*/}

      <Box style={{ width: "100%", position: "fixed", bottom: "10px" }}>
        {/* <SectionTitle
          title={`스캔된 다회용기: ${containerLength}개`}
        ></SectionTitle> */}

        <Container></Container>
      </Box>
    </Page>
  );
}
