import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../AuthContext";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import AdminAppBar from "../../../components/basic/AdminAppBar";

import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import Link from "../../../components/basic/Link";
import Dialog from "../../../components/basic/Dialog";
import * as api from "../../../replhadaAPI"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button"
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";



import QrReader from 'react-qr-reader'
import GoogleMapReact from 'google-map-react';
import PlaceIcon from '@material-ui/icons/Place';

import ListMenu from "../../../components/basic/ListMenu";
import * as constant from "../../../Const";
import * as common from "../../../common"




export default function SignUp(props) {
    const auth = useAuth();

    const [role, setRole] = React.useState(null)
    const [open, setOpen] = React.useState(false);
    const [deliveryRents, setDeliveryRents] = React.useState(null)
    const [pickedDeliveryRents, setPickedDeliveryRents] = React.useState(null)
    const [activeDeliveryRents, setactiveDeliveryRents] = React.useState(null)

    React.useEffect(() => {

        (async () => {

            if (auth.userExtraInfo === null) {
                return
            }

            if (auth.userExtraInfo.role === constant.role.delivery) {

                //TODO 여기서 렌트 가저오기
                //현재 렌트 스테터스 웨이팅 딜리버리인 애들 가져오기
                const myPickups = await api.getUserActiveRents(auth.user.email)


                if (myPickups.code !== 200) {
                    alert(myPickups.msg)
                    return
                }
                const removeDrifted = myPickups.data.filter((v) => {

                    return v.data.status !== constant.rentStatus.drifted && v
                }
                )

                setactiveDeliveryRents(removeDrifted)


                const getRents = await api.getRentByStatus(constant.rentStatus.waitingDelivery)
                if (getRents.code !== 200) {
                    alert(getRents.msg)
                    return
                }
                setDeliveryRents(getRents.data)
            }
        })()

    }, [auth.userExtraInfo])
    const defaultMapOptions = {
        fullscreenControl: false,
    };

    const handleClickOpen = (v) => {
        console.log("브이", v)
        setPickedDeliveryRents(v)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleScan = (datat) => {
        if (datat === null) {
            return
        }

        const dataLowerCase = datat.toLowerCase()
        const prefixLowerCase = constant.prefix.container.toLowerCase()
        if (dataLowerCase.includes(prefixLowerCase)) {
            const containerId = dataLowerCase.replace(prefixLowerCase, "")
            props.history.push(`/sendcontainer?${containerId}`)

            return
        } else {
            alert("다회용기의 QR을 스캔해주세요")
            return
        }
        return
        // if data.conta
        // setqrData(data)

    }

    const handleError = (err) => {
        console.error(err);
    };
    const [defaultCoordinate, setdefaultCoordinate] = React.useState({ lat: 37.6706743, lng: 126.7591576 });

    const [coordinate, setCoordinate] = React.useState(null);
    React.useEffect(() => {
        if (auth.userExtraInfo !== null) {
            setRole(auth.userExtraInfo.role)
        }

    }, [auth.userExtraInfo])

    React.useEffect(() => {

        navigator.geolocation.getCurrentPosition(function (position) {
            let lat = position.coords.latitude;
            let lng = position.coords.longitude;
            console.log("내위치", lat, lng)
            setCoordinate({ lat, lng })

        })
    }, [])

    const [value, setValue] = React.useState("restaurant");
    const [qrdata, setqrData] = React.useState("Not Found");




    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,
        checkedG: false
    });
    const data = [
        { title: "입고된 용기", path: "/received" },
        { title: "음식점 분배 요청", path: "/requests" },
        { title: "용기 출고 예약 리스트", path: "/reserved" }
    ];

    const adminData = [
        { title: "음식점 리스트", path: "/adminrestuarantlist" },
        { title: "세척파트너 리스트", path: "/adminwashmatelist" },
        { title: "수거파트너 리스트", path: "/admindeliverylist" },
        { title: "용기 리스트", path: "/admincontainerlist" },
        { title: "진행중인 렌트 리스트", path: "/adminrentinglist" },
        { title: "완료된 렌트 리스트", path: "/adminrentedlist" },
        { title: "고객후기 리스트", path: "/adminreviewlist" },
    ];
    const handleCheckBoxChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const View = role === constant.role.franchise ? franchiseView : (role === constant.role.delivery ? deliveryView : (role === constant.role.washmate ? washmateView : (role === constant.role.admin ? adminView : "")))


    const AnyReactComponent = ({ text }) => <div>{text}</div>;



    const adminView = (<> <Page>
        <AdminAppBar menu={true} />
        <ListMenu data={adminData} />
    </Page></>)
    const washmateView = (<> <Page>
        <AdminAppBar menu={true} />
        <ListMenu data={data} />
    </Page></>)
    const deliveryView = (<Page>
        <AppBar menu={true} />
        {/* <EmptySpace />
    <DesText>음식점</DesText>
    <EmptySpace />
    <EmptySpace /> */}
        <div style={{ height: 'calc(100% - 66px)', width: '100%' }}>
            {deliveryRents && coordinate && <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU" }}
                defaultCenter={defaultCoordinate}
                defaultZoom={16}
                distanceToMouse={() => { }}
                options={defaultMapOptions}
                center={coordinate}
            >


                {deliveryRents && deliveryRents.map((v) => {
                    console.log("왜 좌표", v.data.customerInfo.lat)
                    console.log("왜 좌표", v.data.customerInfo.lng)
                    console.log("왜 좌표", typeof v.data.customerInfo.lng)
                    console.log("왜 좌표", typeof 37.6664354)
                    return (<PlaceIcon
                        lat={v.data.customerInfo.lat}
                        lng={v.data.customerInfo.lng}
                        text="My Marker"


                        onClick={() => {
                            handleClickOpen(v)
                        }
                        }
                    />

                    )


                })}


                <Dialog
                    open={open}
                    onClose={handleClose}
                    title={`픽업 수익      : ${pickedDeliveryRents && pickedDeliveryRents.data.pricing.delivery}원`}
                    content={`픽업 주소      : ${(pickedDeliveryRents && pickedDeliveryRents.data.customerInfo.mainAddress + " " + pickedDeliveryRents.data.customerInfo.restAddress)}`}
                    content2={`거리 : ${common.calcCrow(coordinate.lat, coordinate.lng, pickedDeliveryRents && pickedDeliveryRents.data.customerInfo.lat, pickedDeliveryRents && pickedDeliveryRents.data.customerInfo.lng)}km`}
                    closeButtonTapped={handleClose}
                    actionButtonTapped={() => {
                        props.history.push(`/retrive?rentId=${pickedDeliveryRents.id}`)
                    }}
                />


            </GoogleMapReact>
            }


        </div>
        {activeDeliveryRents && activeDeliveryRents.length !== 0 && (<Card style={{ position: "fixed", bottom: "120px", left: "0px", marginLeft: "24px", }}><span>현재 진행중인 픽업이 있습니다</span> <ContainedButton

            onClick={() => {
                if (activeDeliveryRents.length === 1) {
                    console.log("activeRents", activeDeliveryRents[0])
                    props.history.push(`/retriving?rentId=${activeDeliveryRents[0].id}`)
                    return true
                }
                props.history.push("/pickups")
                return true
            }}
        >완료하기</ContainedButton></Card>)}

    </Page >)
    const previewStyle = {
        height: 240,
        width: 320,
    }
    const franchiseView = (<Page>
        <AppBar menu={true} />
        <EmptySpace />
        <DesText>음식점</DesText>
        <EmptySpace />
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%"
            }}
        // onClick={() => {
        // }}
        >
            <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                facingMode={"environment"}

            />


            <p
                style={{
                    margin: "auto",
                    marginTop: "20px"
                }}
                onClick={() => {

                    console.log("큐알 터치 됐다")
                    props.history.push(`/sendcontainer?containerId=abcd1234`)

                }}
            >
                사용하실 다회용기의 QR을 찍어주세요
            </p>
        </div>
        <EmptySpace />
        <EmptySpace />
        <EmptySpace />
        <EmptySpace />
        <Link
            onClick={() => {
                props.history.push("/requestcontainer");
            }}
            justifycontent="flex-end"
        >
            다회용기 요청하기
        </Link>
        <EmptySpace />
    </Page>)
    if (auth.userExtraInfo === null) {
        return "로딩중"
    }
    if (auth.userExtraInfo.role === constant.role.delivery && coordinate === null) {
        return "내 위치를 가져오고 있습니다"
    }

    if (auth.userExtraInfo.role === constant.role.delivery && deliveryRents === null) {
        return "로딩중"
    }

    return (
        <>


            {auth.userExtraInfo && auth.userExtraInfo.role === constant.role.franchise && franchiseView}
            {auth.userExtraInfo && auth.userExtraInfo.role === constant.role.delivery && deliveryView}
            {auth.userExtraInfo && auth.userExtraInfo.role === constant.role.washmate && washmateView}
            {auth.userExtraInfo && auth.userExtraInfo.role === constant.role.admin && washmateView}
        </>)
}