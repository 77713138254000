import React from "react";
import { css } from "../../Const";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import * as common from "../../common"

export default function RevenueDisplay(props) {

    const marginSide = css.marginSide;
    return (
        <div
            style={{
            }}
        >
            <Paper
                variant="outlined"
                square
                style={{
                    boxShadow: "0px 6px 10px rgba(0, 10, 18, 0.2)",
                    height: "138px",
                    margin: "0 17px",
                    paddingTop: "12px",
                    borderRadius: "15px",
                    backgroundColor: "#000A12"
                }}
            >
                <div
                    style={{
                        margin: "40px 24px",
                        boxShadow: "0px 6px 10px rgba(0, 10, 18, 0.2)"
                    }}
                >
                    <p
                        style={{
                            color: "white",
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#ECEFF1",
                            opacity: "0.6"
                        }}
                    >
                        {props.calender && props.calender.replace("-", ".")} 
                    </p>
                    <p
                        style={{
                            color: "#E5E5E5",
                            fontStyle: "normal",
                            fontWeight: "800",
                            fontSize: "28px",
                            lineHeight: "34px",
                            textAlign: "left",
                            marginTop: "16px"
                        }}
                    >
                      {props.totalRent}  건 / {(common.numberWithCommas(props.totalRevenue)) + " 개"}
                    </p>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "flex-end"
                        }}
                    >

                    </div>
                </div>
            </Paper>
        </div>
    );
}
