import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";
import { useAuth } from "../../../AuthContext";

import UpText from "../../../components/basic/UpText";
import ContentText from "../../../components/basic/ContentText";

import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import qs from "qs";
import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const"
import { useLocation } from "react-router-dom";

export default function Retrive(props) {
    const auth = useAuth();

    const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
    });
    const rentId = query.rentId;
    const [rent, setRent] = React.useState(null);
    const [returnPoint, setReturnPoint] = React.useState(null);
    const [washmate, setWashmate] = React.useState(null);
    const [data, setData] = React.useState(null)

    const location = useLocation();

    React.useEffect(() => {
        console.log(location.pathname); // result: '/secondpage'
        console.log(location.search); // result: '?query=abc'
        console.log("ㄷㅔ이터터", location.state.data); // result: 'some_value'
        setData(location.state.data)
    }, [location]);


    const [value, setValue] = React.useState("restaurant");
    const [refresh, setRefresh] = React.useState(false);

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,
        checkedG: false
    });
    const handleCheckBoxChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const info = rent && (rent.data.status === constant.rentStatus.deliveryReserved ? "다회용기의를 회수하여 용기의 QR을 찍어주세요" :
        (rent.data.status === constant.rentStatus.delivieryPicikup ? "수거장소로 다회용기를 배달하여 수거장소 QR을 스캔하여 완료해 주세요" : ""))

    return (
        <Page style={{ backgroundColor: "grey" }}>
            <AppBar backLink="/admincontainerlist" />
            <EmptySpace />

            <SubTitle>{data && data.storeName}</SubTitle>
            <EmptySpace />

            <UpText>{info}</UpText>
            <EmptySpace />
            <EmptySpace />
            <DesText>업체 정보</DesText>
            <EmptySpace />

            <Card>
                <UpText>상호 </UpText>
                <ContentText>{data && data.storeName}</ContentText>
                <UpText>주소 </UpText>
                <ContentText>{data && data.mainAddress}</ContentText>
                <ContentText>{data && data.restAddress}</ContentText>
                <EmptySpace />

            </Card>

            <EmptySpace />
            <EmptySpace />

            <DesText>업주 정보</DesText>
            <EmptySpace />
            <Card>
                <UpText> 연락처 </UpText>
                <ContentText>{data && data.owner.contact}</ContentText>
                <EmptySpace />
            </Card>


            <EmptySpace />
        </Page>
    );
}
