import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {Box,TextField} from "@material-ui/core";

import { css } from "../../Const";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200
  }
}));
export default function ContainedButton(props) {
  const classes = useStyles();


  var date = new Date();
  const now = moment(date).format('YYYY-MM-DD').toString()
  console.log(now)
  const handleDateChange = (date) => {
    props.setValue(date.target.value);
  };
  const marginSide = css.marginSide;
  const bothMarginside = marginSide * 2;
  const marginTop = css.marginTop;
  return (
    <Box style={{  }}>
      <form className={classes.container} noValidate>
        <TextField
          {...props}
          fullWidth
          type="date"
          variant="outlined"
          value={props.value}
          onChange={handleDateChange}
          defaultValue={now}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
      </form>
    </Box>
  );
}

// import DatePicker from "../../../components/basic/DatePicker"

// const [selectedDate, setSelectedDate] = React.useState("");

{/* <DatePicker label="날짜를 선택 하세요" setValue={setSelectedDate} value={selectedDate} /> */ }
