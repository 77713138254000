import firebase from "./firebaseConfig";
import axios from "axios"
import * as constant from "./Const"
let db = firebase.firestore();
let user = firebase.auth().currentUser;


const send = async function (url, json) {
    const header = {
        headers: {
            "Content-Type": "application/json",
        }
    };
    const res = await axios.post(url, json, header);
    return res.data
};

//adminWashmateList

//adminContainerList
//adminDeliveryList
//adminRentedList
//adminRentingList
//adminRestaurantList
//adminReviewList
//adminWashmateList

export async function washmatePush(userId) {
    try {
        
        const res = await send(constant.urls.api + "washmates/push",{userId})
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}



export async function adminWashmateList() {
    try {
        const res = await send(constant.urls.api + "admin/washmates")
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}
export async function adminReturnPointList(docs) {
    try {
        const res = await send(constant.urls.api + "admin/returnpoints", { docs })
        console.log("닥스", docs)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}
export async function adminContainerList(pagination) {
    try {
        const res = await send(constant.urls.api + "admin/containers", { pagination })
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}
export async function adminDeliveryList(pagination) {
    try {
        const res = await send(constant.urls.api + "admin/deliveries", { pagination })
        console.log(pagination)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}
export async function adminRentedList(pagination) {
    try {
        const res = await send(constant.urls.api + "admin/rented", { pagination })
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}
export async function adminRentingList(pagination) {
    try {
        const res = await send(constant.urls.api + "admin/renting", { pagination })
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}
export async function adminRestaurantList() {
    try {
        const res = await send(constant.urls.api + "admin/restaurants")
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}
export async function adminReviewList(pagination) {
    try {
        const res = await send(constant.urls.api + "admin/rents", { pagination })
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}

//create containers
//createDeliveries
//createFranchise
//createWashmate
//createRequest

export async function createRent(info) {
    try {
        const res = await send(constant.urls.api + "rents/create", { info })
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}
export async function createUser(id, info) {
    try {
        console.log("크리에이트 유저", id, info)
        const res = await send(constant.urls.api + "users/create", { id, info })
        // const res1 = await send(constant.urls.api + "users/create", { id:id, info:info })

        console.log(res)

        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요2" };
    }
}
export async function createFranchise(info) {
    try {
        const res = await send(constant.urls.api + "franchises/create", { info })
        console.log("레스", res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요3" };
    }
}
export async function createDelivery(info) {
    try {
        const res = await send(constant.urls.api + "deliveries/create", { info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요4" };
    }
}
export async function createWashmate(info) {
    try {
        const res = await send(constant.urls.api + "washmates/create", { info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function createRequest(info) {
    try {
        const res = await send(constant.urls.api + "requests/create", { info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function createReturnPoint(info) {
    try {
        console.log("센트 워시메이트 인포", info)
        const res = await send(constant.urls.api + "returnpoints/create", { info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function createContainer(info) {
    try {
        const res = await send(constant.urls.api + "containers/create", { info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

// 여기서 부터 만들

export async function getOrdersByPhoneNumber(phoneNumber){
    try {
        const res = await send(constant.urls.api + "orders/phonenumber/get", { phoneNumber })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

// ORDERS
export async function getOrdersByOrderId(orderId){
    try {
        const res = await send(constant.urls.api + "orders/orderid/get", { orderId })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function getOrders(){
    try {
        const res = await send(constant.urls.api + "orders/get", {  })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}






//updateRents
//updateContainers
//updateFranchise
//updateWashmate
//updateRequest


export async function updateRent(id, info) {
    try {
        const res = await send(constant.urls.api + "rents/update", { id, info })
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function updateUser(id, info) {
    try {
        const res = await send(constant.urls.api + "users/update", { id, info })
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function updateFranchise(id, info) {
    try {
        const res = await send(constant.urls.api + "franchises/update", { id, info })
        return res
        return { code: 200, data: res.data, id: res.id };
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function updateDelivery(id, info) {
    try {
        const res = await send(constant.urls.api + "deliveries/update", { id, info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function updateWashmate(id, info) {
    try {
        const res = await send(constant.urls.api + "washmates/update", { id, info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function updateRequest(id, info) {
    try {
        const res = await send(constant.urls.api + "requests/update", { id, info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function updateReturnPoints(id, info) {
    try {
        const res = await send(constant.urls.api + "returnpoints/update", { id, info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function updateContainer(containerId, info) {
    try {
        console.log("아이디", containerId)
        const res = await send(constant.urls.api + "containers/update", { containerId, info })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

export async function getPreviousRent(containerId) {
    try {
        console.log("아이디", containerId)
        const res = await send(constant.urls.api + "rents/getPrevious", { containerId })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}


//getRent
//getUser
//getFranchise
//getDelivery
//getWashmate
//getrequest
//getReturnPoints
//getContainer



export async function getRent(id) {
    try {
        const res = await send(constant.urls.api + "rents/get", { id })
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}
export async function getUser(id) {
    try {

        const res = await send(constant.urls.api + "users/get", { id })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요2" };
    }
}
export async function getFranchise(id) {
    try {
        const res = await send(constant.urls.api + "franchises/get", { id })
        console.log("레스", res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요3" };
    }
}
export async function getDelivery(id) {
    try {
        const res = await send(constant.urls.api + "deliveries/get", { id })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요4" };
    }
}
export async function getWashmate(id) {
    try {
        const res = await send(constant.urls.api + "washmates/get", { id })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function getRequest(id) {
    try {
        const res = await send(constant.urls.api + "requests/get", { id })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function getReturnPoint(id) {
    try {
        const res = await send(constant.urls.api + "returnpoints/get", { id })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function getContainer(id) {
    try {
        const res = await send(constant.urls.api + "containers/get", { id })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function getPricing(id) {
    try {
        const res = await send(constant.urls.api + "pricing/get", { id })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

export async function getRentByStatus(status) {
    try {
        const res = await send(constant.urls.api + "rents/getByStatus", { status })
        console.log(status)
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}

export async function getRentByUserIdAndStatus(userId,status) {
    try {
        const res = await send(constant.urls.api + "rents/getRentByUserIdAndStatus", { userId,status })
        console.log(status)
        console.log(res)
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요1" };
    }
}


//createRent
//createUser
//createFranchise
//createDelivery
//createWashmate
//createrequest
//createReturnPoints
//createContainer

//updateRent
//updateUser
//updateFranchise
//updateDelivery
//updateWashmate
//updaterequest
//updateReturnPoints
//updateContainer


export async function findRentByContainerId(id) {
    try {
        const res = await send(constant.urls.api + "rents/findByContainerId", { id })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}
export async function getUserActiveRents(email) {
    try {
        const res = await send(constant.urls.api + "rents/getUserActive", { email })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}


export async function deleteField(collection, docId, field) {
    try {
        const res = await send(constant.urls.api + "deleteField", { collection, docId, field })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

export async function washReceivedRents(email) {
    try {
        const res = await send(constant.urls.api + "rents/washReceived", { email })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

export async function getStores(email, docs, storeDocType) {
    try {
        let res;
        if (storeDocType === "franchiseDocs") {
            res = await send(constant.urls.api + "franchises/getStores", { email, docs })
        }
        if (storeDocType === "washmateDocs") {
            res = await send(constant.urls.api + "washmates/getStores", { email, docs })
        }

        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

export async function getWashRequests(email, status) {
    try {
        console.log(status)
        const res = await send(constant.urls.api + "requests/getWashRequests", { email, status })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

export async function deleteUser(id) {
    try {
        const res = await send(constant.urls.api + "users/delete", { id })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

export async function getRevenuRent(role, email, yearMonth) {
    try {
        console.log("monthe", yearMonth)
        const res = await send(constant.urls.api + "rents/revenue", { role, email, yearMonth })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}

export async function getRevenuRequest(email, yearMonth) {
    try {
        console.log("monthe", yearMonth)
        const res = await send(constant.urls.api + "requests/revenue", { email, yearMonth })
        return res
    } catch (e) {
        console.log("에러", e);
        return { code: 400, msg: "시스템에러, 다시시도해 주세요" };
    }
}



