import React from "react";
import { css } from "../../Const";

export default function UpText(props) {
    const marginSide = css.marginSide;
    const marginTop = css.marginTop;

    return (
        <div style={{ width: "100%" }}>
            <p
                style={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "18px",
                    margin: `${marginTop}px ${marginSide}px`,
                    color: "#323232",

                }}
            >
                {props.children}
            </p>
        </div>
    );
}
