import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";
import InfiniteScroll from "react-infinite-scroll-component";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import Link from "../../../components/basic/Link";

import * as constant from "../../../Const";
import * as common from "../../../common"
import * as api from "../../../replhadaAPI"
import { useAuth } from "../../../AuthContext";
import moment from "moment";


const useStyles = makeStyles({
    table: {
        width: "100%"
    }
});
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};





export default function SignUp(props) {
    const auth = useAuth();

    const classes = useStyles();
    const [value, setValue] = React.useState("restaurant");
    const [rents, setRents] = React.useState(null);
    const pageNum = 50
    const [pagination, setPagination] = React.useState({ startPoint: null, num: pageNum, hasMore: true })
    const fetchData = async () => {

        const received = await api.adminContainerList(pagination)
        if (received.pagination.hasMore) {
            setRents([...rents, ...received.data])
        }
        setPagination(received.pagination)


    }

    const [state, setState] = React.useState({
        items: Array.from({ length: 20 }),
        hasMore: true
    })


    const fetchMoreData = () => {
        if (state.items.length >= 500) {
            setState({ hasMore: false });
            return;
        }
        // a fake async api call like which sends
        // 20 more records in .5 secs
        setTimeout(() => {
            setState({
                items: state.items.concat(Array.from({ length: 20 }))
            });
        }, 500);
    };

    React.useEffect(() => {
        (async () => {
            const received = await api.adminContainerList(pagination)
            setRents(received.data)
            setPagination(received.pagination)

        })()

    }, [])



    const handleCheckBoxChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <Page>
            <AppBar backLink="/main" />
            <EmptySpace />

            <SubTitle>다회용기 리스트</SubTitle>
            <EmptySpace />
            <EmptySpace />
            <EmptySpace />
            <div>




                <InfiniteScroll
                    dataLength={rents && rents.length}
                    next={fetchData}
                    hasMore={pagination.hasMore}
                    loader={<h5 style={{ zIndex: "2", textAlign: "center", marginTop: "5px" }}>더보기</h5>}
                    endMessage={
                        <p style={{ fontSize: "20px", textAlign: "center", marginTop: "5px" }}>
                            <b>마지막</b>
                        </p>}
                >
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>컨테이너 ID</TableCell>
                                    <TableCell align="center">상태</TableCell>
                                    <TableCell align="center">업데이트</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rents && rents.map((row, i) => (
                                    // <div style={{
                                    //     height: 40,
                                    //     border: "1px solid green",
                                    //     margin: 6,
                                    //     padding: 8
                                    // }} key={i}>

                                    <TableRow key={row.storeName + i}

                                    >

                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="center">{row.data.status}</TableCell>

                                        <TableCell align="center">
                                            {common.getTimeStamp(row.data.updatedBy)}
                                        </TableCell>
                                    </TableRow>

                                    // </div>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InfiniteScroll>
            </div>
            <EmptySpace />
            <EmptySpace />

            <EmptySpace />
        </Page >
    );
}
