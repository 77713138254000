import React from "react";
import { useAuth } from "../../../AuthContext";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import CheckBox from "../../../components/basic/BcheckBox";
import CheckBoxSelf from "../../../components/basic/CheckBoxSelf";
import AddressForm from "../../../components/basic/AddressForm";
import qs from "qs";
import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const"
import { useLocation } from "react-router-dom";

export default function SignUp(props) {
  const auth = useAuth();
  const location = useLocation();
  const [containerId, setContainerId] = React.useState(null)
  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
  });
  React.useEffect(() => {
    (async () => {
      console.log(location.pathname); // result: '/secondpage'
      console.log(location.search); // result: '?query=abc'
      console.log("ㄷㅔ이터터", location.state.data); // result: 'some_value'
      setContainerId(location.state.data.toLowerCase().replace("containerid=", ""))
    })()

  }, [location]);
  // const containerId = query.containerId;
  const [value, setValue] = React.useState("restaurant");

  const [mainAddress, setMainAddress] = React.useState("");
  const [restAddress, setRestAddress] = React.useState("");
  const [pricing, setPricing] = React.useState(null)
  React.useEffect(() => {
    (async () => {
      const result = await api.getPricing(constant.pricingId)
      if (result.code !== 200) {
        return
      }
      console.log("가격정보", result.data)
      setPricing(result.data)
    })()

  }, [])

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const addressView = (<><EmptySpace />
    <AddressForm
      mainAddress={mainAddress}
      setMainAddress={setMainAddress}
      restAddress={restAddress}
      setRestAddress={setRestAddress}
      fixable={true}
    />
    <EmptySpace /></>)

  return (
    <Page>
      <AppBar backLink="/main" menu={true} />
      <EmptySpace />

      <SubTitle>음식배달 정보</SubTitle>
      <EmptySpace />
      <DesText>다회용기ID : {containerId}</DesText>

      <EmptySpace />
      <CheckBoxSelf
        label="고객 주소 정보 입력"
        checked={state.checkedA}
        onChange={handleCheckBoxChange}
        name="checkedA"
      />
      <UpText>*용기 회수를 위해 고객님의 주소정보가 필요합니다 </UpText>
      <EmptySpace />

      {state.checkedA && addressView}
      <ContainedButton onClick={async () => {
        //TODO렌트정보 생성
        //pricing에서 정보 받아오기


        //렌탈 정보 찾기 컨테이너아이디로
        // 현재 컨테이너 아이디가 안감
        const findResult = await api.getPreviousRent(containerId)
        console.log("findResult", findResult)
        if (findResult.code !== 200) {
          alert("파인드리저트", findResult.msg)
          return
        }
        if (findResult.data.length === 1) {
          //렌탈정보 컴플리트로 바꾸기
          const updateResult = await api.updateRent(findResult.data[0].id, { status: constant.rentStatus.completed, updatedBy: (new Date()) })

          if (updateResult.code !== 200) {
            alert("업데이트 리저트", updateResult.msg)
            return
          }
          //요기 스테터스 노말로 바꾸기
          console.log("컨테이너 스테터스", constant.containerStatus.normal)
          const containerResult = await api.updateContainer(containerId, { status: constant.containerStatus.normal, updatedBy: (new Date()) })

          console.log("containerResult", containerResult)

          if (containerResult.code !== 200) {
            alert("containerResult", containerResult.msg)
            return
          }


        }



        const rentInfo = {
          status: constant.rentStatus.sendToUser,
          createdBy: (new Date()),
          containerId,
          pricing: pricing,
          customerInfo: {
            mainAddress: mainAddress,
            restAddress: restAddress,
            lat: 0, lng: 0,
            bDoorLock: false,
            doorlockPassword: "",
            rating: 0,
            comment: ""

          },
          franchiseInfo: {
            owner: auth.user.email,
            doc: auth.userExtraInfo.franchiseDocs[0]
          },

        }
        const result = await api.createRent(rentInfo)
        if (result.code !== 200) {
          alert(result.msg)
          return false
        }


        alert("렌탈 정보가 등록되었습니다")
        props.history.push("/main")
        return true
        //컨테이너 사용처리
      }}> 완료 </ContainedButton>
    </Page >
  );
}
