import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {Box,TextField} from "@material-ui/core";

import { css } from "../../Const";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200
  }
}));

export default function TimePicker(props) {
  const classes = useStyles();

  const marginSide = css.marginSide;
  const bothMarginside = marginSide * 2;
  const marginTop = css.marginTop;
  var date = new Date();
  const now = moment(date).format('HH:mm').toString()

  const handleTimeChange = (time) => {
    props.setValue(time.target.value);
  };

  return (
    <Box style={{  }}>
      <form className={classes.container} noValidate>
        <TextField

          {...props}
          type="time"
          value={props.value}
          defaultValue={now}
          className={classes.textField}
          onChange={handleTimeChange}

          // maxDate={new Date(2019, 11, 24)}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
      </form>
    </Box>
  );
}
// import TimePicker from "../../../components/basic/TimePicker"

{/* <TimePicker label={"시간을 선택하세요"} value={selectedTime} setValue={setSelectedTime} /> */ }
// const [selectedTime, setSelectedTime] = React.useState("14:35");
