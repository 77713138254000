import React from "react";
import { useEffectOnce } from "react-use";
import Page from "components/Page";
// import GlobalContext from "context/GlobalContext";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
// import { useSnackbar } from "notistack";
import { useModal } from "react-hooks-use-modal";
import { makeStyles } from "@material-ui/core/styles";
import { Header, Title, LastLink } from "components/basic/Components";
import {
  Box,
  Paper,
  Typography,
  Container,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { CustomerController } from "Controllers";
import moment from "moment";
import * as constant from "Const";
import { createRent, getPricing } from "replhadaAPI";
import CloseIcon from "@material-ui/icons/Close";
import OutLinedTextField from "components/basic/OutLinedTextField";
import CheckBox from "components/basic/BcheckBox";
import UpText from "components/basic/UpText";
import EmptySpace from "components/basic/EmptySpace";
import ContainedButton from "components/basic/ContainedButton";
import useInput from "hooks/useInput";
import { Container as Containers, Order } from "Classes";

var geocoder = require("google-geocoder");

const useStyles = makeStyles({
  table: {},
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const ModalContent = (props) => {
  return (
    <React.Fragment>
      <Box>
        <Paper style={{ maxHeight: "600px", overflow: "auto" }}>
          <Box
            sx={{ width: "100%", height: "48px" }}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CloseIcon
              style={{ margin: "22px" }}
              onClick={() => {
                props.close();
              }}
            />
          </Box>
          <Container>
            <CheckBox
              label="출입문 비밀번호가 필요한가요?"
              checked={props.state.checkedA}
              onChange={props.handleCheckBoxChange}
              name="checkedA"
              sx={{ width: "100%" }}
            />
          </Container>
          {props.state.checkedA && (
            <React.Fragment>
              <Container>
                <UpText>
                  아파트 출입문 비밀번호를 입력해주세요 (예#1234){" "}
                </UpText>
                <EmptySpace />
                <TextField
                  variant="outlined"
                  fullWidth
                  value={props.doorPassword.value}
                  onChange={props.doorPassword.onChange}
                />
                <UpText>비밀번호는 수거 후 자동 삭제되오니 안심하세요 </UpText>
              </Container>
            </React.Fragment>
          )}
          <Container>
            <EmptySpace />
            <EmptySpace />
            <ContainedButton onClick={props.requestTapped}>
              수거요청
            </ContainedButton>
          </Container>
          <EmptySpace />
        </Paper>
      </Box>
    </React.Fragment>
  );
};

const Home = (props) => {
  // const { user, setNetwork, setDialogMessage } =
  //     React.useContext(GlobalContext);
  // const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const containerIds = props.location.state?.containerIds ?? [];
  const numOfContainers = props.location.state?.numOfContainers ?? 0;

  const phoneNumber = props.location.state.phoneNumber;
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [state, setState] = React.useState({
    checkedA: false,
  });
  const [selectedData, setSelectedData] = React.useState(null);
  const doorPassword = useInput("");
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const initData = async () => {
    const cc = new CustomerController();
    cc.init();
    const ordersResult = await cc.getOrders();
    const priceResult = await getPricing(constant.pricingId);
    if (priceResult.code !== 200) {
      return;
    }
    return { orders: ordersResult.data, price: priceResult.data };
  };

  useEffectOnce(async () => {
    const dataResult = await initData();
    console.log("데이터", dataResult);
    setData(dataResult);
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const dataResult = await initData();
        setData(dataResult);
        setRefresh(false);
      })();
    }
  }, [refresh]);

  const Body = (props) => {
    return (
      <React.Fragment>
        <Box>
          <Header title="주소지 선택" />
          <Title title="수거지 주소를 입력합니다" />
          <AddressTable />
        </Box>
      </React.Fragment>
    );
  };

  const doorInputChanged = (e) => {
    e.preventDefault();
    console.log(e);

    setdoorPassword(e.target.value);
  };
  const timeFormat = (time) => {
    return moment(time.slice(0, 8) + "T" + time.slice(8, 12)).format(
      "M월D일 h:ma"
    );
  };

  const requestTapped = async () => {
    console.log("mainAddress", selectedData);
    console.log("mainAddress", selectedData.data.orderer.addrNew);

    console.log("프롭스", props.location.state);
    let latitude = 0;
    let longitude = 0;

    var geo = geocoder({
      key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
    });

    function coor(main, rest) {
      return new Promise((resolve, reject) => {
        geo.find(main + " " + rest, (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(res);
        });
      });
    }
    async function getCoordinate() {
      return await coor(selectedData.data.orderer.addrNew);
    }
    const addrssCoor = await getCoordinate();
    longitude = addrssCoor[0].location.lng;
    latitude = addrssCoor[0].location.lat;

    const rentInfo = {
      status: constant.rentStatus.waitingDelivery,
      createdBy: new Date(),
      containerIds,
      numOfContainers,
      pricing: data.price,
      order: selectedData,
      customerInfo: {
        mainAddress: selectedData.data.orderer.addrNew ?? "",
        restAddress: "",
        lat: latitude,
        lng: longitude,
        bDoorLock: state.checkedA ?? "",
        doorlockPassword: doorPassword ?? "",
        rating: 0,
        comment: "",
        addedBy: new Date(),
      },
      franchiseInfo: {
        owner: "",
        doc: "",
      },
    };
    const result = await createRent(rentInfo);
    console.log("렌트인포 넘기기1", result);
    // for await (const conId of containerIds){
    //     const container =  new Containers(conId)
    //     const conObj = {status: constant.containerStatus.waitingDelivery,
    //     updatedBy: (new Date()),

    //     }
    //     container.updateContainer(conObj)
    // }

    const orderController = new Order(selectedData.id);
    await orderController.delete();

    if (result.code !== 200) {
      alert(result.msg);
      return false;
    } else {
      history.push({
        pathname: `/rating`,
        state: {
          selectedData,
          phoneNumber,
          numOfContainers,
          containerIds,
          id: result.id,
          data: result.data,
        },
      });
    }

    return;

    return false;
  };
  const AddressTable = (props) => {
    return (
      <React.Fragment>
        <Container>
          {/* <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">주문시간</TableCell>
                  <TableCell align="center">배송지</TableCell>
                  <TableCell align="center">선택</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.orders.map((v, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {timeFormat(v.data.created)}
                      </TableCell>
                      <TableCell align="center">
                        {v.data.orderer.addrNew}
                      </TableCell>
                      <TableCell align="center">
                        <AsyncButton data={v}>선택</AsyncButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer> */}
          {/* <LastLink
            title="내 주소가 안보이시나요?"
            onClick={() => {
              history.push({
                pathname: "/inputmyorder",
                state: { numOfContainers, containerIds, phoneNumber },
              });
            }}
          /> */}
          <EmptySpace />
          <EmptySpace />

          <ContainedButton
            onClick={async () => {
              history.push({
                pathname: "/inputmyorder",
                state: { numOfContainers, containerIds, phoneNumber },
              });
            }}
          >
            주소 입력하기
          </ContainedButton>
        </Container>
      </React.Fragment>
    );
  };

  const AsyncButton = (props) => {
    return (
      <React.Fragment>
        <Button
          size={"small"}
          color={"primary"}
          variant={"contained"}
          onClick={() => tableButtonTapped(props.data)}
        >
          <Typography>선택</Typography>
        </Button>
      </React.Fragment>
    );
  };
  const tableButtonTapped = async (v) => {
    // TODO 모달에다
    setSelectedData(v);

    return open();
  };

  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          <Body></Body>
          <Modal>
            <ModalContent
              close={close}
              state={state}
              doorPassword={doorPassword}
              requestTapped={requestTapped}
              handleCheckBoxChange={handleCheckBoxChange}
            />
          </Modal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};
export default Home;
