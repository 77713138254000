import React from "react";

import {Button,Box} from "@material-ui/core";
import { css } from "../../Const";
export default function ContainedButton(props) {
  const marginSide = css.marginSide;
  const bothMarginside = marginSide * 2;
  const marginTop = css.marginTop;
  const [disabled, setDisabled] = React.useState(false)
  return (
    <Box
     sx={{width: "100%"}}
    >
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={disabled}
        
        {...props}
       
        onClick={async () => {
          setDisabled(true)
          const done = await props.onClick()
          setDisabled(false)
        }}
      >
        {props.children}
      </Button>
    </Box>
  );
}
