import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";
import { useAuth } from "../../../AuthContext";

import UpText from "../../../components/basic/UpText";
import ContentText from "../../../components/basic/ContentText";

import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import qs from "qs";
import * as api from "../../../replhadaAPI"
import * as constant from "../../../Const"

export default function Retrive(props) {
    const auth = useAuth();

    const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
    });
    const rentId = query.rentId;
    const [rent, setRent] = React.useState(null);
    const [returnPoint, setReturnPoint] = React.useState(null);
    const [washmate, setWashmate] = React.useState(null);

    React.useState(() => {
        (async () => {

            const result = await api.getRent(rentId)
            console.log("리저트", result)
            if (result.code !== 200) {
                alert(result.msg)
                return
            }

            setRent(result)
            setReturnPoint(result.data.returnPoint)
            setRefresh(false)





        })()


    }, [refresh])
    const [value, setValue] = React.useState("restaurant");
    const [refresh, setRefresh] = React.useState(false);

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,
        checkedG: false
    });
    const handleCheckBoxChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const info = rent && (rent.data.status === constant.rentStatus.deliveryReserved ? "다회용기의를 회수하여 용기의 QR을 찍어주세요" :
        (rent.data.status === constant.rentStatus.delivieryPicikup ? "수거장소로 다회용기를 배달하여 수거장소 QR을 스캔하여 완료해 주세요" : ""))

    return (
        <Page style={{ backgroundColor: "grey" }}>
            <AppBar backLink="/login" />
            <EmptySpace />

            <SubTitle>수거 정보</SubTitle>
            <EmptySpace />

            <UpText>{info}</UpText>
            <EmptySpace />
            <EmptySpace />
            <DesText>수거 주소</DesText>
            <EmptySpace />

            <Card>
                <UpText>주소 </UpText>
                <ContentText>{rent && rent.data.customerInfo.mainAddress}</ContentText>
                <ContentText>{rent && rent.data.customerInfo.restAddress}</ContentText>
                <EmptySpace />
                {/* {rent && rent.data.customerInfo.bDoorLock && (<><UpText>출입문 비밀번호*</UpText> */}
                    {/* <ContentText>{rent && rent.data.customerInfo.doorlockPassword}</ContentText></>)} */}
            </Card>

            <EmptySpace />
            <EmptySpace />

            <DesText>수거 수익</DesText>
            <EmptySpace />
            <Card>
                <UpText>수익</UpText>
                <ContentText>{rent && rent.data.pricing.delivery}원</ContentText>
            </Card>


            <EmptySpace />
            <DesText>반납 장소</DesText>
            <EmptySpace />

            {returnPoint && (<><Card>
                <UpText>세척 업체명</UpText>
                <ContentText>{returnPoint.data.storeName}</ContentText>
                <EmptySpace />
                <UpText>주소</UpText>
                <ContentText>{returnPoint.data.mainAddress}</ContentText>
                <ContentText>{returnPoint.data.restAddress}</ContentText>

            </Card>
                <EmptySpace />
            </>)

            }
            {rent && rent.data.status === constant.rentStatus.deliveryReserved && <ContainedButton
                onClick={async () => {
                    props.history.push(`/scancontainer?rentId=${rent.id}`)
                }}
            >다회용기QR을 찍어 회수 확인</ContainedButton>}
            {rent && rent.data.status === constant.rentStatus.delivieryPicikup && <ContainedButton
                onClick={async () => {
                    props.history.push(`/scanreturnpoint?rentId=${rent.id}`)
                }}
            >반납장소 QR을찍어 완료</ContainedButton>}

            {<ContainedButton
                onClick={async () => {
                    //만약 수거 안했을때
                    //그냥 취소 
                    //status 웨이팅 딜리버리 및 딜리버리 지움

                    if (rent.data.status === constant.rentStatus.deliveryReserved) {
                        const rentInfo = { status: constant.rentStatus.waitingDelivery }
                        const result = await api.updateRent(rent.id, rentInfo)
                        if (result.code !== 200) {
                            alert(result.msg)
                            return
                        }

                        await api.deleteField("Rents", rent.id, "delivery")
                        await api.deleteField("Rents", rent.id, "returnPoint")
                        await api.deleteField("Rents", rent.id, "washmate")

                        alert("취소가 완료 되었습니다")
                        props.history.push("/main")
                        return
                    }
                    if (rent.data.status === constant.rentStatus.delivieryPicikup) {
                        if (window.confirm("픽업했던 장소에 놓아 두시겠습니까?")) {
                            const rentInfo = { status: constant.rentStatus.waitingDelivery }
                            const result = await api.updateRent(rent.id, rentInfo)
                            if (result.code !== 200) {
                                alert(result.msg)
                                return
                            }

                            await api.deleteField("Rents", rent.id, "delivery")
                            await api.deleteField("Rents", rent.id, "returnPoint")
                            await api.deleteField("Rents", rent.id, "washmate")

                            alert("취소가 완료 되었습니다")
                            props.history.push("/main")
                            return
                        } else {

                            if (window.confirm("가까운 수거 장소에 놓아 두시겠습니까?")) {
                                const rentInfo = { status: constant.rentStatus.drifted }
                                const result1 = await api.updateRent(rent.id, rentInfo)
                                if (result1.code !== 200) {
                                    alert(result1.msg)
                                    return
                                }
                                console.log("rent.data.containerId", rent.data.containerId)
                                const result = await api.updateContainer(rent.data.containerId, { status: constant.containerStatus.drifted })
                                if (result.code !== 200) {
                                    alert(result.msg)
                                    return
                                }
                                alert("취소 처리 되었습니다")

                                props.history.push("/main")

                            } else {
                                const rentInfo = { status: constant.rentStatus.drifted, }
                                const result1 = await api.updateRent(rent.id, rentInfo)
                                if (result1.code !== 200) {
                                    alert(result1.msg)
                                    return
                                }
                                const result = await api.updateContainer(rent.data.containerId, { status: constant.containerStatus.drifted, updatedBy: (new Date()) })
                                if (result.code !== 200) {
                                    alert(result.msg)
                                    return
                                }

                                alert("가까운 수거장소에 반납 부탁드립니다")

                                props.history.push("/main")

                            }

                        }


                    }


                    //수거 한 상태



                    //픽업했던 고객님 집앞에 놓아 두시겠습니까?
                    //->yes 이면 그냥 취소 및 //status 웨이팅 딜리버리 및 딜리버리 지움
                    //아니면
                    // 가까운 수거 장소에 놓아 두시겠습니까?
                    //-> yes  ->  드리프트, 
                    // no -> 알럴트 로  가까운 수거장소에 반납 부탁드리겠습니다.
                    // 


                    //     const result = await api.updateRent(rent.id, rentInfo)
                    //     if (result.code !== 200) {
                    //         alert(result.msg)
                    //         return
                    //     }
                    //     alert("수거 예약이 완료 되었습니다")
                    //     props.history.push(`/retriving?rentId=${rent.id}`)
                }}
            >취소</ContainedButton>}
            <EmptySpace />
        </Page>
    );
}
