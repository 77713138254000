import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Fab from "@material-ui/core/Fab";

import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { useAuth } from "../../AuthContext";
import { useHistory } from "react-router";

import { css } from "../../Const";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 0,
    bottom: "auto",
    minHeight: "66px",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

function BappBar(props) {
  const auth = useAuth();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["내정보", "관리자메뉴", "세척 파트너", "수거 파트너"].map(
          (text, index) => {
            if (auth.userExtraInfo === null) {
              return;
            }

            return (
              <ListItem
                button
                key={text}
                onClick={async () => {
                  console.log("텍스트", text);
                  if (text === "관리자메뉴") {
                    history.push("/main");
                    return;
                  }
                  if (text === "세척 파트너") {
                    history.push("/adminwashmate");
                    return;
                  }
                  if (text === "내정보") {
                    history.push("/mypage");
                    return;
                  }

                  if (text === "수거 파트너") {
                    history.push("/admindelivery");
                    return;
                  }
                  if (text === "로그아웃") {
                    const result = await auth.signOut();
                    console.log(result);
                    if (result.code !== 200) {
                      alert("다시 시도해 주세요");
                    }
                    window.location.href = "/login";

                    return;
                  }
                  if (text === "내수익") {
                    history.push("/revenu");

                    return;
                  }
                }}
              >
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            );
          }
        )}
      </List>
      {/* <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  const marginSide = css.marginSide;
  const classes = useStyles();
  const backLink = typeof props.backLink === "undefined" ? false : true;
  const menu = typeof props.menu === "undefined" ? false : true;
  const refesh = typeof props.refesh === "undefined" ? false : true;

  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        style={{
          top: 0,
          bottom: "auto",
          minHeight: "66px",
        }}
      >
        <Toolbar style={{ height: "66px" }}>
          {backLink && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                history.push(props.backLink);
              }}
            >
              <ArrowBackIos />
            </IconButton>
          )}

          <div className={classes.grow} />
          {refesh && (
            <IconButton color="inherit">
              <SearchIcon />
            </IconButton>
          )}
          {menu && (
            <>
              <IconButton
                edge="end"
                color="inherit"
                onClick={toggleDrawer("left", true)}
              >
                <MenuIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      <div
        style={{
          width: "100%",
          height: "66px",
        }}
      ></div>

      <div>
        {
          <React.Fragment key={"left"}>
            <Drawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
            >
              {list("left")}
            </Drawer>
          </React.Fragment>
        }
      </div>
    </>
  );
}

export default withRouter(BappBar);
// return (
//   <AppBar position="fixed" color="primary" className={classes.appBar}>
//     <Toolbar>
//       <IconButton edge="start" color="inherit" aria-label="open drawer">
//         <MenuIcon />
//       </IconButton>
//       <div className={classes.grow} />
//       <IconButton color="inherit">
//         <SearchIcon />
//       </IconButton>
//       <IconButton edge="end" color="inherit">
//         <MoreIcon />
//       </IconButton>
//     </Toolbar>
//   </AppBar>
// );
// }
