import React from "react";
import CheckBox from "../../components/basic/BcheckBox";
import PolicyModal from "../../components/basic/PolicyModal";

import { css } from "../../Const";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";

export default function PolicyBox(props) {
  const marginSide = css.marginSide;
  const bothMarginside = marginSide * 2;
  const marginTop = css.marginTop;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ width: "100%" }}>
      <Paper variant="outlined" style={{ margin: `0px ${marginSide}px` }}>
        {/* <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 24px 10px 24px",
            border: "1px solid black"
          }}
        > */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: `${marginTop}px ${marginSide}px`
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckBox label={props.label} {...props} />
          </div>

          <Link
            href="#"
            onClick={() => {
              handleOpen();
            }}
          >
            약관 확인
          </Link>
        </div>
        {/* </div> */}
      </Paper>
      {open && (
        <PolicyModal
          url={props.url}
          closeModal={() => {
            setOpen(false);
          }}
        ></PolicyModal>
      )}
    </div>
  );
}
