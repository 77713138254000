import React from "react";
import { css } from "../../Const";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Checkbox from "@material-ui/core/Checkbox";

export default function Bcard(props) {
  const marginSide = css.marginSide;
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...props}
          icon={<CircleUnchecked />}
          checkedIcon={<CircleCheckedFilled />}
          style={{
            color: "black",

            "&$checked": {
              color: "black"
            },

            checked: {}
          }}
        />
      }
      label={props.label}
    />
  );
}
{
  //usage
  /* <FormGroup column>
          

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

<CheckBox
            label="음식점"
            checked={state.checkedA}
            onChange={handleChange}
            name="checkedA"
          />
          <CheckBox
            label="배달 파트너"
            checked={state.checkedB}
            onChange={handleChange}
            name="checkedB"
          />
          <CheckBox
            label="세척 파트너"
            checked={state.checkedC}
            onChange={handleChange}
            name="checkedC"
          />
          </FormGroup> */
}
