import React from "react";
import { useEffectOnce } from "react-use";
import Page from "components/Page";
// import GlobalContext from "context/GlobalContext";
import { useHistory, withRouter } from "react-router-dom";
import { useAuth } from "AuthContext";
import { Delivery } from "Classes";
import Loader from "components/Loader";
// import { useSnackbar } from "notistack";
import { useModal } from "react-hooks-use-modal";
import { makeStyles } from "@material-ui/core/styles";
import {
  Header,
  Title,
  LastLink,
  SectionTitle,
} from "components/basic/Components";
import {
  Box,
  Paper,
  Typography,
  Container,
  Button,
  Grid,
  Link,
  Chip,
} from "@material-ui/core";
import AddressForm from "components/basic/AddressForm";
import "../mypage/App.css";
import { useSnackbar } from "notistack";

import GoogleMapReact from "google-map-react";
import PlaceIcon from "@material-ui/icons/Place";
import moment from "moment";
import EmptySpace from "components/basic/EmptySpace";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as constant from "Const";

import { ResponsiveBar } from "@nivo/bar";
import firebase from "firebase/app";
import "firebase/database";
import theme from "styles/theme";
var db = firebase.firestore();

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: "8px",
    overflowX: "auto",
    maxHeight: screen.height,
  },
  table: {
    minHeight: 150,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const Marker = ({ children }) => children;

const Home = (props) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // const { user, setNetwork, setDialogMessage } =
  //     React.useContext(GlobalContext);
  // const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const ids = props.location;
  console.log("스테이트", props.location.state.coordinate);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [delivery, setDelivery] = React.useState(null);

  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });

  const [defaultCoordinate, setdefaultCoordinate] = React.useState({
    lat: 37.6706743,
    lng: 126.7591576,
  });
  const [coordinate, setCoordinate] = React.useState(
    props.location.state.coordinate
  );
  const defaultMapOptions = {
    fullscreenControl: false,
  };

  useEffectOnce(async () => {
    const apiData = await initData();
    setData(apiData);
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const apiData = await initData();
        setData(apiData);
        setRefresh(false);
      })();
    }
  }, [refresh]);

  async function getReservedRents(docId) {
    try {
      const tempData = await db.collection("Rents").doc(docId).get();
      return { id: tempData.id, data: tempData.data() };
    } catch (e) {
      console.log(e);
    }
  }

  async function getAllReturnPoints() {
    try {
      const tempData = await db.collection("ReturnPoints").get();
      return tempData.docs.map(async (v) => ({ id: v.id, data: v.data() }));
    } catch (e) {
      console.log(e);
    }
  }

  const initData = async () => {
    const delivery = new Delivery(auth.user.email);
    setDelivery(delivery);
    const todayStart = moment().startOf("day").format("YYYY-MM-DD").toString();
    const todayEnd = moment()
      .add("days", 1)
      .startOf("day")
      .format("YYYY-MM-DD")
      .toString();
    const works = await delivery.getTodayWorks(todayStart, todayEnd);
    console.log("works", works);

    let rents = await delivery.getPickuped();
    rents = rents.data;
    console.log("rents", rents);
    const returnPoints = await delivery.sortReturnPoints(
      props.location.state.coordinate
    );
    return { rents, returnPoints, works };

    //await for getRent 해서 데이터에 저장
    //버튼 누르면 예약으로 텍스트 변경 및
    //예약으로 변경하고 리스트 업데이트
    //예약으로 변경하기전에 한번 예약되어있는지 확인

    //

    //보관소 가져오기

    //유저 수거 완료 정보 가져오기
  };

  const TodayCard = (props) => {
    return (
      <React.Fragment>
        <Box style={{}}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {[
              {
                title: "수거현황",
                description: "오늘의 수거량입니다",
                digit: `${props.numContainer}개/${props.numDelivered}건`,
              },
            ].map((v, i) => {
              return (
                <React.Fragment key={i}>
                  <Container key={i}>
                    <Paper style={{ padding: "10px" }} key={i}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Container>
                            <Typography variant={"subtitle2"}>
                              {v.title}
                            </Typography>
                            <Typography variant={"caption"}>
                              {v.description}
                            </Typography>
                          </Container>
                        </Box>
                        <Box>
                          <Container>
                            <Typography variant={"subtitle1"}>
                              {v.digit}
                            </Typography>
                          </Container>
                        </Box>
                      </Box>
                    </Paper>
                  </Container>
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  // const FixedButton = () =>{
  //     return ( <React.Fragment>
  //         <Box justifyContent="center" alignItems="center" display="flex"
  //         sx={{position:"fixed",bottom:"0px", left:"0px", right:"0px", width:"100%", height:"60px"}}>
  //             <Container>
  //           <Button
  //           fullWidth
  //           variant="contained"
  //           onClick={tableButtonTapped}
  //           color={"primary"}
  //           >
  //               <Typography>완료</Typography>
  //           </Button>
  //           </Container>
  //           </Box>
  //     </React.Fragment>)
  // }
  // const tableButtonTapped=()=>{
  //     return history.push(`/rating`)
  // }

  const AddressTable = (props) => {
    const rows = props.data;
    console.log("LOG", rows);
    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Container>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                sx={{ maxHeight: "100px" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">주문시간</TableCell>
                    <TableCell align="center">배송지</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => {
                    if (
                      row.data.status !== constant.rentStatus.delivieryPicikup
                    ) {
                      return;
                    }

                    return (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          {moment(row.data.createdBy)
                            .format("MM.DD hh:mm")
                            .toString()}
                        </TableCell>
                        <TableCell align="center">
                          {row.data.order.data.orderer.addrNew}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Paper>
      </React.Fragment>
    );
  };

  const cancelTapped = async (row) => {
    let passData = row.data;
    const isReserved = await delivery.cancelReservation(row.id, passData);
    if (isReserved.code !== 200) {
      alert(isReserved.msg);
      setRefresh(true);
      return;
    }
    enqueueSnackbar("예약이 취소되었습니다", { variant: "success" });
    setRefresh(true);
  };

  const Graph = (props) => {
    return (
      <React.Fragment>
        <MyResponsiveBar data={data} />
      </React.Fragment>
    );
  };

  const Map = (props) => {
    const returnPoints = props.returnPoints;

    return (
      <React.Fragment>
        <Container>
          <Box sx={{ height: "300px", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
              }}
              defaultCenter={returnPoints.data.coor}
              defaultZoom={16}
              distanceToMouse={() => {}}
              options={defaultMapOptions}
              center={returnPoints.data.coor}
            >
              <Marker
                lat={returnPoints.data.coor.lat}
                lng={returnPoints.data.coor.lng}
              >
                <div
                  className="cluster-marker"
                  style={{
                    // width: `${10 + (pointCount / points.length) * 20}px`,
                    width: `${40}px`,
                    // height: `${10 + (pointCount / points.length) * 20}px`
                    height: `${30}px`,
                  }}
                >
                  회수장소{" "}
                </div>
              </Marker>
            </GoogleMapReact>
          </Box>
        </Container>
      </React.Fragment>
    );
  };
  const ReturnPoint = (props) => {
    const returnPoints = [props.returnPoints];
    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={2}>
            {returnPoints &&
              returnPoints.map((v, i) => (
                <React.Fragment key={i}>
                  <Grid item key={i} xs={12}>
                    <Paper key={i} style={{ marginTop: "4px" }}>
                      <Container>
                        <Box
                          sx={{
                            p: 2,
                            gap: 2,
                          }}
                        >
                          <Typography variant="subtitle1">
                            {v.data.storeName}
                          </Typography>
                          <Typography variant="subtitle2">
                            {v.data.mainAddress + "_" + v.data.restAddress}
                          </Typography>
                        </Box>
                      </Container>
                    </Paper>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </Container>
      </React.Fragment>
    );
  };
  const FixedButton = (props) => (
    <React.Fragment>
      <Box
        style={{
          marign: "auto",
          position: "fixed",
          bottom: "10px",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {[
            {
              title: "반납하기",
              description: "반납처의 QR을 스캔하면 모두 반납됩니다",
              chipTitle: "확인",
              clicked: () => {
                return history.push("pickedupscan", { coordinate });
              },
            },
          ].map((v, i) => {
            return (
              <React.Fragment key={i}>
                <Paper
                  style={{
                    width: "80%",
                    margin: "auto",
                    padding: "5%",
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Container>
                        <Typography variant={"subtitle2"}>{v.title}</Typography>
                        <Typography variant={"caption"}>
                          {v.description}
                        </Typography>
                      </Container>
                    </Box>
                    <Box>
                      <Container>
                        <Chip
                          label={v.chipTitle}
                          clickable
                          onClick={v.clicked}
                        ></Chip>
                      </Container>
                    </Box>
                  </Box>
                </Paper>
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </React.Fragment>
  );

  const ModalContent = (props) => {
    return (
      <React.Fragment>
        <Box></Box>
      </React.Fragment>
    );
  };
  const numContainer =
    data &&
    data?.works?.data
      .map((v) => {
        if (v.data?.numOfContainers) {
          try {
            return Number(v.data?.numOfContainers) ?? 0;
          } catch (e) {
            console.log("갯수 에러", e);
            return 0;
          }
        }
        return Number(v.data.containerIds.length);
      })
      .reduce((sum, current) => {
        if (Number.isNaN(current)) {
          return sum;
        }
        return sum + current;
      }, 0);
  const Body = (props) => {
    return (
      <React.Fragment>
        <Box>
          <Header title="회수 예약" />
          <SectionTitle title="픽업한 수거" />
          <AddressTable data={data.rents} />
          <SectionTitle title="금일 수거 현황" />
          {/* <MyResponsiveBar /> */}
          <TodayCard
            numContainer={numContainer}
            numDelivered={data.works.data.map((v) => v).length}
          ></TodayCard>
          <SectionTitle title="반납할 보관소 위치" />
          {console.log("LOG 6", data)}
          <Map returnPoints={data.returnPoints[0]} />
          <ReturnPoint returnPoints={data.returnPoints[0]} />
        </Box>
        {/* <FixedButton></FixedButton> */}
      </React.Fragment>
    );
  };

  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          <Body></Body>
          <EmptySpace />
          <EmptySpace />
          <EmptySpace />
          <EmptySpace />
          <EmptySpace />
          <EmptySpace />
          <EmptySpace />
          <EmptySpace />
          <Modal>
            <ModalContent></ModalContent>
          </Modal>
          <FixedButton></FixedButton>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};
export default withRouter(Home);
