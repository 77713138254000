
import {getOrdersByPhoneNumber, getOrders as gg} from "replhadaAPI"

export function CustomerController(){

        this.orderId 
        this.phoneNumber 
        this.myOrder
        this.init = async(data)=>{
            console.log( "데이터",data)
            if ( data?.orderId ){
                console.log("여기 들어오나")
                this.orderId = data.orderId
                const order = await getOrdersByOrderId(data.orderId)
                console.log(order)
                return
            }
            if ( data?.phoneNumber  ){
                const orders = await getOrdersByPhoneNumber(data.phoneNumber)
                this.myOrder = orders
                console.log(orders)
                return 
            }
     }
     this.getMyOrder = () => {
         return this.myOrder
     }
     this.getOrders = async ()=> {
        
        const apiResult = await gg()
         console.log(apiResult)
        return apiResult
    }

}