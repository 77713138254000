import React from "react";

import Page from "../../../components/basic/Page";
import OutLinedTextField from "../../../components/basic/OutLinedTextField";

import UpText from "../../../components/basic/UpText";
import DesText from "../../../components/basic/DesText";

import SubTitle from "../../../components/basic/SubTitle";
import ContainedButton from "../../../components/basic/ContainedButton";
import AppBar from "../../../components/basic/BappBar";
import EmptySpace from "../../../components/basic/EmptySpace";
import Card from "../../../components/basic/Bcard";
import PolicyBox from "../../../components/basic/PolicyBox";
import ContentText from "../../../components/basic/ContentText";

import FormGroup from "@material-ui/core/FormGroup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { useLocation } from "react-router-dom";
import * as constant from "../../../Const";
import * as api from "../../../replhadaAPI"


export default function SignUp(props) {
  const [value, setValue] = React.useState("restaurant");
  const [rentInfo, setRentInfo] = React.useState(null);
  const [responseAmount, setResponseAmount] = React.useState(null);

  const location = useLocation();


  React.useEffect(() => {
    console.log(location.pathname); // result: '/secondpage'
    console.log(location.state.id); // result: '?query=abc'
    console.log(location.state.data); // result: 'some_value'
    setRentInfo({ id: location.state.id, data: location.state.data })
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedG: false
  });
  const handleCheckBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Page>
      <AppBar backLink="/login" />
      <EmptySpace />

      <DesText>용기 배정</DesText>
      <EmptySpace />
      <EmptySpace />

      <UpText>음식점 정보 </UpText>
      <ContentText >{rentInfo && rentInfo.data.request.storeName}</ContentText>
      <EmptySpace />

      <UpText>주소 </UpText>
      <ContentText >{rentInfo && rentInfo.data.request.mainAddress}</ContentText>
      <ContentText >{rentInfo && rentInfo.data.request.restAddress}</ContentText>
      <EmptySpace />

      <UpText>용기 요청 갯수 </UpText>
      <ContentText >{rentInfo && rentInfo.data.request.amount.typeA}개</ContentText>

      <EmptySpace />
      <EmptySpace />
      <UpText>실제 배정될 갯수 </UpText>
      <OutLinedTextField
        value={responseAmount}
        setValue={setResponseAmount}
        inputProps={{
          inputMode: "numeric",
          maxLength: 4
        }}
      />

      <EmptySpace />
      <ContainedButton

        onClick={async () => {

          const requestInfo = {
            response: {
              amount: { typeA: responseAmount },

            },
            status: constant.requestStatus.reserved,
            updatedBy: (new Date())
          }


          const result = await api.updateRequest(rentInfo.id, requestInfo)

          if (result.code !== 200) {
            alert(result.msg)
            return
          }
          alert("배정이 예약됐습니다")
          props.history.push(`/requests`)
          return true
        }}

      >완료</ContainedButton>
      <EmptySpace />
    </Page>
  );
}
