import React from "react";
import { css } from "../../Const";

export default function SubTitle(props) {
  const marginSide = css.marginSide;

  return (
    <div style={{ width: "100%" }}>
      <p
        style={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "32px",
          margin: `0 ${marginSide}px 0 ${marginSide}px`,
          color: "#323232",
        }}
      >
        {props.children}
      </p>
    </div>
  );
}
