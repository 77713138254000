import React from "react";
import { css } from "../../Const";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { TextField,Box } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';


const marginSide = css.marginSide;
const bothMarginside = marginSide * 2;
const marginTop = css.marginTop;
const useStyles = makeStyles((theme) => ({
    formControl: {
        width:"100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function BankSelect(props) {
    const classes = useStyles();
    const handleSelectChange = (event) => {

        props.setValue(event.target.value);
    };
    return (
        <Box style={{
            width: "100%"
        }}>
            <FormControl variant="outlined" className={classes.formControl
            } >
                {/* <InputLabel
          >선택하세요</InputLabel> */}
                < TextField
                    value={props.value}
                    variant="outlined"
                    label="선택하세요"
                    fullWidth
                    onChange={handleSelectChange}
                    select
                >
                    <MenuItem value={""}>
                        <em>선택</em>
                    </MenuItem>
                    <MenuItem value={"경남은행"}>경남은행</MenuItem>
                    <MenuItem value={"광주은행"}>광주은행</MenuItem>
                    <MenuItem value={"국민은행"}>국민은행</MenuItem>
                    <MenuItem value={"기업은행"}>기업은행</MenuItem>
                    <MenuItem value={"농협"}>농협</MenuItem>
                    <MenuItem value={"지역농협"}>지역농협</MenuItem>
                    <MenuItem value={"대구은행"}>대구은행</MenuItem>
                    <MenuItem value={"부산은행"}>부산은행</MenuItem>
                    <MenuItem value={"도이치은행"}>도이치은행</MenuItem>
                    <MenuItem value={"산업은행"}>산업은행</MenuItem>
                    <MenuItem value={"상호저축은행"}>상호저축은행</MenuItem>
                    <MenuItem value={"새마을금고"}>새마을금고</MenuItem>
                    <MenuItem value={"수협"}>수협</MenuItem>
                    <MenuItem value={"신한은행"}>신한은행</MenuItem>
                    <MenuItem value={"신협"}>신협</MenuItem>
                    <MenuItem value={"씨티은행"}>씨티은행</MenuItem>
                    <MenuItem value={"외한은행"}>외한은행</MenuItem>
                    <MenuItem value={"우리은행"}>우리은행</MenuItem>
                    <MenuItem value={"우체국"}>우체국</MenuItem>
                    <MenuItem value={"전북은행"}>전북은행</MenuItem>
                    <MenuItem value={"제주은행"}>제주은행</MenuItem>
                    <MenuItem value={"카카오뱅크"}>카카오뱅크</MenuItem>
                    <MenuItem value={"하나은행"}>하나은행</MenuItem>
                    <MenuItem value={"케이뱅크"}>케이뱅크</MenuItem>
                    <MenuItem value={"SC은행"}>SC은행</MenuItem>
                    <MenuItem value={"HSBC은행"}>HSBC은행</MenuItem>
                    <MenuItem value={"교보증권"}>교보증권</MenuItem>
                    <MenuItem value={"대신증권"}>대신증권</MenuItem>
                    <MenuItem value={"대우증권"}>대우증권</MenuItem>
                    <MenuItem value={"동부증권"}>동부증권</MenuItem>
                    <MenuItem value={"메리츠종합금융증권"}>메리츠종합금융증권</MenuItem>
                    <MenuItem value={"미래에셋증권"}>미래에셋증권</MenuItem>
                    <MenuItem value={"부국증권"}>부국증권</MenuItem>
                    <MenuItem value={"삼성증권"}>삼성증권</MenuItem>
                    <MenuItem value={"신영증권"}>신영증권</MenuItem>
                    <MenuItem value={"신한금융투자"}>신한금융투자</MenuItem>
                    <MenuItem value={"아이엠투자증권"}>아이엠투자증권</MenuItem>
                    <MenuItem value={"우리투자증권"}>우리투자증권</MenuItem>
                    <MenuItem value={"유안타증권"}>유안타증권</MenuItem>
                    <MenuItem value={"유진투자증권"}>유진투자증권</MenuItem>
                    <MenuItem value={"이트레이드증권"}>이트레이드증권</MenuItem>
                    <MenuItem value={"키움증권"}>키움증권</MenuItem>
                    <MenuItem value={"하나대투증권"}>하나대투증권</MenuItem>
                    <MenuItem value={"하이투자증권"}>하이투자증권</MenuItem>
                    <MenuItem value={"한국투자증권"}>한국투자증권</MenuItem>
                    <MenuItem value={"한화투자증권"}>한화투자증권</MenuItem>
                    <MenuItem value={"현대증권"}>현대증권</MenuItem>
                    <MenuItem value={"HMC투자증권"}>HMC투자증권</MenuItem>
                    <MenuItem value={"LIG투자증권"}>LIG투자증권</MenuItem>
                    <MenuItem value={"NH농협증권"}>NH농협증권</MenuItem>
                    <MenuItem value={"SK증권"}>SK증권</MenuItem>
                    <MenuItem value={"산림조합"}>산림조합</MenuItem>
                </TextField>
            </FormControl>

        </Box >
    );
}


// const [bank, setBank] = React.useState("");
{/* <BankSelect setValue={setBank} value={bank} /> */ }
