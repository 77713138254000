import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyAJBP9y_uuSww-8fTR4PGnWcox3KncsI_E",
  authDomain: "replhada.firebaseapp.com",
  projectId: "replhada",
  storageBucket: "replhada.appspot.com",
  messagingSenderId: "592266099384",
  appId: "1:592266099384:web:e25719276cb5764133524d",
  measurementId: "G-Q8JHB1S6CJ"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
export default firebase;

const google_provider = new firebase.auth.GoogleAuthProvider();
const fb_provider = new firebase.auth.FacebookAuthProvider();
const twitter_provider = new firebase.auth.TwitterAuthProvider();
const github_provider = new firebase.auth.GithubAuthProvider();

export { google_provider, fb_provider, twitter_provider, github_provider };
