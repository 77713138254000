import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "styles/theme";
import { HelmetProvider } from "react-helmet-async";

import "./styles.css";
import { GlobalProvider } from "./globalContext";
import { AnimatePresence } from "framer-motion";
import { AuthProvider } from "./AuthContext";
import { SnackbarProvider } from "notistack";

//login
//signup
//password
//mypage
//customercenter
//revenu

//customermain
//rating

//franchisemenu
//sendcontainer
//requestcontainer

//deliverymenu
//map
//retrive

//washmatemenu
//received
//receiveddetail
//requests
//requestdetail
//reserved
//reserveddetail

//replhadamenu
//monitor

import App from "./App";

/* mypage */
import LoginPage from "./views/basic/mypage/LoginPage.js";
import SignUpPage from "./views/basic/mypage/SignUp";
import ForgotPassword from "./views/basic/mypage/ForgotPassword";
import Main from "./views/basic/mypage/Main";
import MyPage from "./views/basic/mypage/MyPage";
import CustomerCenter from "./views/basic/mypage/CustomerCenter";
import Revenu from "./views/basic/mypage/Revenu";
import PrivatePolicy from "./views/basic/mypage/PrivatePolicy";

import ServicePolicy from "./views/basic/mypage/ServicePolicy";

/* customer */
import CustomerInfo from "./views/basic/customer/CustomerInfo";

import CustomerMain from "./views/basic/customer/CustomerMain";
import Rating from "./views/basic/customer/Rating";
import InputOrder from "./views/basic/customer/InputOrder";
import MyOrderChoice from "./views/basic/customer/MyOrderChoice";

/* franchise */
import SendContainer from "./views/basic/franchise/SendContainer";
import RequestContainer from "./views/basic/franchise/RequestContainer";

/* delivery */

//지우기
import Retrive from "./views/basic/delivery/Retrive";
//지우기
import Retriving from "./views/basic/delivery/Retriving";
//지우기
import Pickups from "./views/basic/delivery/Pickups";
//지우기
import ScanContainer from "./views/basic/delivery/ScanContainer";
//지우기
import ScanReturnPoint from "./views/basic/delivery/ScanReturnPoint";

import MapDetail from "./views/basic/delivery/MapDetail";
import ReservedDelivery from "./views/basic/delivery/ReservedDelivery";
import PickedUppDelivery from "./views/basic/delivery/PickedUppDelivery";
import ReservedScan from "./views/basic/delivery/ReservedScan";
import PickedUpScan from "./views/basic/delivery/PickedUpScan";
import ReservedScanComplete from "./views/basic/delivery/ReservedScanComplete";
import PickedUpScanComplete from "./views/basic/delivery/PickedUpScanComplete";
/* washmate */
import Received from "./views/basic/washmate/Received";
import ReceivedDetail from "./views/basic/washmate/ReceivedDetail";
import Requests from "./views/basic/washmate/Requests";
import RequestDetail from "./views/basic/washmate/RequestDetail";
import Reserved from "./views/basic/washmate/Reserved";
import ReservedDetail from "./views/basic/washmate/ReservedDetail";

// admin
import AdminDelivery from "./views/basic/admin/AdminDelivery";
import AdminWashmate from "./views/basic/admin/AdminWashmate";
import AdminContainerList from "./views/basic/admin/AdminContainerList";
import AdminContainerDetail from "./views/basic/admin/AdminContainerDetail";
import AdminDeliveryList from "./views/basic/admin/AdminDeliveryList";
import AdminDeliveryDetail from "./views/basic/admin/AdminDeliveryDetail";

import AdminRentedList from "./views/basic/admin/AdminRentedList";
import AdminRentedDetail from "./views/basic/admin/AdminRentedDetail";
import AdminRentingList from "./views/basic/admin/AdminRentingList";
import AdminRentingDetail from "./views/basic/admin/AdminRentingDetail";
import AdminRestuarantList from "./views/basic/admin/AdminRestuarantList";
import AdminRestaurantDetail from "./views/basic/admin/AdminRestaurantDetail";
import AdminReviewList from "./views/basic/admin/AdminReviewList";
import AdminReviewDetail from "./views/basic/admin/AdminReviewDetail";
import AdminWashmateList from "./views/basic/admin/AdminWashmateList";
import AdminWashmateDetail from "./views/basic/admin/AdminWashmateDetail";
import AdminWashmateProfit from "./views/basic/admin/AdminWashmateProfit";

import Test from "./views/basic/admin/Test";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
          <GlobalProvider>
            <AuthProvider>
              <AnimatePresence exitBeforeEnter>
                <React.StrictMode>
                  <Switch>
                    <Route Z exact path="/" component={App} />
                    <Route Z exact path="/test" component={Test} />
                    <Route
                      Z
                      exact
                      path="/service_policy"
                      component={ServicePolicy}
                    />
                    <Route
                      Z
                      exact
                      path="/private_policy"
                      component={PrivatePolicy}
                    />
                    //login
                    <Route exact path="/login" component={LoginPage} />
                    //signup
                    <Route exact path="/signup" component={SignUpPage} />
                    //password
                    <Route
                      exact
                      path="/forgotpassword"
                      component={ForgotPassword}
                    />
                    //Main
                    <Route exact path="/main" component={Main} />
                    //mypage
                    <Route exact path="/mypage" component={MyPage} />
                    //customercenter
                    <Route
                      exact
                      path="/customercenter"
                      component={CustomerCenter}
                    />
                    //revenu
                    <Route exact path="/revenu" component={Revenu} />
                    //customerentry
                    <Route
                      exact
                      path="/customerinfo"
                      component={CustomerMain}
                    />
                    
                    //customermain
                    <Route
                      exact
                      path="/customermain"
                      component={CustomerMain}
                    />
                    <Route
                      exact
                      path="/customerphone"
                      component={CustomerInfo}
                    />
                    //customer choose orderlist
                    <Route
                      exact
                      path="/choosemyorder"
                      component={MyOrderChoice}
                    />
                    //customer save orderinfo
                    <Route exact path="/inputmyorder" component={InputOrder} />
                    //rating
                    <Route exact path="/rating" component={Rating} />
                    //franchisemenu //sendcontainer
                    <Route
                      exact
                      path="/sendcontainer"
                      component={SendContainer}
                    />
                    //requestcontainer
                    <Route
                      exact
                      path="/requestcontainer"
                      component={RequestContainer}
                    />
                    //retrive
                    <Route exact path="/retrive" component={Retrive} />
                    //mapdetail
                    <Route exact path="/mapdetail" component={MapDetail} />
                    //retriving
                    <Route exact path="/retriving" component={Retriving} />
                    //pickups
                    <Route exact path="/pickups" component={Pickups} />
                    //scanreturnpoint
                    <Route
                      exact
                      path="/scanreturnpoint"
                      component={ScanReturnPoint}
                    />
                    //scanContainer
                    <Route
                      exact
                      path="/scancontainer"
                      component={ScanContainer}
                    />
                    //scanreturnpoint
                    <Route
                      exact
                      path="/scanreturnpoint"
                      component={ScanReturnPoint}
                    />
                    <Route
                      exact
                      path="/reserveddelivery"
                      component={ReservedDelivery}
                    />
                    <Route
                      exact
                      path="/pickedupdelivery"
                      component={PickedUppDelivery}
                    />
                    <Route
                      exact
                      path="/reservedscan"
                      component={ReservedScan}
                    />
                    <Route
                      exact
                      path="/pickedupscan"
                      component={PickedUpScan}
                    />
                    <Route
                      exact
                      path="/reservedscancomplete"
                      component={ReservedScanComplete}
                    />
                    <Route
                      exact
                      path="/pickedupScancomplete"
                      component={PickedUpScanComplete}
                    />
                    //washmatemenu
                    <Route exact path="/washmatemenu" component={Main} />
                    //received
                    <Route exact path="/received" component={Received} />
                    //receiveddetail
                    <Route
                      exact
                      path="/receiveddetail"
                      component={ReceivedDetail}
                    />
                    //requests
                    <Route exact path="/requests" component={Requests} />
                    //requestdetail
                    <Route
                      exact
                      path="/requestdetail"
                      component={RequestDetail}
                    />
                    //reserved
                    <Route exact path="/reserved" component={Reserved} />
                    //reserveddetail
                    <Route
                      exact
                      path="/reserveddetail"
                      component={ReservedDetail}
                    />
                    //admin
                    <Route
                      exact
                      path="/admindelivery"
                      component={AdminDelivery}
                    />
                    <Route
                      exact
                      path="/adminwashmate"
                      component={AdminWashmate}
                    />
                    <Route
                      exact
                      path="/admincontainerlist"
                      component={AdminContainerList}
                    />
                    <Route
                      exact
                      path="/admincontainerdetail"
                      component={AdminContainerDetail}
                    />
                    <Route
                      exact
                      path="/admindeliverylist"
                      component={AdminDeliveryList}
                    />
                    <Route
                      exact
                      path="/admindeliverydetail"
                      component={AdminDeliveryDetail}
                    />
                    <Route
                      exact
                      path="/adminrentedlist"
                      component={AdminRentedList}
                    />
                    <Route
                      exact
                      path="/adminrenteddetail"
                      component={AdminRentedDetail}
                    />
                    <Route
                      exact
                      path="/adminrentinglist"
                      component={AdminRentingList}
                    />
                    <Route
                      exact
                      path="/adminrentingdetail"
                      component={AdminRentingDetail}
                    />
                    <Route
                      exact
                      path="/adminrestuarantlist"
                      component={AdminRestuarantList}
                    />
                    <Route
                      exact
                      path="/adminrestaurantdetail"
                      component={AdminRestaurantDetail}
                    />
                    <Route
                      exact
                      path="/adminreviewlist"
                      component={AdminReviewList}
                    />
                    <Route
                      exact
                      path="/adminreviewdetail"
                      component={AdminReviewDetail}
                    />
                    <Route
                      exact
                      path="/adminwashmatelist"
                      component={AdminWashmateList}
                    />
                    <Route
                      exact
                      path="/adminwashmatedetail"
                      component={AdminWashmateDetail}
                    />
                    <Route
                      exact
                      path="/adminwashmateprofit"
                      component={AdminWashmateProfit}
                    />
                    {/* admin

import AdminDelivery from "./views/basic/admin/AdminDelivery";
import AdminWashmate from "./views/basic/admin/AdminWashmate";
import AdminContainerList from "./views/basic/admin/AdminContainerList";
import AdminContainerDetail from "./views/basic/admin/AdminContainerDetail";
import AdminDeliveryList from "./views/basic/admin/AdminDeliveryList";
import AdminRentedList from "./views/basic/admin/AdminRentedList";
import AdminRentedDetail from "./views/basic/admin/AdminRentedDetail";
import AdminRentingList from "./views/basic/admin/AdminRentingList";
import AdminRentingDetail from "./views/basic/admin/AdminRentingDetail";
import AdminRestuarantList from "./views/basic/admin/AdminRestuarantList";
import AdminRestaurantDetail from "./views/basic/admin/AdminRestaurantDetail";
import AdminReviewList from "./views/basic/admin/AdminReviewList";
import AdminReviewDetail from "./views/basic/admin/AdminReviewDetail";
import AdminWashmateList from "./views/basic/admin/AdminWashmateList";
import AdminWashmateDetail from "./views/basic/admin/AdminWashmateDetail";
*/}
                  </Switch>
                </React.StrictMode>
              </AnimatePresence>
            </AuthProvider>
          </GlobalProvider>
        </SnackbarProvider>
      </HelmetProvider>
    </ThemeProvider>
  </BrowserRouter>,

  rootElement
);
